import React, { Component } from 'react'
import Header from './MapUI/Header'
import parse, { domToReact } from 'html-react-parser';
import { Link } from 'react-router-dom';
import NewsletterArticle from './Common/NewsletterArticle';
import LinkRedirectionModal from './Common/LinkRedirectionModal';
import { getDate, scrollTo } from '../util/util';
import TextareaAutosize from 'react-textarea-autosize';
import EditImage from './Common/EditImage';
import ReactQuill from 'react-quill';
import PublishMenu from './Common/PublishMenu';
import AuthorEditOptions from './Common/AuthorEditOptions';
import axios from 'axios';
import {authHeader} from '../util/service';
import Footer from './Common/Footer';

export default class ArticleEdit extends Component {
    constructor(props) {
        super(props)

        this.state = {
            article: {
                title: '',
                image: '',
                content: ''
            },
            articles: [],
            relatedArticles: [],
            selectedImageUpdate: { src: '' },
            alert: null,
            history: [],
            isLoading: true,
        }
    }

    componentDidMount = () => {
        scrollTo(0)

        document.body.classList.toggle('body-dashboard', false);

        if (this.props.isCreating) {
            this.setState({
                isCreating: true,
                isLoading: false
            })
        } else {
            this.getArticle(this.props.slug);
        }
    }

    getArticle = (slug) => {
        axios.get(`https://versemail.azurewebsites.net/news/world/article/${slug}`, { headers: authHeader() }).then(response => {
            if (response?.data) {
                this.setState({
                    article: response?.data,
                    isLoading: false
                })
            }
        }).catch(error => {
            this.setState({
                isLoading: false
            })
        });
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.slug !== this.props.slug)
            scrollTo(0)

        if (prevState.article !== this.state.article) {
            this.getArticles(0, 6, this.state.article?.type || 1)
        }
    }

    getArticles = (skip, limit, type) => {
        axios.get(`https://versemail.azurewebsites.net/news/world/articles/${skip}/${limit}/${type}`, { headers: authHeader() }).then(response => {
            if (response?.data) {
                let data = response?.data?.articles
                let articles = data.slice(0, 2),
                relatedArticles = data.slice(2, data?.length)

                this.setState({
                    articles: articles,
                    relatedArticles: relatedArticles
                })
            }
        }).catch(error => {

        });
    }

    setArticle = (article) => {
        this.setState({
            article: article
        })
    }

    setAuthor = (author) => {
        this.setState({
            article: {
                ...this.state.article,
                author: author
            }
        })
    }

    getContentParsed = (content) => {
        let modules = {
            toolbar: [
                ['bold', 'italic', 'underline'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['link', 'image', 'video'],
            ],
            imageCompress: {
                quality: .75,
                maxWidth: 1000,
                maxHeight: 1000,
                imageType: 'image/jpeg',
            }
        };

        return <ReactQuill
            className='p'
            modules={modules}
            value={content}
            name='content'
            placeholder='Article content'
            onChange={(html) => this.handleInputChange({target: {name: 'content', value: html}})}
        />
    }

    displayRelatedArticles = (articles) => {
        if (!articles) return 'No articles to display yet'

        return articles.map(article => {
            return (
                <Link to={`/article/${article?.slug}`} className='element'>
                    <div className='image' style={{ backgroundImage: `url(${article?.image})` }} />
                    <h5>{article?.title}</h5>
                </Link>
            )
        })
    }

    renderImpressions = () => {
        return (
            <div className='impressions'>
                {/*<div className='child'>
                    <div className='icon'>
                        <i class='bx bx-heart' />
                        <p>28</p>
                    </div>

                    <div className='icon'>
                        <i class='bx bx-message-rounded-dots' />
                        <p>21 comments</p>
                    </div>
                </div>

                <div className='child'>
                    <div className='icon' style={{ fontSize: 20 }}>
                        <i className='bx bx-upload' />
                        <p>72</p>
                    </div>
                </div>*/}
            </div>
        )
    }

    renderAuthor = () => {
        return (
            <>
                <div className='author-details'>
                    <div className={'profile-picture-container edit' + (this.state.isAuthorImg ? ' selected' : '')}>
                        <div className={'profile-picture'}
                            style={{ backgroundImage: `url(${this.state.article?.author?.profilePicture})` }}
                            onClick={() => this.toggleSelectImage(this.state.article?.author?.profilePicture || '.', true)}
                        />
                    </div>
                    
                    <div className='author'>
                        <span>Author</span>
                        <TextareaAutosize
                            className={'input-text name-input'}
                            value={this.state.article?.author?.firstName || ''}
                            name='firstName'
                            placeholder='Author name'
                            autoComplete="off"
                            onChange={(e) => this.handleInputChangeAuthor(e)}
                        />
                        <TextareaAutosize
                            className={'input-text desc-input'}
                            value={this.state.article?.author?.description || ''}
                            name='description'
                            placeholder='Author description'
                            autoComplete="off"
                            onChange={(e) => this.handleInputChangeAuthor(e)}
                        />
                    </div>
                </div>
                <AuthorEditOptions article={this.state.article} 
                        setArticle={this.setArticle}  author={this.state.article?.author} setAuthor={this.setAuthor}/>
            </>
        )
    }

    renderBottomRelatedArticles = () => {
        if (!this.state.relatedArticles?.length) return 'No related articles yet'

        return this.state.relatedArticles.map((article) => {
            return null
        })
    }

    handleInputChange = (e) => {
        this.setState({
            article: {
                ...this.state.article,
                [e.target.name]: e.target.value
            }
        })
    }

    handleInputChangeAuthor = (e) => {
        this.setState({
            article: {
                ...this.state.article,
                author: {
                    ...this.state.article?.author,
                    [e.target.name]: e.target.value
                }
            }
        })
    }

    toggleSelectImage = (src = '', isAuthorImg) => {
        this.setState({
            selectedImageUpdate: { src },
            isAuthorImg: isAuthorImg
        })
    }

    updateImage = (src) => {
        if (this.state.isAuthorImg) {
            this.setState({
                article: {
                    ...this.state.article,
                    author: {
                        ...this.state.article.author,
                        profilePicture: src
                    }
                },
                isAuthorImg: false
            })
        } else {
            this.setState({
                article: {
                    ...this.state.article,
                    image: src
                }
            })
        }
    }

    setIsCreating = () => {
        this.setState({isCreating: false})
    }

    render() {
        const { article } = this.state;
        if (this.state.isLoading) {
            return (
                <div className='article-page'>
                    <Header pageTitle={'NEWS'} />

                    <div className='loading-page'>
                        <div className='loader' />
                    </div>
                </div>
            )
        } 
        return (
            <div className='article-page'>
                <Header pageTitle={'NEWS'} />
                <EditImage
                    selectedImage={this.state.selectedImageUpdate}
                    toggleSelectImage={this.toggleSelectImage}
                    updateImage={this.updateImage}
                />
                <div className='article'>
                    <PublishMenu 
                        article={this.state.article} 
                        setArticle={this.setArticle} 
                        originalArticle={this.state.originalArticle}
                        handleCreateOrUpdate={this.handleCreateOrUpdate}
                        isCreating={this.state.isCreating}
                        slug={this.props.slug}
                        setIsCreating={this.setIsCreating}
                    />

                    <div className='title-container'>
                        <TextareaAutosize
                            className={'input-text title'}
                            value={article?.title}
                            name='title'
                            placeholder='Title'
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                        />
                    </div>
                    <div className='image-container'>
                        <div
                            className={'image edit' + (this.state.selectedImageUpdate?.src !== '' ? ' selected' : '')}
                            style={{ backgroundImage: `url(${article?.image})` }}
                            onClick={() => this.toggleSelectImage(article?.image || '.')}
                        />
                    </div>

                    <div className='details'>
                        <div className='date'>
                            {getDate(this.state.article?.creationDate)}
                        </div>

                        <div className='user'>
                            By {this.state.article?.author?.firstName}
                        </div>

                        <div className='user'>
                            |
                        </div>

                        <div className='length'>
                            {Math.max(Math.round((this.state.article?.content?.length / 5) / 238), 1)} min read
                        </div>
                    </div>

                    <div className='content-container'>
                        <div className='content'>
                            {this.getContentParsed(this.state.article?.content)}

                            <NewsletterArticle author={this.state.article?.author?.firstName} />
                            {this.renderImpressions()}
                            {this.renderAuthor()}
                        </div>

                        <div className='recommendations'>
                            <h4>Don't miss</h4>

                            <div className='articles'>
                                {this.displayRelatedArticles(this.state.articles)}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='related-articles-container'>
                    {
                        this.state.relatedArticles?.length ?
                        <>
                            <h4>Related Topics</h4>
                            <div className='related-articles'>
                                {this.displayRelatedArticles(this.state.relatedArticles)}
                            </div>
                        </> : 
                        ''
                    }
                </div>
                <Footer />
            </div>
        )
    }
}
