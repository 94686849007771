import { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { applyProps, useThree } from '@react-three/fiber'
import { useGLTF } from '@react-three/drei'
import React from 'react';
import * as THREE from 'three'
import { FlakesTexture } from 'three-stdlib'

export function LoadModelFallback(props) {
    const { path, properties, focusElement, selectedProperty, selectedTypes, tutorialSteps, isTutorial, ground } = props
    const { scene, nodes, materials, animations } = useGLTF(path)
    const [isAnimationCalled, setIsAnimationCalled] = useState(false);
    const { camera, raycaster } = useThree();

    useEffect(() => {
        // seems redundant but we only need the data here once
        let parents = []; // added for objects that have same parent, only add it once
        let propertiesData = Object.values(nodes).map((child) => {
            if (child.isMesh) {
                let childProperty, returnedData;
                for (let property of properties) {
                    if (property?.name === child?.name || (property?.name.includes(child?.name) && !parents.includes(property?.parent))) {
                        childProperty = property
                        returnedData = {
                            property: childProperty,
                            target: child
                        }
                        property?.parent && parents.push(property?.parent)
                        break;
                    }
                }

                return returnedData
            }
        });

        // seems like a repetition of the previous loop but needed for tutorials positions and clarity
        if (tutorialSteps?.length) {
            let tutorialStepsData = tutorialSteps.map((tutorialStep) => {
                let target = getNodeByName(tutorialStep?._3DName, nodes)

                let children = []
                if (tutorialStep?.children?.length) {
                    children = tutorialStep?.children.map((childTutorial) => {
                        let targetChild = getNodeByName(childTutorial?._3DName, nodes)

                        return {
                            ...childTutorial,
                            target: targetChild
                        }
                    })
                }

                tutorialStep = {
                    ...tutorialStep,
                    target: target,
                    children: children
                }

                return tutorialStep;
            })

            props.setTutorialSteps(tutorialStepsData);
        }

        props.setPropertiesData(propertiesData.filter((e) => { return e !== undefined }));

        
    }, [])

    useEffect(() => {
        let names = props.selectedProperty?.name;
        names = handleSelectedPropertyTutorial(props.selectedProperty);

        let colorValue = isTutorial ? "#fad57f" : "#945f72"
        if (names instanceof Array && names?.length) {

        } else {
            names = [names];
        }

        setNodesColorByNames(names, colorValue, 'SELECTED');
    }, [selectedProperty])

    const handleSelectedPropertyTutorial = (selectedProperty) => {
        let results = []
        if (isTutorial && selectedProperty?.length) {
            results = selectedProperty?.map(element => {
                return element?.name
            })
        } else if (selectedProperty?.name) {
            results = selectedProperty?.name;
        }

        return results
    }

    useEffect(() => {
        // get childproperties by type
        let names = [];
        for (let selectedType of selectedTypes) {
            let namesByType = getChildPropertiesByType(selectedType?.type);
            names = [...names, ...namesByType]
        }

        setNodesColorByNames(names, '#74AAE2', 'HIGHLIGHTED')
    }, [props.selectedTypes])

    const setNodesColorByNames = (names, color = "#945f72", type) => {
        for (let node of Object.values(nodes)) {
            if (names?.length && names.indexOf(node?.name) > -1) {
                setNodeMaterial(node, undefined, color, type)
            } else if (node?.originalMaterial && node?.name !== 'Water' && node?.name !== 'Roads') {
                if (type === 'SELECTED' && node.isHighlighted) {
                    node.material.color.set('#74AAE2')
                } else {
                    node.material = node.originalMaterial
                    node.isHighlighted = undefined;
                    node.selectionType = undefined
                }
            }
        }
    }

    let getNodeByName = (name, nodes) => {
        let returnedResult;
        for (let node of Object.values(nodes)) {
            if (node?.name === name) {
                returnedResult = node
            }
        }
    
        return returnedResult;
    }

    const setNodeMaterial = (node, properties, color, type) => {
        if (node) {
            const materialName = node.material.name;
            const material = materials[materialName];
            const clonedMaterial = material.clone();

            if (properties) {
                clonedMaterial.metalness = properties.metalness
                clonedMaterial.roughness = properties.roughness
            }
            if (color) {
                clonedMaterial.color.set(color);
                node.colorValue = color;
            }

            if (type) {
                node.selectionType = type
                
                if (type === 'SELECTED') {
                    node.isSelected = true;
                } else if (type === 'HIGHLIGHTED')
                    node.isHighlighted = true
            }

            node.material = clonedMaterial;
        }
    }

    useMemo(() => {
        props.setIsLoaded(true);

        Object.values(nodes).forEach(node => {
            if (node.material) {
                node.material.metalness = .5;
                node.material.roughness = .9;
                node.material.color.set('#fff');
                node.originalMaterial = node.material;

                if (node.name !== 'Water') {
                    //if (!props.isMobile) {
                        node.castShadow = true;
                        node.receiveShadow = true;
                    //}
                }
            }
        });

        setNodeMaterial(nodes['Water'], {metalness: .7, roughness: .95}, '#51768f')
        setNodeMaterial(nodes['Roads'], undefined, '#737578')
    }, [scene, nodes, materials]);

    if (animations?.length && !isAnimationCalled) {
        setIsAnimationCalled(true);
        let modelAnim = scene;
        let mixer = new THREE.AnimationMixer(modelAnim);
        let mixerSlowed = new THREE.AnimationMixer(modelAnim);
        mixerSlowed.timeScale = .2;
        mixer.timeScale = 1;
        animations.forEach(clip => {
            let action = mixer.clipAction(clip);

            clip.tracks = clip.tracks.map((track) => {
                track.setInterpolation(THREE.InterpolateLinear)
                return track
            })

            if (clip?.name === "CableMachine1Action" || clip?.name === "CableMachine2Action") {
                action = mixerSlowed.clipAction(clip)
            }
            action.play();
        });

        props.addMixer(mixer);
        props.addMixer(mixerSlowed);
    }

    const handleMouseClick = (event) => {
        clickMesh(event, true)
    }

    const handleMouseHover = (event) => {
        if (!props.isCameraMoving)
            clickMesh(event)
    }

    const clickMesh = (event, isClick) => {
        const mouse = new THREE.Vector2();
        const intersects = [];
    
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    
        raycaster.setFromCamera(mouse, camera);
    
        scene.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                const intersect = raycaster.intersectObject(child);
                if (intersect.length > 0) {
                    intersects.push({ name: child.name, distance: intersect[0].distance });
                }
            }
        });
    
        intersects.sort((a, b) => a.distance - b.distance);
    
        if (intersects.length > 0 && isClick) {
            const closestNodeName = intersects[0].name;
            setMeshClicked(closestNodeName)
        } else if (intersects.length > 0) {
            const closestNodeName = intersects[0].name;
            setMeshHovered(closestNodeName)
        }
    }

    const setMeshClicked = (nodeName) => {
        const childProperty = getChildProperty(nodeName);

        if (childProperty && !isTutorial) {
            focusElement(childProperty, nodes[nodeName]);
        }
    }

    const setMeshHovered = (nodeName) => {
        return;

        const childProperty = getChildProperty(nodeName);

        if (childProperty && !isTutorial && nodes[nodeName]?.colorValue !== "#fad57f") {
            setNodeMaterial(nodes[nodeName], undefined, "#fad57f")
        }
    }

    const getChildProperty = (nodeName) => {
        let childProperty;
        for (let property of properties) {
            if (property?.name instanceof Array && property?.name?.length) {
                for (let name of property.name) {
                    if (name === nodeName) {
                        childProperty = property;
                        break;
                    }
                }
            }

            if (property?.name === nodeName) {
                childProperty = property
                break;
            }
        }

        return childProperty;
    }

    // returns names
    const getChildPropertiesByType = (type) => {
        let results;
        for (let property of properties) {
            if (property?.type === type) {
                results = property?.name;
                break;
            }
        }

        return results;
    }

    return (
        <mesh receiveShadow onClick={handleMouseClick} onPointerMove={handleMouseHover}>
            <primitive object={scene} {...props} />
        </mesh>
    )
}
