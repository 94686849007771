import React, { Component } from 'react'
import Header from './MapUI/Header'
import NavigationMenu from './Common/NavigationMenu';
import { Link } from 'react-router-dom';
import Modal from './Common/Modal';
import { isTokenValid } from '../util/service';

export default class MapUI extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isChecked: false,
            wasVisited: true,
            propertyTypes: [
                {
                    name: 'Skyscrapers',
                    type: 'SKYSCRAPER',
                    isChecked: false
                },
                {
                    name: 'Semi collective building',
                    type: 'SEMI_COLLECTIVE',
                    isChecked: false
                },
                {
                    name: 'Suburb villa',
                    type: 'VILLA',
                    isChecked: false
                },
                {
                    name: 'Cliff houses',
                    type: 'CLIFF',
                    isChecked: false
                },
                {
                    name: 'Beach houses',
                    type: 'BEACH',
                    isChecked: false
                },
            ],
            propertyStatus: [
                {
                    name: 'Owned',
                    type: 1,
                    isChecked: false
                },
                {
                    name: 'Sold',
                    type: 2,
                    isChecked: false
                }
            ],
            propertySize: [
                {
                    name: 'XXS',
                    isChecked: false
                },
                {
                    name: 'XS',
                    isChecked: false
                },
                {
                    name: 'S',
                    isChecked: false
                },
                {
                    name: 'M',
                    isChecked: false
                },
                {
                    name: 'L',
                    isChecked: false
                },
                {
                    name: 'XL',
                    isChecked: false
                },
                {
                    name: 'XXL',
                    isChecked: false
                }
            ],
            propertyFilters: [
                {
                    icon: 'bx bxs-train',
                    name: 'transportation',
                    type: 'TRANSPORTATION',
                },
                {
                    icon: 'bx bxs-joystick-alt',
                    name: 'games',
                    type: 'ACTIVITIES',
                },
                {
                    icon: 'bx bxs-landscape',
                    name: 'free lands',
                    type: 'FREE_LANDS'
                },
                {
                    icon: 'bx bxs-shopping-bag',
                    name: 'shopping',
                    type: 'NFT'
                },
                {
                    icon: 'bx bxs-hotel',
                    name: 'Accomodation',
                    type: 'HOUSING',
                    types: ['CLIFF', 'BEACH', 'VILLAS']
                },
                // filter for more options. Doesn't have the same functionality
                {
                    icon: 'bx bx-slider-alt',
                    name: '',
                    type: 'FILTER',
                    isOptions: true
                },
            ],
            isFooterOpen: false,
            displayPropertyFilters: false,
            tutorialStepsFiltered: [],
            isInfoDisplayed: false
        }

        this.modalRef = React.createRef();
        this.propertyDetailsRef = React.createRef();
        this.infoRef = React.createRef();
        this.footerRef = React.createRef();
    }

    componentDidMount = () => {
        window.addEventListener('mousedown', this.pageClick, false);
        window.addEventListener('touchstart', this.pageClick, false);

        if (this.props.tutorialSteps?.length) {
            let tutorialSteps = [...this.props.tutorialSteps]
            let tutorialStepsFiltered = tutorialSteps.map((step, index) => {
                if (step?.icon && step?.icon !== '' && !step?.isContinuous) {
                    let children = [];
                    if (index + 1 >= tutorialSteps?.length) return step // avoid getting over tutorialsteps (shouldn't happen anyway)

                    for (let i = index + 1; i < tutorialSteps?.length; i++) {
                        let childStep = tutorialSteps[i]
                        if (childStep?.icon && childStep?.icon !== '') {
                            break;
                        } if (childStep?.isContinuous)  {
                            // we avoid repeting the element so we do nothing
                        } else {
                            children.push(tutorialSteps[i])
                        }
                    }

                    return {
                        ...step,
                        children: children
                    }
                }
            }).filter((step) => { return step !== undefined })

            this.setState({
                tutorialStepsFiltered: tutorialStepsFiltered
            })
        }

        this.windowResized()
        window.addEventListener('resize', this.windowResized);

        if (!this.props.isTutorial && !isTokenValid()) {
           // window.open('/start/login', '_self')
        }
    }

    setFirstTimeVisit = () => {
        let wasVisited = localStorage.getItem('wasVisited');

        if (!wasVisited) {
            localStorage.setItem('wasVisited', true);
            this.setState({ wasVisited: false })
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps?.selectedProperty !== this.props.selectedProperty) {
            // added for a delay to the card
            let delay = this.props.selectedProperty !== null && this.state.isMobile ? 0 : 0;
            setTimeout(() => {
                this.setState({
                    selectedProperty: this.props.selectedProperty
                })
            }, delay)
        }

        if (prevProps?.isModelLoaded !== this.props.isModelLoaded && this.props.isModelLoaded) {
            this.setFirstTimeVisit()
        }
    }

    windowResized = () => {
        if (window.innerWidth <= 768 && !this.state.isMobile) {
            this.setState({
                isMobile: true
            })
        } else if (window.innerWidth > 768 && this.state.isMobile) {
            this.setState({
                isMobile: false
            })
        }
    }

    pageClick = (e) => {
        if (this.modalRef?.current && !this.modalRef.current.contains(e.target)) {
            this.handleDisplayPropertyFilters(false)
        }

        if (this.infoRef?.current && !this.infoRef.current.contains(e.target)) {
            this.toggleInfo(false);
            this.closeModal();
        }

        if (this.footerRef?.current && !this.footerRef.current.contains(e.target)) {
            this.toggleFooter(false)
        }
        /*
        if (this.state.isMobile && this.propertyDetailsRef?.current && !this.propertyDetailsRef.current.contains(e.target)) {
            this.props.setSelectedProperty(undefined)
            this.setState({
                selectedProperty: false
            })
        }
        */
    }

    toggleFooter = (value) => {
        this.setState({
            isFooterOpen: value !== undefined ? value : !this.state.isFooterOpen
        })
    }

    checkType = (type, propertyFilters, arrayName) => {
        if (!type || !propertyFilters?.length || !arrayName) return;

        propertyFilters = propertyFilters.map(filter => {
            if (filter?.name === type?.name) {
                filter.isChecked = !filter.isChecked
            }

            return filter
        })

        this.setState({
            [arrayName]: propertyFilters
        }, () => this.updateSelectedFilters())
    }

    updateSelectedFilters = () => {
        let propertyStatus = this.state.propertyStatus,
            propertyTypes = this.state.propertyTypes,
            propertySize = this.state.propertySize;

        let allCheckedFilters = [
            ...this.getCheckedFilters(propertyStatus),
            ...this.getCheckedFilters(propertyTypes),
            ...this.getCheckedFilters(propertySize)
        ]

        this.props.setSelectedTypes(allCheckedFilters)
        this.props.setSelectedProperty(undefined)
    }

    getCheckedFilters = (filtersArray) => {
        if (!filtersArray?.length) return [];

        return filtersArray.filter(element => {
            return element?.isChecked
        })
    }

    handleNavigationMenu = (filter, isOptions) => {
        this.handleDisplayPropertyFilters(isOptions)
        ///if (!filter?.type || !this.props.properties?.length) return;

        let navigationMenuData = this.props.properties.filter((property) => {
            if (property?.type === filter?.type || (filter?.types && filter.types.includes(property?.type) )) {
                return property
            }
        })

        this.setState({
            navigationMenuData: navigationMenuData,
            propertyFilters: this.state.propertyFilters.map((propertyFilter) => {
                propertyFilter.isChecked = filter?.name === propertyFilter?.name;
                return propertyFilter
            })
        })
    }

    handleDisplayPropertyFilters = (isOptions) => {
        isOptions = isOptions !== undefined && isOptions
        this.setState({
            displayPropertyFilters: isOptions
        })
    }

    toggleInfo = (value) => {
        this.setState({
            isInfoDisplayed: value !== undefined ? value : !this.state.isInfoDisplayed,
        })

        this.toggleFooter(false)
    }

    renderInfoModal = () => {
        if (this.state.isInfoDisplayed)
            return (
                <div className='info-container'>
                    <div className='info-modal' ref={this.infoRef}>
                        <div className='title'>
                            Parcels Loaded
                        </div>
                        <div className='content'>
                            Parcel information is retrieved from OpenSea. They were last refreshed 309709 minutes ago. If all parcels have been loaded successfully, this number will be 5000. In the event that less than 5000 parcels have been loaded you can still use the map but you are advised to refresh after 30-60 minutes.Note that there is a small delay before changes on OpenSea are reflected on the map. Therefore, always check parcels on OpenSea for the latest status and price information.
                        </div>
                        <div className='buttons'>
                            <div className='button' onClick={() => this.toggleInfo(false)}>
                                Got it
                            </div>
                        </div>
                    </div>
                </div>
            )
    }

    renderFilterButtons = () => {
        if (!this.state.propertyFilters?.length) return;

        return (
            <div className='filter-buttons-container'>
                {
                    this.state.propertyFilters.map(filter => {
                        return (
                            <div className={'filter-button' + (filter?.isChecked ? ' active' : '') + (filter?.isOptions ? ' options' : '')} onClick={() => this.handleNavigationMenu(filter, filter?.isOptions)}>
                                <i class={filter?.icon} />
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    renderPropertyType = (type, propertyFilters, arrayName) => {
        return (
            <div className='checkbox-container' onClick={() => this.checkType(type, propertyFilters, arrayName)}>
                <i class={!type?.isChecked ? 'bx bxs-checkbox checkbox' : 'bx bxs-checkbox-checked checkbox checked'}></i>
                <p>{type?.name}</p>
            </div>
        )
    }

    renderPropertyFilters = (propertyFilters, title, arrayName, width) => {
        if (!propertyFilters?.length) return;

        let isSmallClass = propertyFilters?.length <= 4 ? ' small' : ''

        let style = {
            width: width + 'px',
            minWidth: width + 'px',
            maxWidth: width + 'px'
        }

        return (
            <div className={'filter-property-container' + isSmallClass} style={{ ...style }}>
                <h4>{title}</h4>
                <div className='filters'>
                    {
                        propertyFilters.map((type) => {
                            return this.renderPropertyType(type, propertyFilters, arrayName)
                        })
                    }
                </div>
            </div>
        )
    }

    renderGetItNow = (selected) => {
        if (selected?.owner !== '649226fea5f22c6f9ee5b054') {
            return (
                <div className='button confirmation' onClick={() => this.props.buyProperty(selected?._id)}>
                    <div className='icon'>
                        <i className='bx bxs-home-heart' />
                    </div>
                    <p>
                        Get it now
                    </p>
                </div>
            )
        } else {
            return (
                <div className='button confirmation'>
                    <div className='icon'>
                        <i className='bx bxs-home-heart' />
                    </div>
                    <p>
                        Owned
                    </p>
                </div>
            )
        }
    }

    renderSelectedPropertyDetails = (selected) => {
        if (selected) {
            return (
                <div className={'property-detail' + (this.props.isCameraMoving ? ' moving' : '')} key={selected?._id} ref={this.propertyDetailsRef}>
                    {
                        selected?.image && 
                        <div className='image-container' style={{ backgroundImage: `url(/map/image-0${selected?.image}.jpg)` }}>
                            {/*<div className='badge'>L</div>*/}
                        </div>
                    }
                    <div className='text-container'>
                        {/*<h2>{selected?.description}</h2>
                        <p><label>Token ID:</label> {selected?.tokenID}</p>*/}
                        <h2 style={{textTransform: 'capitalize'}}>{selected?.title}</h2>
                        <p>{selected?.description}</p>
                        {
                            false && selected?.isProperty &&
                            <>
                                <p><label>Sale price:</label> {selected?.salePrice} Eth or with the verse coin</p>
                                <p><label>Last price:</label> {selected?.lastPrice} Eth or with the verse coin</p>
                            </>
                        }

                    </div>

                    <div className='buttons-container'>
                        <div className='button close' onClick={() => this.props.setSelectedProperty(null)}>
                            <p>
                                Close
                            </p>
                        </div>
                        {false && this.renderGetItNow(selected)}
                    </div>
                </div>
            )
        }
    }

    renderFilterProperties = () => {
        if (this.state.displayPropertyFilters)
            return (
                <div className='filter-properties' ref={this.modalRef}>
                    <div className='filter-properties-scroll'>
                        {this.renderPropertyFilters(this.state.propertyTypes, 'Property type', 'propertyTypes')}
                        {/*this.renderPropertyFilters(this.state.propertyStatus, 'Land status', 'propertyStatus')*/}
                        {this.renderPropertyFilters(this.state.propertySize, 'Land size', 'propertySize', 189)}
                    </div>
                </div>
            )
    }

    renderUI = () => {
        if (!this.props.isModelLoaded) return

        return (
            <div className={'navigation-ui-container' + (this.state.isFooterOpen ? ' open' : '')}>
                <div className='navigation-ui'>
                    {this.renderFilterButtons()}
                    {this.renderFilterProperties()}

                    <NavigationMenu
                        data={this.state.navigationMenuData}
                        handleNavigationMenu={this.handleNavigationMenu}
                        setselectedNavigationItem={this.props.setselectedNavigationItem}
                    />
                    <Link to={'/map/tutorial'} className='tutorial-mode-btn'>
                        <div className='icon'>
                            <i class='bx bx-compass' />
                        </div>
                        <div>
                            Tutorial Mode
                        </div>
                    </Link>

                    <div className='toggle-footer' onClick={this.toggleFooter}>
                        <i class={'bx bx-chevron-up'}></i>
                    </div>

                </div>

                {this.renderFooter()}
            </div>
        )
    }

    renderFooter = () => {
        return (
            <div className='footer-container' ref={this.footerRef}>
                <div className='footer'>
                    <Link to={isTokenValid() ? '/map/free-navigation' : '/start/login'} className='verse-logo-container'>
                        <img src={'../verse_logo.png'} className='verse-logo' />
                        <div className='provided-container'>
                            <h3>
                                Provided with <span>❤</span> from
                            </h3>
                            <h4>
                                The Verse Estate company
                            </h4>
                        </div>
                    </Link>
                    <div className='links-container'>
                        <a href='#'>Privacy policy</a>
                        <a href='#'>Terms of use</a>
                        <a href='#'>Cookie policy</a>
                        <a href='#' onClick={() => this.toggleInfo(!this.state.isInfoDisplayed)}>Sync Time</a>
                    </div>

                    <div className='rights-container'>
                        © {new Date().getFullYear()} All Rights are Reserved - Verse Estate
                    </div>
                </div>
            </div>
        )
    }

    renderMapUserUI = () => {
        return (
            <>
                <Header pageTitle={'VERSE'} isFromMap={true} toggleInfo={this.toggleInfo} />
                {this.renderUI()}
                {this.renderSelectedPropertyDetails(this.state.selectedProperty)}
                {this.renderNavigationArrow()}
            </>
        )
    }

    renderNavigationArrow = () => {
        if (!this.props.isTutorial)
            return (
                <>
                    <div className='arrow right' onClick={() => { this.props.navigate(5, 0) }} />
                    <div className='arrow left' onClick={() => { this.props.navigate(-5, 0) }} />
                    <div className='arrow top' onClick={() => { this.props.navigate(0, 10) }} />
                    <div className='arrow bottom' onClick={() => { this.props.navigate(0, -10) }} />
                </>
            )
    }

    renderMapTutorialUI = () => {
        return (
            <>
                {
                    this.state.isMobile ?
                        <Header pageTitle={'VERSE'} isFromMap={true} isTutorial={true} toggleInfo={this.toggleInfo} />
                        :/*
                        <div className='header-container left'>
                            <div className='logo' />
                        </div>*/
                        <Header pageTitle={'VERSE'} isFromMap={true} isTutorial={true} opacity={.8} toggleInfo={this.toggleInfo} />
                    }

                {this.renderTutorialNavigation()}
                {this.renderTutorialTitles(this.props.selectedTutorialStep)}

                {
                    <div className={'navigation-ui-container' + (this.state.isFooterOpen ? ' open' : '') + ' tutorial'}>
                        <div className='navigation-ui'>
                            <Link to={'/map/free-navigation'} className='tutorial-mode-btn'>
                                <div className='icon'>
                                    <i class='bx bx-compass' />
                                </div>
                                <div>
                                    Free Navigation
                                </div>
                            </Link>

                            <div className='toggle-footer' onClick={this.toggleFooter}>
                                <i class={'bx bx-chevron-up'}></i>
                            </div>
                        </div>
                        {this.renderFooter()}
                    </div>
                }
            </>
        )
    }

    renderTutorialNavigation = () => {
        if (!this.state.tutorialStepsFiltered?.length) return;

        return (
            <>
                <div className='tutorial-navigation'>
                    {this.state.tutorialStepsFiltered.map((step, index) => {
                        return this.renderTutorialStep(step, index)
                    })}
                </div>

                {
                    this.props.selectedTutorialStep?.displayName === 'The City' &&
                    <div className='tutorial-scroll-btn' style={{ cursor: 'pointer', pointerEvents: 'all' }} onClick={this.startJourney}>
                        <p>
                            {this.state.isMobile ? 'Swipe up' : 'Scroll'} to start the journey
                        </p>
                    </div>
                }
            </>
        )
    }

    startJourney = () => {
        if (this.state.tutorialStepsFiltered?.length) {
            this.selectTutorialStep(this.state.tutorialStepsFiltered[0]?.children[0])
        }
    }

    setStepActive = (index) => {
        let tutorialStepsFiltered = this.state.tutorialStepsFiltered.map((step, i) => {
            step.isActive = i === index
            return step
        })

        this.setState({
            tutorialStepsFiltered: tutorialStepsFiltered
        })
    }

    checkStepIsActive = (step) => {
        let selectedTutorialStep = this.props.selectedTutorialStep
        let isManuallyActive = step?.isActive;

        let isChildSelected = false;
        if (step?.children?.length) {
            isChildSelected = step?.children?.some(el => el?.displayName === selectedTutorialStep?.displayName)
        }

        return isManuallyActive || isChildSelected;
    }

    selectTutorialStep = (step) => {
        this.props.setSelectedTutorialStep(step);
        this.props.setTargetPosition({
            position: step?.position,
            _3DName: step?._3DName
        })
    }

    renderTutorialStep = (step, index) => {
        return (
            <>
                <div className={'step' + (this.checkStepIsActive(step) ? ' active' : '')} onClick={() => {this.setStepActive(index)}}>
                    <div className='titles-container' onClick={() => this.selectTutorialStep(step)}>
                        <div className='title'>
                            {step?.displayName}
                        </div>
                        {
                            step?.icon &&
                            <div className='icon' style={{ backgroundImage: `url(${step?.icon})` }} />
                        }
                    </div>

                    <div className={'children'} style={{ height: this.checkStepIsActive(step) ? (step?.children?.length * 25) + 10 + 'px' : 0 }}>
                        {
                            step?.children?.length ?
                                step?.children?.map((child, index) => {
                                    return <div
                                        key={index}
                                        onClick={() => this.selectTutorialStep(child)}
                                        className={child?.displayName === this.props.selectedTutorialStep?.displayName ? ' active' : ''}>
                                        {child?.displayName}
                                    </div>
                                }) : ''
                        }
                    </div>
                </div>
            </>
        )
    }

    renderTutorialTitles = (selectedTutorial) => {
        if (selectedTutorial?.description)
            return (
                <div className='tutorial-titles-card' key={selectedTutorial?.displayName || selectedTutorial?.description}>
                    {false && <div className='image' style={{backgroundImage: `url(${selectedTutorial?.image})`}}/>}
                    <div className='title-container without-image'>
                        <div className='title'>
                            {
                                false && selectedTutorial?.icon && selectedTutorial?.icon != '' &&
                                <div className='icon' style={{ backgroundImage: `url(${selectedTutorial?.icon})` }} />
                            }
                            <h2>
                                {selectedTutorial?.displayName}
                            </h2>
                        </div>
                        <p>
                            {selectedTutorial?.description}
                        </p>
                    </div>
                </div>
            )
    }

    handleRenderMapUIOption = () => {
        // temp conditions
        if (this.props.isTutorial)
            return this.renderMapTutorialUI()

        return this.renderMapUserUI()
    }

    renderLoader = () => {
        if (!this.props.isModelLoaded) {
            return (
                <div className='loading-page'>
                    <div className='loader' />
                </div>
            )
        }
    }

    renderPopupVisitor = () => {
        if (!this.state.wasVisited) {
            return (
                <div className='info-container'>
                    <div className='info-modal' ref={this.infoRef}>
                        <div className='title'>
                            Welcome to Verse World, <br></br>The Hyper-Realistic Metaverse.
                        </div>
                        <div className='content'>
                            In the metaverse, endless possibilities await. From immersive gaming to professional meetings, and even acquiring virtual assets like land and homes. 
                            <br/>Join us on desktop or in VR, and connect with people worldwide.
                        </div>
                        <div className='buttons'>
                            <div className='button' onClick={() => this.closeModal()}>
                                Got it
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return
        if (!this.state.wasVisited) {
            return <Modal {
                ...{
                    title: `Welcome to Verse World,
                    The Hyper-Realistic Metaverse.
                    `,
                    content: `In the metaverse, endless possibilities await. From immersive gaming to professional meetings, and even acquiring virtual assets like land and homes. Join us on desktop or in VR, and connect with people worldwide.`,
                    closeModal: this.closeModal,
                }
            } />
        }
    }

    closeModal = () => {
        this.setState({
            wasVisited: true
        })
    }

    render() {
        return (
            <>
                <div className='map-ui'>
                    {this.renderLoader()}
                    {this.renderInfoModal()}
                    {this.renderPopupVisitor()}
                    {this.handleRenderMapUIOption()}
                </div>
            </>
        )
    }
}
