import * as THREE from 'three'
import { CameraControls, OrbitControls, useScroll } from '@react-three/drei'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import TWEEN from '@tweenjs/tween.js';

// Official version of TutorialCamera
const TutorialCameraScroll = (props) => {
    const scroll = useScroll()
    const { camera, scene } = useThree()
    const { tutorialSteps, initialCamera, focusElement, targetPositionProps } = props

    // let lastScrollPosition = 0, currentScroll = 0;

    const [lastScrollPosition, setLastScroll] = useState(0);
    const [currentScroll, setCurrentScroll] = useState(0);

    const [targetPosition, setTargetPosition] = useState(null);
    const [isCameraMoving, setIsCameraMoving] = useState(false);
    const [nextStep, setNextStep] = useState(1/15);
    const [previousStep, setPreviousStep] = useState(0);
    const [index, setIndex] = useState(-1);

    useEffect(() => {
        if (targetPosition !== null && targetPosition !== undefined)
            startMovement(targetPosition)
    }, [targetPosition])

    useEffect(() => {
        camera.fov = props.fov || 20
    }, [props.fov])

    // same as in targetPosition but from props
    useEffect(() => {
        if (targetPositionProps !== null && targetPositionProps !== undefined) {
            startMovement(targetPositionProps?.position)
            getElementAndSetActive(targetPositionProps?._3DName)
        }
    }, [targetPositionProps])

    useEffect(() => {
        if (targetPosition !== null && targetPosition !== undefined)
            startMovement(targetPosition)
    }, [targetPosition])

    useEffect(() => {
    }, [isCameraMoving])

    useFrame((state, delta) => {
        const r1 = scroll.range(0, 1);
        setCurrentScroll(r1)

        let nextStepToReach = nextStep + 1 / tutorialSteps?.length, 
        previousStepToReach = nextStep - 2 * 1 / tutorialSteps?.length;

        let percentage = currentScroll.toFixed(2) * tutorialSteps?.length,
        currentIndex = Math.ceil(percentage);

        if (currentIndex !== index) {
            setIndex(currentIndex);
            handleScrollingLogic(currentIndex)
        }
    })

    const startMovement = (targetPosition) => {
        if (!targetPosition) {
            setIsCameraMoving(false)
            return
        }

        setIsCameraMoving(true)

        let cameraPosition = {
            ...camera?.position,
            ...camera?.rotation
        }
        
        let tween = new TWEEN.Tween(cameraPosition).to(targetPosition, 1000).delay(0).easing(TWEEN.Easing.Cubic.InOut).onUpdate(() => {
            camera.position.x = cameraPosition?.x
            camera.position.y = cameraPosition?.y
            camera.position.z = cameraPosition?.z

            if (targetPosition?._x && targetPosition?._y && targetPosition?._z) {
                camera.rotation.x = cameraPosition?._x
                camera.rotation.y = cameraPosition?._y
                camera.rotation.z = cameraPosition?._z
            }

        }).onComplete(() => {
            camera.updateProjectionMatrix()
            setIsCameraMoving(false)

        }).start();
    }

    const handleScrollingLogic = (index) => {
        let currentTutorialStep = tutorialSteps[index]

        if (currentTutorialStep?.position) {
            startMovement(currentTutorialStep?.position)
        } else {
            setTargetPosition(initialCamera)
        }

        setTutorialStepActive(currentTutorialStep)
        getElementAndSetActive(currentTutorialStep?._3DName)
    }
    
    const setTutorialStepActive = (currentTutorialStep, index) => {
        let result = {
            ...currentTutorialStep,
            index,
        };
        
        props.setSelectedTutorialStep(result);
    }

    const getElementAndSetActive = (name) => {
        if (!name || name === '') return focusElement(undefined)
        
        let object;
        // array added later for multiple elements, originally onmy the else
        if (name instanceof Array) {
            let objects = [];
            for (let el of name) {
                objects.push(scene.getObjectByName(el))
            }
            // should be objects, yes
            object = objects
        } else {
            object = scene.getObjectByName(name);
        }

        focusElement(object)
    }

    return null
}

export default TutorialCameraScroll