import React, { Component } from 'react'
import imageCompression from 'browser-image-compression';
import ImagesGalleryMenu from './ImagesGalleryMenu';
import ImageViewModal from './ImageViewModal';

export default class ProfileBanner extends Component {
    constructor(props) {
        super(props)

        this.state = {
            uploadedPicture: undefined
        }

        this.fileInput = React.createRef();
    }

    handleFileChange = async (e) => {
        if (!e.target?.files?.length) return;

        let file = e.target.files[0];

        if (!file) return;

        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
        }
        try {
            this.setState({
                isLoading: true
            })

            const compressedFile = await imageCompression(file, options);

            const reader = new FileReader();
            reader.readAsDataURL(compressedFile);
            reader.onloadend = () => {
                this.setState({
                    uploadedPicture: reader.result
                })
            }
        } catch (error) {
            this.setState({
                isLoading: false
            })
        }
    }

    renderHoverOption = () => {
        //if (this.props.isOwner)
            return (
                <div className='options' onClick={this.handleClick}>
                   {this.props.fromSettings ? <i class='bx bx-camera' /> : <i class='bx bx-show-alt'/>} 
                </div>
            )
    }

    handleClick = (value) => {
       // if (this.props.isOwner) {
            this.setState({
                isClicked: value
            })
       // }
    }

    setUploadedPicture = (value) => {
        if (!this.props.isOwner) return;

        this.setState({
            uploadedPicture: value
        })

        let e = {
            target: {
                name: 'coverPicture',
                value: value
            }
        }

        this.props.handleInput && this.props.handleInput(e)
    }

    render() {
        return (
            <>
                <div className={'profile-banner' + (this.props.isSettings ? ' settings' : '')} style={{ backgroundImage: `url(${this.state.uploadedPicture || this.props.coverPicture})` }} >
                    {this.renderHoverOption()}
                </div>

                {
                    this.state.isClicked && this.props.isOwner && this.props.fromSettings &&
                    <ImagesGalleryMenu isBanner={true} closeModal={this.handleClick} setUploadedPicture={this.setUploadedPicture} />
                }

                {
                    this.state.isClicked && !this.props.fromSettings && (this.state.uploadedPicture || this.props.coverPicture) &&
                    <ImageViewModal isBanner={true} closeModal={this.handleClick} image={this.state.uploadedPicture || this.props.coverPicture} />
                }

            </>
        )
    }
}
