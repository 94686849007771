import React, { Component } from 'react'
import imageCompression from 'browser-image-compression';
import ImagesGalleryMenu from './ImagesGalleryMenu';
import ImageViewModal from './ImageViewModal';

export default class ProfilePicture extends Component {
    constructor(props) {
        super(props)

        this.state = {
            uploadedPicture: undefined,
            isClicked: false,
        }

        this.fileInput = React.createRef();
    }

    renderHoverOption = () => {
       // if (this.props.isOwner)
            return (
                <div className='options' onClick={() => this.handleClick(!this.state.isClicked)}>
                   {this.props.fromSettings ? <i class='bx bx-camera' /> : <i class='bx bx-show-alt'/>} 
                </div>
            )
    }

    handleClick = (value) => {
        this.setState({
            isClicked: value
        })

        /*
        if (this.props.isOwner) {
            this.fileInput.click();
        }*/
    }

    handleFileChange = async (e) => {
        if (!e.target?.files?.length) return;

        let file = e.target.files[0];

        if (!file) return;

        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          }
          try {
            this.setState({
                isLoading: true
            })

            const compressedFile = await imageCompression(file, options);
        
            const reader = new FileReader();
            reader.readAsDataURL(compressedFile);
            reader.onloadend = () => {
                this.setState({
                    uploadedPicture: reader.result
                })
            }
          } catch (error) {
            this.setState({
                isLoading: false
            })
          }
    };

    setUploadedPicture = (value) => {
        if (!this.props.isOwner) return;

        this.setState({
            uploadedPicture: value
        })

        let e = {
            target: {
                name: 'profilePicture',
                value: value
            }
        }

        this.props.handleInput && this.props.handleInput(e)
    }

    render() {
        return (
            <>
                <div className='profile-picture-container'>
                    {this.renderHoverOption()}
                    <div className='profile-picture' style={{ backgroundImage: `url(${this.state.uploadedPicture || this.props.picture || 'https://verseworldstorage.blob.core.windows.net/verseweb/VerseWorld/empty.jpg'})` }}>
                        <div className='verified-badge-container'>
                            <div className='verified-badge' />
                        </div>

                        {/* <input
                            type='file'
                            ref={(input) => (this.fileInput = input)}
                            style={{ display: 'none' }}
                            accept="image/*"
                            onChange={this.handleFileChange}
                        />*/}
                    </div>
                </div>

                {
                    this.state.isClicked && this.props.isOwner && this.props.fromSettings &&
                    <ImagesGalleryMenu closeModal={this.handleClick} setUploadedPicture={this.setUploadedPicture}/>
                }

                {
                    this.state.isClicked && !this.props.fromSettings &&
                    <ImageViewModal closeModal={this.handleClick} image={this.state.uploadedPicture || this.props.picture || 'https://verseworldstorage.blob.core.windows.net/verseweb/VerseWorld/empty.jpg'} />
                }
            </>
            
        )
    }
}
