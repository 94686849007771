import React from 'react'
import Footer from '../components/Common/Footer'
import { useParams } from 'react-router';
import ArticleEdit from '../components/ArticleEdit';
import { useEffect } from 'react';
import { isAdmin } from '../util/service';

export default function ArticleEditContainer(props) {
    let { slug } = useParams();
    useEffect(() => {
        if (!isAdmin()) {
            window.open(`/article/${slug}`, '_self')
        }
    }, [])

    if (isAdmin())
        return (
            <>
                <ArticleEdit slug={slug} {...props}/>
            </>
        )

    return null
}
