import React from 'react'
import ProfilePublic from '../components/ProfilePublic';
import Footer from '../components/Common/Footer';
import { useParams } from 'react-router';

export default function ProfileContainer() {
    let { userName } = useParams();

    return (
        <>
            <ProfilePublic userName={userName}/>
            <Footer />
        </>
    )
}
