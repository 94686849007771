import * as THREE from 'three'
import { CameraControls, OrbitControls, useScroll } from '@react-three/drei'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import TWEEN from '@tweenjs/tween.js';

// Should be deprecated
const TutorialCamera = (props) => {
    const scroll = useScroll()
    const { camera } = useThree()
    const { tutorialSteps, initialCamera } = props

    // let lastScrollPosition = 0, currentScroll = 0;

    const [lastScrollPosition, setLastScroll] = useState(0);
    const [currentScroll, setCurrentScroll] = useState(0);

    const [targetPosition, setTargetPosition] = useState(null);
    const [indexState, setIndex] = useState(0);
    const [isCameraMoving, setIsCameraMoving] = useState(false);
    const [isScrollingDownState, setIsScrollingDown] = useState(null);
    const [isScrollingDownStatePrevState, setIsScrollingDownStatePrevState] = useState(false);
    let index = 0

    useEffect(() => {
        if (targetPosition !== null && targetPosition !== undefined)
            startMovement(targetPosition)
    }, [targetPosition])

    useEffect(() => {
    }, [isCameraMoving])

    useEffect(() => {
        if (isScrollingDownState !== null && isScrollingDownState !== isScrollingDownStatePrevState) {
            handleScrollingLogic()
            setIsScrollingDownStatePrevState(!isScrollingDownState)
        }

    }, [isScrollingDownState])

    useFrame((state, delta) => {
        /*const r1 = scroll.range(0 / 4, 1 / 4)
        const r2 = scroll.range(1 / 4, 1 / 4)*/
        const r1 = scroll.range(0, 1);
        const margin = scroll.range(0, 1 / 15);

        // lastScrollPosition = currentScroll
        setLastScroll(currentScroll)
        // currentScroll = r1
        setCurrentScroll(r1)

        let isScrollingDown = currentScroll > lastScrollPosition + 0.001,
            isScrollingUp = currentScroll + 0.001 < lastScrollPosition;

        if (isScrollingDown && !isCameraMoving) {
            setIsScrollingDown(true) 
        } else if (isScrollingUp && !isCameraMoving) {
            setIsScrollingDown(false)
        }
    })

    const handleScrollingLogic = () => {
        let indexAddition = isScrollingDownState ? indexState + 1 : indexState - 1;

        indexAddition = Math.max(0, indexAddition);

        indexAddition = Math.min(tutorialSteps?.length - 1, indexAddition);

        setIndex(indexAddition)
        let currentTutorialStep = tutorialSteps[indexState]

        if (currentTutorialStep?.position) {
            // let targetPosition = currentTutorialStep?.target?.getWorldPosition(new THREE.Vector3());
            let targetPositionNew = currentTutorialStep?.position;
            setTutorialStepActive(currentTutorialStep)
            setTargetPosition(null)
            setTimeout(() => {
                setTargetPosition(targetPositionNew);
            }, 500)
            
        } else {
            setTargetPosition(null)
            setTargetPosition(initialCamera)
        }
    }

    const startMovement = (targetPosition) => {
        if (!targetPosition) {
            setIsCameraMoving(false)
            return
        }

        setIsCameraMoving(true)

        let cameraPosition = {
            ...camera?.position,
            ...camera?.rotation
        }

        //targetPosition.z = targetPosition?.z || 0 + 25
        let tween = new TWEEN.Tween(cameraPosition).to(targetPosition, 1000).delay(0).easing(TWEEN.Easing.Cubic.InOut).onUpdate(() => {
            camera.position.x = cameraPosition?.x
            camera.position.y = cameraPosition?.y
            camera.position.z = cameraPosition?.z

            if (targetPosition?._x && targetPosition?._y && targetPosition?._z) {
                camera.rotation.x = cameraPosition?._x
                camera.rotation.y = cameraPosition?._y
                camera.rotation.z = cameraPosition?._z
            }

        }).onComplete(() => {
            //controls.setLookAt(...targetPosition.toArray(), ...new THREE.Vector3(0, 0, 0).toArray(), true)

            camera.updateProjectionMatrix()
            setIsCameraMoving(false)

        }).start();
    }

    const setTutorialStepActive = (currentTutorialStep, index) => {
        let result = {
            ...currentTutorialStep,
            index,
        };
        
        setTimeout(() => {
            props.setSelectedTutorialStep(result);
        }, 1000)
    }

    return null
}

export default TutorialCamera