import React, { Component } from 'react'
import News from '../components/News'
import Footer from '../components/Common/Footer'

export default class NewsContainer extends Component {
    componentDidMount() {
        // temp
        window.open('/', '_self')
    }

    render() {
        return (
            <>
                <News />
                <Footer />
            </>
        )
    }
}
