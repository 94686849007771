import React, { Component } from 'react'

export default class ConfirmationModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }

        this.editImage = React.createRef();
    }

    componentDidMount = () => {
        window.addEventListener('mousedown', this.pageClick, false);
    }

    pageClick = (e) => {
        if (this.editImage && this.editImage.current && !this.editImage.current.contains(e.target)) {
            this.props.confirmationData?.cancelAction()
        }
    }

    confirm = (parameter) => {
        this.props.confirmationData?.confirmationAction(parameter);
        // to close the modal
        this.props.confirmationData?.cancelAction()
    }

    render() {
        const { confirmationData } = this.props;

        if (confirmationData !== null)
            return (
                <div className='image-edit-container'>
                    <div className='image-edit' ref={this.editImage}>
                        <i class='cross bx bxs-x-circle' onClick={() => confirmationData?.cancelAction()} />
                        <div>
                            <p style={{ paddingBottom: 0 }}>Please confirm your action</p>

                            <p style={{ fontWeight: 200 }}>Are you sure you want to {confirmationData?.message}</p>

                            <div className='input-container buttons'>
                                <div class="button cancel" onClick={() => confirmationData?.cancelAction()}>
                                    <div>
                                        <p>Cancel</p>
                                    </div>
                                </div>
                                <div class="button" onClick={() => this.confirm(confirmationData.parameter)}>
                                    <div>
                                        <p>Confirm</p>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            )
        return null;
    }
}
