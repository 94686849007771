import axios from 'axios';
import React, { Component } from 'react'
import {authHeader} from '../../util/service';

export default class AuthorEditOptions extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isListOpen: false,
            isListTypesOpen: false,
            authors: [],
            types: [
                {
                    value: 1,
                    name: 'Featured'
                },
                {
                    value: 2,
                    name: `Don't miss`
                },
                {
                    value: 3.1,
                    name: 'Selected movie'
                },
                {
                    value: 3,
                    name: 'Cinema'
                },
                {
                    value: 4,
                    name: 'Stadium'
                },
                {
                    value: 5,
                    name: 'Trading center'
                },
            ]
        }

        this.elementRef = React.createRef();
        this.elementRefType = React.createRef();
    }

    componentDidMount = () => {
        window.addEventListener('mousedown', this.pageClick, false);

        this.fetchAuthors()
    }

    pageClick = (e) => {
        if (this.elementRef && this.elementRef.current && !this.elementRef.current.contains(e.target) && this.state.isListOpen) {
            this.handleOpen(false)
        }

        if (this.elementRefType && this.elementRefType.current && !this.elementRefType.current.contains(e.target) && this.state.isListTypesOpen) {
            this.handleOpenType(false)
        }
    }

    fetchAuthors = () => {
        axios.get(`https://versemail.azurewebsites.net/news/world/authors`, { headers: authHeader() }).then(response => {
            if (response?.data) {
                this.setState({
                    authors: response?.data
                })
            }
        }).catch((error) => {
            console.log(error.response)
        });
    }

    renderAuthorsList = () => {
        if (this.state.authors?.length) {
            return this.state.authors.map(author => {
                return (
                    <div
                        onClick={() => this.selectAuthor(author)}
                        className={author?._id === this.props.author?._id ? 'selected' : ''}>
                        {author?.firstName}
                    </div>
                )
            })
        } else
            return 'No authors yet...'
    }

    renderArticleTypesList = () => {
        if (this.state.types?.length) {
            return this.state.types.map(type => {
                return (
                    <div
                        onClick={() => this.selectType(type)}
                        className={type?.value === this.props.article?.type ? 'selected' : ''}>
                        {type?.name}
                    </div>
                )
            })
        } else
            return 'No authors yet...'
    }

    handleOpen = (value) => {
        this.setState({
            isListOpen: value
        })
    }

    handleOpenType =  (value) => {
        this.setState({
            isListTypesOpen: value
        })
    }

    selectAuthor = (author) => {
        this.props.setAuthor(author)
        this.handleOpen(false)
    }

    selectType = (type) => {
        if (!this.props.article || !type)  return;

        let article = {
            ...this.props.article,
            type: type?.value
        }
        this.props.setArticle(article)
        this.handleOpenType(false)
    }

    renderArticleType = () => {
        return (
            <div className='author-edit-options' ref={this.elementRefType}>
                <div className='authors-list-container' style={{ marginRight: 0 }}>
                    <div className='opener' onClick={() => this.handleOpenType(!this.state.isListTypesOpen)}>
                        <span>Select article type</span>
                        <i class='bx bx-chevron-down' />
                    </div>

                    <div className={'list' + (this.state.isListTypesOpen ? ' open' : '')}>
                        {this.renderArticleTypesList()}
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <>
                <div className='author-edit-options' ref={this.elementRef}>
                    <div className='authors-list-container'>
                        <div className='opener' onClick={() => this.handleOpen(!this.state.isListOpen)}>
                            <span>Select an author for this article</span>
                            <i class='bx bx-chevron-down' ></i>
                        </div>

                        <div className={'list' + (this.state.isListOpen ? ' open' : '')}>
                            {this.renderAuthorsList()}
                        </div>
                    </div>
                    <div className='btn add' onClick={() => this.selectAuthor(null)}>
                        <i class='bx bxs-user-plus' ></i>
                    </div>
                </div>

                {this.renderArticleType()}
            </>
        )
    }
}
