import React, { Component, useEffect, useState } from 'react'
import ForumHero from '../components/Forum/ForumHero'
import Header from '../components/MapUI/Header'
import Footer from '../components/Common/Footer'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import ContentTable from '../components/Forum/ContentTable'
import AskQuestion from '../components/Forum/AskQuestion'
import HotQuestions from '../components/Forum/HotQuestions'
import Topic from '../components/Forum/Topic'
import AddTopic from '../components/Forum/AddTopic'
import forumtypes from '../util/ForumTypes'
import { isTokenValid } from '../util/service'
import { scrollTo } from '../util/util'

export default function ForumContainer(props) {
    let { slug } = useParams();
    const [forum, setForum] = useState(null);

    useEffect(() => {
        window.open('/', '_self')
    }, [])

    useEffect(() => {
        scrollTo(0)
    }, [slug])

    const translateType = (type) => {
        if (props.isForumHome) return setForum(null); 

        let result;
        for (let element of forumtypes) {
            if (element.type === type) {
                result = element;
                setForum(result)
                break
            }
        }
    }

    useEffect(() => {
        translateType(props.type)
    }, [props.type])

    const renderNavigation = () => {
        return (
            <div className='navigation-container'>
                <Link to={'/forum#content'}>Home</Link>
                {
                    forum &&
                    <>
                        <i className='bx bx-chevron-right' /><Link to={'/forum/' + (forum?.url)}>{forum?.title}</Link>
                    </>
                }
                {
                    props.isTopic && slug &&
                    <>
                        <i className='bx bx-chevron-right' /><p>{slug}</p>
                    </>
                }
                {
                    props.isAdd &&
                    <>
                        <i className='bx bx-chevron-right' /><p>Ask Question</p>
                    </>
                }
            </div>
        )
    }

    const renderContent = () => {
        return (
            <div className='content' id='content'>
                <div className='main'>
                    <AskQuestion {...props}/>
                    {handleContent()}
                </div>

                <div className='secondary'>
                    <HotQuestions />
                </div>
            </div>
        )
    }

    const handleContent = () => {
        if (props.isTopic)
            return <Topic forumTitle={forum?.title} slug={slug} translateType={translateType}/>
        else if (props.isAdd) 
            return <AddTopic />
        else 
            return <ContentTable type={forum?.title} forum={forum} isForumHome={props.isForumHome}/>
    }

    return null;

    return (
        <div className='forum-page'>
            <Header pageTitle={'FORUM'} />
            <ForumHero background={props.isAdd ? '/hero-forum-add.svg' : '/hero-forum.png'} {...props}/>
            {renderNavigation()}
            {renderContent()}
            <Footer />
        </div>
    )
}