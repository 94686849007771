import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Menu extends Component {
	render() {
		const {isUsersPage} = this.props;

		return (
			<div className={'menu' + (this.props.isMenuOpen ? ' open' : '')}>
				<div className='menu-title'>
					Dashboard
				</div>
				<div className='items-container'>
					<Link to={'/dashboard/newsletter'} className={isUsersPage && 'selected'} >
						<i class='bx bx-envelope-open' />
						<div>
							Newsletters
						</div>
					</Link>
                    <Link to={'/ctqbc/create/article'} >
						<i class='bx bxs-book-open' />
						<div>
							Create Article
						</div>
					</Link>
				</div>
			</div>
		)
	}
}
