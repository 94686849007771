import React, { useEffect, useState } from 'react';
import {
    Connection,
    clusterApiUrl,
    LAMPORTS_PER_SOL,
    PublicKey,
} from "@solana/web3.js";
import {
    getParsedNftAccountsByOwner,
    isValidSolanaAddress,
    createConnectionConfig,
} from "@nfteyez/sol-rayz";
import axios from 'axios';

export default function WalletSolana() {
    const [nftData, setNftData] = useState([]);

    // create a connection to devnet
    const createConnection = () => {
        return new Connection(clusterApiUrl('mainnet-beta'), 'confirmed');
    };

    // check solana on window. This is useful to fetch the address of your wallet.
    const getProvider = () => {
        if ("solana" in window) {
            const provider = window.solana;
            if (provider.isPhantom) {
                return provider;
            }
        }
        return null;
    };

    // Function to get all NFT information.
    const getAllNftData = async () => {
        try {
            const connection = createConnection();
            const provider = getProvider();
            if (provider) {
                let ownerToken = "3nhmsoT6w5vGt6NaPzSRJPCuz7S2q2Pit5UAEXeHLZT4" //provider.publicKey;
                const isValid = isValidSolanaAddress(ownerToken);

                const nfts = await connection.getAccountInfo(new PublicKey(ownerToken), "confirmed");

                return nfts;
            }
        } catch (error) {
            console.error(error);
        }
    };

    // Function to get all NFT data
    const getNftTokenData = async () => {
        try {
            let nftData = await getAllNftData();
            let arr = [];
            for (let i = 0; i < nftData.length; i++) {
                console.log("NFT URI:", nftData[i].data.uri);
                let val = await axios.get(nftData[i].data.uri);
                arr.push(val);
            }
            return arr;
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        async function fetchData() {
            let res = await getAllNftData();
            setNftData(res);
        }

        fetchData();
    }, []);

    return (
        <div>
            <h1>WalletSolana</h1>
            {/* Render your NFT data here */}
            {nftData?.map((nft, index) => (
                <div key={index}>
                    NFT URI: {nft.data.uri}
                </div>
            ))}
        </div>
    );
}
