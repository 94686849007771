import axios from 'axios'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import forumtypes from '../../util/ForumTypes'
import { scrollTo } from '../../util/util'
import { authHeader } from '../../util/service'

export default class HotQuestions extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            hotQuestions: []
        }
    }

    componentDidMount = () => {
        axios.get(`https://versemail.azurewebsites.net/forum/question/trending`, { headers: authHeader() }).then(response => {
            if (response?.data) {
                this.setState({hotQuestions: response.data})
            }

            this.toggleIsLoading(false)
        }).catch((error) => {
            console.log(error.response)
            this.setState({isLoading: false})
        });
    }

    toggleIsLoading = (value) => {
        this.setState({isLoading: value})
    } 

    getTypeColorAndIcon = (type) => {
        let result;
        for (let element of forumtypes) {
            if (element.type === type) {
                result = element;
                break
            }
        }

        return result
    }

    renderList = () => {
        if (this.state.isLoading) {
            return ('Loading...')
        } 
        return this.state.hotQuestions.map((question) => {
            return (
                <Link to={'/forum/topic/' + question?.slug} className='question' onClick={this.handleClick}>
                    <div className='icon' style={{backgroundColor: this.getTypeColorAndIcon(question?.type).color}}>
                        <i class={this.getTypeColorAndIcon(question?.type).icon} />
                    </div>
                    <div className='text'>
                        {question?.question || question?.text}
                    </div>
                    <div className='engagement'>
                        {question?.answers?.length}
                    </div>
                </Link>
            )
        })
    }

    handleClick = () => {
        scrollTo(500)
    }

    render() {
        return (
            <div className='category'>
                <h4>Hot Questions</h4>
                <div className='list'>
                    {this.renderList()}
                </div>
            </div>
        )
    }
}
