import { Html } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React, { useEffect, useState } from 'react'
import * as THREE from 'three'

export default function Annotation({ children, ...props }) {
    const [active, setActive] = useState(false);
    const { target, property, index, customPosition } = props
    const targetPosition = target?.getWorldPosition(new THREE.Vector3());
    const { camera } = useThree()

    const getTypeIcon = (type) => {
        switch(type) {
            case 'TRANSPORTATION':
                return 'bx bxs-train'
            case 'GAMES':
            case 'SEMI_COLLECTIVE':
            case 'ACTIVITIES':
                return 'bx bxs-joystick-alt'
            case 'NFT':
            case 'SKYSCRAPER':
                return 'bx bxs-shopping-bag'
            default:
                return 'bx bxs-train'
        }
    }

    // needed to have the labels have the same size in 3D 
    const getScaleConstant = (targetPosition, camera) => {
        return 1.5
        var scaleVector = new THREE.Vector3();
        var scaleFactor = 100;
        var scale = scaleVector.subVectors(targetPosition, camera?.position).length() / scaleFactor;
        return scale
    }

    if (property?.title)
        return (
            <Html
                position={[customPosition?.x || targetPosition?.x, customPosition?.y || targetPosition?.y + 3, customPosition?.z || targetPosition?.z]}
                transform
                sprite
            >
                <div 
                    className={"property-annotation-container-official " + (props.isActive ? '' : 'active')} 
                    style={{transform: `scale(${getScaleConstant(targetPosition, camera)})`}}
                    onClick={() => props.focusElement(property, target)}
                >
                    <div className='property-label'>
                        <i className={getTypeIcon(property?.type)}/> {property?.title}
                    </div>
                </div> 
            </Html>
        )

    return null

    // old version
    return (
        <Html
            position={[targetPosition?.x, targetPosition?.y + 7, targetPosition?.z]}
            transform
            sprite
        >
            <div className={"property-annotation-container " + (props.isActive ? '' : 'closed')}>
                <div className='property-annotation-details'>
                    <div className='image-container' style={{backgroundImage: `url(${property?.image})`}}>
                        <i class={'bx bxs-heart' + (active ? ' active' : '')} onClick={() => setActive(!active)}/>
                    </div>
                    <div className='text-container'>
                        <p className='id'><label><i class='bx bx-bolt-circle'></i></label> {property?.tokenID}</p>
                        <h3>Stadium {property?.type}</h3>
                        <h2>{property?.description}</h2>
                        <p>{property?.lastPrice} Eth</p>
                    </div>
                </div>
                <div className='property-label' onClick={() => props.openCloseAnnotations(props.isActive ? null : index)}>
                    {property?.salePrice} ETC 
                    {
                        active &&
                        <span><i class='bx bxs-heart'/></span>
                    }
                </div>
            </div>
        </Html>
    )
}