import React, { Component } from 'react'
import imageCompression from 'browser-image-compression';

export default class EditImage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isDisplayed: false,
            image: '',
            isLoading: false
        }

        this.editImage = React.createRef();
    }

    componentDidMount = () => {
        window.addEventListener('mousedown', this.pageClick, false);
    }

    pageClick = (e) => {
        if (this.editImage && this.editImage.current && !this.editImage.current.contains(e.target)) {
            this.closeEditImage()
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.selectedImage !== this.props.selectedImage) {
            this.setState({
                isDisplayed: this.props.selectedImage?.src !== '',
                image: this.props.selectedImage,
            })
        }
    }

    closeEditImage = () => {
        this.props.toggleSelectImage && this.props.toggleSelectImage()
    }

    handleImageChange = (e) => {
        this.setState({
            image: {
                ...this.state.image,
                src: e.target.value
            }
        })
    }

    updateSRC = () => {
        this.props.updateImage(this.state.image?.src);

        if (this.state.image?.src !== '')
            this.closeEditImage()
    }

    handleFileInput = async (e) => {
        if (!e.target?.files?.length) return;

        let file = e.target.files[0];

        if (!file) return;

        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
        }
        try {
            this.setState({
                isLoading: true
            })

            const compressedFile = await imageCompression(file, options);

            const reader = new FileReader();
            reader.readAsDataURL(compressedFile);
            reader.onloadend = () => {
                this.props.updateImage(reader.result);
                this.closeEditImage();
                this.setState({
                    isLoading: false
                })
            }
        } catch (error) {
            this.setState({
                isLoading: false
            })
        }
    };

    render() {
        if (this.state.isDisplayed)
            return (
                <div className='image-edit-container'>
                    <div className='image-edit' ref={this.editImage}>
                        <i class='cross bx bxs-x-circle' onClick={() => this.closeEditImage()} />
                        <div>
                            <p>Edit image source</p>
                            <div className='input-container'>
                                <input type='text'
                                    className='input-src'
                                    placeholder='Image SRC'
                                    value={this.state.image?.src || ''}
                                    onChange={(e) => this.handleImageChange(e)}
                                />

                                <div class="button" onClick={() => this.updateSRC()}>
                                    <div>
                                        <i class="bx bx-link" />
                                        <p>{this.state.isLoading ? 'Loading...' : 'Update'}</p>
                                    </div>
                                </div>
                            </div>

                            <input type="file" onChange={this.handleFileInput} style={{ marginTop: '15px' }} accept="image/*" />

                        </div>
                    </div>
                </div>
            )
        return null;
    }
}
