import React, { Component } from 'react'
import axios from 'axios'
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageCompress from 'quill-image-compress';
import ReCAPTCHA from "react-google-recaptcha";
import { authHeader, isTokenValid } from '../../util/service';

Quill.register('modules/imageCompress', ImageCompress);

export default class AddResponse extends Component {
    constructor(props) {
        super(props)

        this.state = {
            userResponse: '',
            isError: '',
            isCatptchaSubmitted: false,
        }
    }

    renderAddResponse = (content) => {
        let modules = {
            toolbar: [
                ['bold', 'italic', 'underline'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['link', 'image', 'video'],
            ],
            imageCompress: {
                quality: .75,
                maxWidth: 1000,
                maxHeight: 1000,
                imageType: 'image/jpeg',
            }
        };

        return (
            <ReactQuill
                className='p'
                modules={modules}
                value={content}
                name='content'
                placeholder='Your answer...'
                onChange={(html) => this.handleContentChange(html)}
            />
        )
    }

    handleContentChange = (value) => {
        this.setState({
            userResponse: value
        })
    }

    cancel = () => {
        this.handleContentChange('')
    }

    addResponse = () => {
        const value = this.state.userResponse
        
        // calculating size first
        const size = new Blob([value]).size / 1000000;
        if (size > 5) return alert('You have exceeded 5MB limit')

        let isNotDisabled = this.state.userResponse && this.state.userResponse !== '' && this.state.userResponse !== '<p><br></p>'
        if (!isNotDisabled && !this.props.topic?._id) return

        if (!this.state.isCatptchaSubmitted) {
            return this.setState({
                isError: 'Please check the CAPTCHA'
            })
        };

        this.postResponse()
    }

    postResponse = () => {
        let answer = {
            content: this.state.userResponse,
            forum: this.props.topic?.forum,
            question: this.props.topic?._id,
        }
        
        this.props.setAnswerBeingAdded(answer)

        this.setState({
            userResponse: null,
            isError: '',
            isCatptchaSubmitted: false,
            isResponsePosted: false
        })

        axios.post(`https://versemail.azurewebsites.net/forum/answer`, {answer}, { headers: authHeader() }).then(response => {
            if (response?.data) {
                this.props.setAnswerBeingAdded(null)
                this.props.addAnswerToList(response?.data)
            }
            
            this.setState({
                isResponsePosted: true
            }, () => {
                this.setState({isResponsePosted: false})
            })

        }).catch(error => {
            this.props.setAnswerBeingAdded(null)
            this.setState({isError: 'There was an error adding your answer. Please retry.', isCatptchaSubmitted: false})
        });
    }

    renderButtons = () => {
        let isNotDisabled = this.state.userResponse && this.state.userResponse !== '' && this.state.userResponse !== '<p><br></p>'
        return (
            <div className='section'>
                <div className='btns-container'>
                    <div className='btn cancel' onClick={this.cancel}>
                        Cancel
                    </div>

                    <div className={'btn confirm' + (isNotDisabled ? '' : ' disabled')} onClick={this.addResponse}>
                        Add Answer
                    </div>
                </div>
            </div>
        )
    }

    renderError = () => {
        if (this.state.isError) {
            return (
                <div className='status-bar deleted missing'>
                    <p>
                        {this.state.isError}
                    </p>
                </div>
            )
        }
    }

    renderRecaptcha = () => {
        if (!this.state.isCatptchaSubmitted && !this.state.isResponsePosted)
            return (
                <div className='recaptcha'>
                    <ReCAPTCHA
                        sitekey="6LcYJj0pAAAAAJ9kMa8y04EZqM7yIBgYE1LPwbs1"
                        onChange={this.verifyCaptcha}
                        onExpired={() => this.setState({ isCatptchaSubmitted: false })}
                    />
                    {this.renderRequiredError(this.state.isCaptchaError, 'You need to check the captcha before submitting')}
                </div>
            )
    }

    verifyCaptcha = (token) => {
        this.setState({
            isCatptchaSubmitted: true
        })
    }

    renderRequiredError = (requiredCondition, message) => {
        if (requiredCondition)
            return (
                <div className='error'>
                    {message}
                </div>
            )
    }

    render() {
        if (!isTokenValid()) return null;

        return (
            <div className='add-response'>
                {this.renderAddResponse(this.state.userResponse)}
                {this.renderRecaptcha()}
                {this.renderButtons()}
                {this.renderError()}
            </div>
        )
    }
}
