import * as THREE from 'three'
import { useEffect, useMemo, useRef, useState } from 'react'
import { applyProps, useFrame, useThree } from '@react-three/fiber'
import { useGLTF, Html, useProgress, ContactShadows } from '@react-three/drei'
import React, { Component } from 'react';
import Annotation from './Annotation';

export function LoadModel(props) {
    const { path, properties, focusElement, selectedProperty, selectedTypes, tutorialSteps, isTutorial, ground } = props
    let { scene, nodes, materials, animations } = useGLTF(path);
    const groupRef = useRef();
    const [isAnimationCalled, setIsAnimationCalled] = useState(false);

    useEffect(() => {
        // seems redundant but we only need the data here once
        let parents = []; // added for objects that have same parent, only add it once
        let propertiesData = Object.values(nodes).map((child) => {
            if (child.isMesh) {
                let childProperty, returnedData;
                for (let property of properties) {
                    if (property?.name === child?.name || (property?.name.includes(child?.name) && !parents.includes(property?.parent))) {
                        childProperty = property
                        returnedData = {
                            property: childProperty,
                            target: child
                        }
                        property?.parent && parents.push(property?.parent)
                        break;
                    }
                }

                return returnedData

            }
        });

        // seems like a repetition of the previous loop but needed for tutorials positions and clarity
        if (tutorialSteps?.length) {
            let tutorialStepsData = tutorialSteps.map((tutorialStep) => {
                let target = getNodeByName(tutorialStep?._3DName, nodes)

                let children = []
                if (tutorialStep?.children?.length) {
                    children = tutorialStep?.children.map((childTutorial) => {
                        let targetChild = getNodeByName(childTutorial?._3DName, nodes)

                        return {
                            ...childTutorial,
                            target: targetChild
                        }
                    })
                }

                tutorialStep = {
                    ...tutorialStep,
                    target: target,
                    children: children
                }

                return tutorialStep;
            })

            props.setTutorialSteps(tutorialStepsData)
        }

        props.setPropertiesData(propertiesData.filter((e) => { return e !== undefined }));
    }, [])

    useEffect(() => {
        props.setIsLoaded(true);

        /*let modeltemp = Object.values(nodes).map((child) => {
            if (child.isMesh) {
                return Part(child, properties, focusElement, selectedProperty, selectedTypes, isTutorial, ground)
            }
        }).filter((e) => e !== null);  */

    }, [nodes])

    useEffect(() => {
    }, [animations])


    if (animations?.length && !isAnimationCalled) {
        setIsAnimationCalled(true);
        let modelAnim = scene;
        let mixer = new THREE.AnimationMixer(modelAnim);
        let mixerSlowed = new THREE.AnimationMixer(modelAnim);
        mixerSlowed.timeScale = .2;
        mixer.timeScale = 1;
        animations.forEach(clip => {
            let action = mixer.clipAction(clip);

            clip.tracks = clip.tracks.map((track) => {
                track.setInterpolation(THREE.InterpolateLinear)
                return track
            })

            if (clip?.name === "CableMachine1Action" || clip?.name === "CableMachine2Action") {
                action = mixerSlowed.clipAction(clip)
            }
            action.play();
        });

        props.addMixer(mixer);
        props.addMixer(mixerSlowed)
    }

    let model = Object.values(nodes).map((child) => {
        if (child.isMesh) {
            return Part(child, properties, focusElement, selectedProperty, selectedTypes, isTutorial, ground)
        }
    }).filter((e) => e !== null);


    return (
        <group ref={groupRef} {...props}>
            {model}
        </group>
    );
}

const Part = (child, properties, focusElement, selectedProperty, selectedTypes, isTutorial, ground) => {
    let lowMaterials = ['Water', 'Landscape_RelaxOptimized']

    const objRef = useRef();
    const [hovered, setHover] = useState(false);
    let { camera, scene } = useThree();

    useEffect(() => {
        document.body.style.cursor = hovered ? 'pointer' : 'auto'
    }, [hovered])

    let isSelected = selectedProperty?.length ? getSelectedProperties(selectedProperty, child) : selectedProperty?.name === child?.name,
        childProperty; // from the loop. If it's undefined then the child is not is childProperties

    for (let property of properties) {
        if (property?.name instanceof Array && property?.name?.length) {
            for (let name of property.name) {
                if (name === child?.name) {
                    childProperty = property;
                    break;
                }
            }
        }

        if (property?.name === child?.name) {
            childProperty = property
            break;
        }
    }

    if (selectedProperty?.parent && selectedProperty?.parent === childProperty?.parent && !isSelected) {
        isSelected = true;
    }

    let isHighlighted = selectedTypes?.length && childProperty ? selectedTypes.some(el => el?.type === childProperty?.type) : false
    
    delete child.material._listeners;

    let material = new THREE.MeshPhysicalMaterial({
        ...child.material,
        toneMapped: false,
        color: handleElementsColor(child.name, hovered, isSelected, isHighlighted, isTutorial),
    });

    if (child.name !== 'Water' && child.name !== 'Landscape_RelaxOptimized') {
        material.metalness = .5;
        material.roughness = .9;
    } else {
        material.metalness = .7;
        material.roughness = .95;
    }

    if (child.name === 'Water') {
        material.metalness = .98;
        material.roughness = .7;
        //material.visible = !ground
    }

    if (child?.name !== 'Water') {
        child.castShadow = true;
        child.receiveShadow = true;
    }

    return (
        <>
            <mesh
                key={child.id}
                ref={objRef}
                toneMapped={false}
                castShadow
                receiveShadow
                onPointerOver={(e) => {
                    e.stopPropagation();

                    if (childProperty && !isTutorial) { // equivalent to isChildInProperties boolean
                        setHover(true);
                    }
                }}
                onPointerOut={(e) => {
                    e.stopPropagation();

                    if (childProperty && !isTutorial) {
                        setHover(false);
                    }
                }}
                onClick={(e) => {
                    e.stopPropagation();

                    if (childProperty && !isTutorial) {
                        var target = new THREE.Vector3(),
                            position = child?.getWorldPosition(target)

                        focusElement(childProperty, child);
                    }
                }}
            >
                <primitive object={child} material={material} />
            </mesh>
        </>

    );
};

let handleElementsColor = (name, hovered, isSelected, isHighlighted, isTutorial) => {
    if (isTutorial && isSelected) {
        return "#fad57f"
    }

    if (isSelected) {
        return "#945f72"
    } else if (isHighlighted) {
        return "#74AAE2"
    } else if (hovered) {
        return "#fad57f"
    } else {
        switch (name) {
            case 'Water':
                return '#51768f'
            case 'Roads':
                return '#737578'
            default:
                return '#fff'
        }
    }
}

let getNodeByName = (name, nodes) => {
    let returnedResult;
    for (let node of Object.values(nodes)) {
        if (node?.name === name) {
            returnedResult = node
        }
    }

    return returnedResult;
}

let getSelectedProperties = (selectedProperties, child) => {
    let isSelected = false;

    for (let property of selectedProperties) {

        if (property?.name instanceof Array && property?.name?.length) {
            for (let name of property.name) {
                if (name === child?.name) {
                    isSelected = true;
                    break;
                }
            }
        } 
        
        if (property?.name === child?.name) {
            isSelected = true;
            break;
        }
    }

    return isSelected
}