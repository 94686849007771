import axios from 'axios'
import React, { Component, useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import { getConnectedUserId, getDate, scrollTo } from '../../util/util'
import parse, { domToReact } from 'html-react-parser';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import AddResponse from './AddResponse';
import Modal from '../Common/Modal';
import LinkRedirectionModal from '../Common/LinkRedirectionModal';
import { authHeader, getCurrentUser, isAdmin, isTokenValid } from '../../util/service';

export default class Topic extends Component {
    constructor(props) {
        super(props)

        this.state = {
            topic: undefined,
            isLoading: false,
            answerBeingAdded: null,
            itemsPerPage: 5, // number of responses per page
            isLoadingResponses: false,
        }
    }

    componentDidMount = () => {
        this.fetchTopic(this.props.slug)
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.slug !== prevProps.slug) {
            this.fetchTopic(this.props.slug)
        }
    }

    fetchTopic = (slug) => {
        if (!slug || slug === '') return;

        this.toggleIsLoading(true)
        axios.get(`https://versemail.azurewebsites.net/forum/topic/${slug}`, { headers: authHeader() }).then(response => {
            if (response?.data) {
                this.props.translateType(response.data?.type)
                this.setState({
                    topic: response.data,
                })
                this.fetchResponsesPaginate(response.data?._id)

                if (response.data?.isDeleted && !isAdmin()) {
                    setTimeout(() => {
                        window.open('/forum', '_self')
                    }, 2000)
                }
            }

            this.toggleIsLoading(false)
        }).catch((error) => {
            console.log(error.response)
            this.setState({ isLoading: false })
        });
    }

    // pagination skip should be added later, for now, fetches directly
    fetchResponsesPaginate = (topicId, skip = 0) => {
        if (!topicId) return;

        this.setState({ isLoadingResponses: true })
        axios.get(`https://versemail.azurewebsites.net/forum/answers/${topicId}/${skip}/${this.state.itemsPerPage}`, { headers: authHeader() }).then(response => {
            if (response?.data) {

                this.setState({
                    topic: {
                        ...this.state.topic,
                        answers: this.checkResponsesLikes(response.data?.answers)
                    },
                    total: response?.data?.total,
                    isLoadingResponses: false
                })
            }
        }).catch((error) => {
            console.log(error.response)
            this.setState({ isLoadingResponses: false })
        });
    }

    checkResponsesLikes = (answers) => {
        if (answers?.length) {
            answers = answers.map(answer => {
                if (answer?.likes?.length) {
                    let isLiked = false;

                    for (let like of answer.likes) {
                        if (like?.user === getCurrentUser()?.id) {
                            isLiked = true
                            break;
                        }
                    }

                    answer.isLiked = isLiked
                }

                return answer
            })
        }

        return answers
    }

    handleDeleteQuestion = (topicId, value) => {
        let result = value ? 'delete' : 'restore',
            deletedContent = "removed from our forum. Its content and responses won't be viewed by our users anymore.",
            restoredContent = "restored to our forum. Its content and responses will be viewed again by our users. ",
            content = value ? deletedContent : restoredContent;

        this.setState({
            modal: {
                title: `Are you sure you want to ${result} this?`,
                content: `This post will be ${content}`,
                action: this.deleteQuestion,
                closeModal: this.closeModal,
                topicId: topicId,
                value: value
            }
        })
    }

    handleDeleteResponse = (responseId, value) => {
        let result = value ? 'delete' : 'restore',
            deletedContent = "removed from our forum. This response won't be viewed by our users anymore.",
            restoredContent = "restored to our forum. This response will be viewed again by our users. ",
            content = value ? deletedContent : restoredContent;

        this.setState({
            modal: {
                title: `Are you sure you want to ${result} this?`,
                content: `This response will be ${content}`,
                action: this.deleteResponse,
                closeModal: this.closeModal,
                responseId: responseId,
                value: value
            }
        })
    }

    handlePinQuestion = (topicId, value) => {
        let result = value ? 'pin' : 'unpin',
            pinContent = "pinned and added to the search bar. All users will see it at first when making a search.",
            removePinContent = "unpinned from the search bar. Users won't be able to see it first when making a search.",
            content = value ? pinContent : removePinContent;

        this.setState({
            modal: {
                title: `Are you sure you want to ${result} this?`,
                content: `This post will be ${content}`,
                action: this.pinQuestion,
                closeModal: this.closeModal,
                topicId: topicId,
                value: value
            }
        })
    }

    handleBanUser = (topic) => {
        if (!topic?.user) return;

        let user = topic.user,
            value = !user?.isDeleted;

        let result = value ? 'ban' : 'unban',
            bannedContent = "banned. All his posts and answers will be removed.",
            restoredContent = "able to post and comment again. His deleted posts and anwers won't be restored.",
            content = value ? bannedContent : restoredContent;

        this.setState({
            modal: {
                title: `Are you sure you want to ${result} this user?`,
                content: `This user will be ${content}`,
                action: this.banUser,
                closeModal: this.closeModal,
                userId: topic.user?._id,
                value: value
            }
        })
    }

    handleLikeAnswer = (responseId) => {
        if (!responseId) return;
  
        axios.post(`https://versemail.azurewebsites.net/forum/answer/like/${responseId}`, {}, { headers: authHeader() }).then(response => {
            if (response?.data) {
                let like = response?.data,
                topic = this.state.topic;

                if (!topic?.answers?.length) return;

                topic.answers = topic.answers.map(answer => {
                    if (answer?._id === like?.answer) {
                        let exists = false,
                        isLiked = like?.user === getConnectedUserId() && !like?.isDeleted;

                        if (answer?.likes?.length)
                            answer.likes = answer.likes.map(oldLike => {
                                if (oldLike?._id === like?._id) {
                                    oldLike = like;
                                    exists = true
                                }

                                return oldLike
                            })

                        if(!exists || !answer?.likes?.length) {
                            answer.likes = [...answer?.likes || [], like];
                        }

                        // just in case
                        answer.likes = answer.likes.filter(oldLike => {
                            if (!oldLike?.isDeleted) return oldLike
                        })

                        answer.isLiked = isLiked
                    }

                    return answer;
                })

                this.setState({
                    topic: topic
                })
            }
        }).catch((error) => {
            
        });
    }

    closeModal = () => {
        this.setState({
            modal: undefined
        })
    }

    renderModal = () => {
        if (this.state.modal) {
            return <Modal {...this.state.modal} />
        }
    }

    deleteQuestion = () => {
        let topicId = this.state.modal?.topicId,
            value = this.state.modal?.value;

        if (!topicId) return;

        axios.delete(`https://versemail.azurewebsites.net/forum/question/${topicId}/${value}`, { headers: authHeader() }).then(response => {
            if (response?.data) {
                this.setState({
                    topic: {
                        ...this.state.topic,
                        isDeleted: response?.data?.isDeleted
                    },
                })

                this.closeModal()
                scrollTo(500)
            } else {
                this.closeModal()
            }
        }).catch((error) => {
            this.closeModal()
            console.log(error.response)
        });
    }

    deleteResponse = () => {
        let responseId = this.state.modal?.responseId,
            value = this.state.modal?.value;

        if (!responseId) return;

        axios.delete(`https://versemail.azurewebsites.net/forum/answer/${responseId}/${value}`, { headers: authHeader() }).then(response => {
            if (response?.data) {
                this.setState({
                    topic: {
                        ...this.state.topic,
                        answers: this.state.topic?.answers.filter((answer) => {
                            if (answer?._id !== response?.data?._id) {
                                return answer
                            }
                        })
                    },
                })
                this.closeModal()
            } else {
                this.closeModal()
            }
        }).catch((error) => {
            this.closeModal()
            console.log(error.response)
        });
    }

    pinQuestion = () => {
        let topicId = this.state.modal?.topicId,
            value = this.state.modal?.value;

        if (!topicId) return;

        axios.put(`https://versemail.azurewebsites.net/forum/question/pin/${topicId}/${value}`, {}, { headers: authHeader() }).then(response => {
            if (response?.data) {
                this.setState({
                    topic: {
                        ...this.state.topic,
                        isPinned: response?.data?.isPinned
                    },
                })
                this.closeModal()
                scrollTo(500)
            }
        }).catch((error) => {
            console.log(error.response)
            this.closeModal()
        });
    }

    banUser = () => {
        let userId = this.state.modal?.userId;

        if (!userId) return;

        axios.put(`https://versemail.azurewebsites.net/forum/user/ban/${userId}`, {}, { headers: authHeader() }).then(response => {
            if (response?.data) {
                this.setState({
                    topic: {
                        ...this.state.topic,
                        isDeleted: response?.data?.isDeleted ? response?.data?.isDeleted : this.state.topic?.isDeleted,
                        user: {
                            ...this.state.topic.user,
                            isDeleted: !this.state.topic.user?.isDeleted
                        }
                    },
                })
                this.closeModal()
                scrollTo(500)
            }
        }).catch((error) => {
            console.log(error.response)
            this.closeModal()
        });
    }

    toggleIsLoading = (value) => {
        this.setState({ isLoading: value })
    }

    getContentParsed = (content) => {
        if (!content) return;

        content = content.replace(/\(link=(.*?)\)(.*?)\/link\)|\(b\)(.*?)\/b\)|\(i\)(.*?)\/i\)/g, (match, url, text, boldText, italicText) => {
            if (url) {
                if (url.startsWith('www')) {
                    url = 'http://' + url;
                }
                return `<a href='${url}'>${text}</a>`;
            }
            if (boldText) {
                return `<b>${boldText}</b>`;
            }
            if (italicText) {
                return `<i>${italicText}</i>`;
            }
        });
        return (
            <div className='text'>
                {parse(content)}
            </div>
        )
    }

    renderTopic = (topic) => {
        return (
            <>
                <div className={'topic-body' + (this.state.topic?.isDeleted ? ' deleted' : '')}>
                    <div className='container'>
                        <div className='status closed' style={{ opacity: topic?.isOpen ? 0 : 1 }}>
                            <i class='bx bx-check-circle' />
                            <p>
                                Closed
                            </p>
                        </div>
                    </div>

                    {this.renderUserDetails(topic)}

                    <div className='container'>
                        <div className='question'>
                            <h1>
                                Q:
                            </h1>
                            <h4>
                                {topic?.title || topic?.question}
                            </h4>
                        </div>
                    </div>

                    <div className='content'>
                        <p>
                            {this.getContentParsed(topic?.content)}
                        </p>
                    </div>
                </div>
                <div className='topic-options'>
                    {
                        isAdmin() && 
                        <p onClick={() => this.handleBanUser(topic)}>{isAdmin() && (this.state.topic?.user?.isDeleted ? 'Unban user' : 'Ban user')}</p>
                    }
                    {
                        (isAdmin() || getCurrentUser()?.id === this.state.topic?.user?._id) &&
                        <p onClick={() => this.handleDeleteQuestion(topic?._id, !topic?.isDeleted)}>{(this.state.topic?.isDeleted ? 'Restore' : 'Delete')}</p>
                    }
                    {
                        !topic?.isDeleted && isAdmin() && 
                        <p onClick={() => this.handlePinQuestion(topic?._id, !topic?.isPinned)}>{(isAdmin()) && (topic?.isPinned ? 'Unpin question from search bar' : 'Pin question to search bar')} </p>
                    }
                </div>
            </>
        )
    }

    renderUserDetails = (topic) => {
        return (
            <div className='user-details'>
                <div className='picture' style={{ backgroundImage: `url(${topic?.user?.profilePicture || '/empty.jpg'})` }} />
                <div className='details'>
                    <h5>{topic?.user?.userName || (topic?.user?.firstName || 'Loading...') + ' ' + (topic?.user?.lastName || '')}</h5>
                    <p>{getDate(topic?.creationDate) || topic?.date}</p>
                </div>
            </div>
        )
    }

    renderLoadingTopic = () => {
        return (
            <div className='topic-body'>
                <div className='container'>
                    <div className='status closed' style={{ opacity: 0 }}>
                        <i class='bx bx-check-circle' />
                        <p>
                            Closed
                        </p>
                    </div>
                </div>


                <div className='user-details'>
                    <div className='picture' />
                    <div className='details'>
                        <h5 style={{ width: '20%' }}><Skeleton height={15} /></h5>
                        <p style={{ width: '25%' }}><Skeleton height={10} /></p>
                    </div>
                </div>

                <div className='container'>
                    <div className='question'>
                        <h1>
                            Q:
                        </h1>
                        <h4 style={{ width: '80%' }}>
                            <Skeleton height={40} />
                        </h4>
                    </div>
                </div>

                <div className='content'>
                    <p>
                        <Skeleton height={150} />
                    </p>
                </div>
            </div>
        )
    }

    handlePagination = (e) => {
        let skip = e.selected * this.state.itemsPerPage;
        this.fetchResponsesPaginate(this.state.topic?._id, skip)
        scrollTo(500)
    }

    handleLoadingResponsesDisplay = (topic) => {
        if (this.state.isLoadingResponses)
            return ('Loading...')
        else if (topic.answers?.length) {
            return topic.answers.map((answer) => {
                return this.renderTopicResponse(answer)
            })
        } else {
            return (
                <div className='no-results'>
                    No responses yet.
                </div>
            )
        }
    }

    renderResponses = (topic) => {
        if (topic?.answers)
            return (
                <>
                    <div className={'list-container topic responses' + (this.state.topic?.isDeleted ? ' deleted' : '')}>
                        <h4>Responses</h4>
                        {this.handleLoadingResponsesDisplay(topic)}
                    </div>

                    <div className='pagination-container'>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={this.handlePagination}
                            pageRangeDisplayed={2}
                            pageCount={this.state.total / this.state.itemsPerPage}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                        />
                    </div>
                </>
            )
    }

    renderTopicResponse = (topic, isBeingAdded) => {
        return (
            <>
                <div className={'topic-body response' + (isBeingAdded ? ' loading' : '')}>
                    {this.renderUserDetails(topic)}

                    <div className='content response'>
                        <div className={'check-container' + (topic?.isApproved ? ' approved' : '')} >
                            <i class='bx bx-check' onClick={() => this.handleApproveResponse(topic)} />
                        </div>
                        {this.getContentParsed(topic?.content)}
                    </div>

                    {this.renderResponseOptions(topic)}
                </div>
            </>
        )
    }

    renderResponseOptions = (topic) => {
        return (
            <div className='topic-options'>
                {
                    (isAdmin() || getCurrentUser()?.id === this.state.topic?.user?._id) &&
                    <p onClick={() => this.handleDeleteResponse(topic?._id, !topic?.isDeleted)}>{this.state.topic?.isDeleted ? 'Restore' : 'Delete'}</p>
                }

                {
                    isTokenValid() &&
                    <div className={'like-btn' + (topic?.isLiked ? ' liked' : '')} onClick={() => this.handleLikeAnswer(topic?._id)}>
                        <i class={topic?.isLiked ? 'bx bxs-heart' : 'bx bx-heart'} />
                        <p>
                            {topic?.likes?.length || 'Like'}
                        </p>
                    </div>
                }
            </div>
        )
    }

    isResponseLikedByUser = (response) => {

    }

    handleApproveResponse = (answer) => {
        if (!answer?._id || !this.state.topic) return;
        if (this.state.topic?.isDeleted) return;

        axios.put(`https://versemail.azurewebsites.net/forum/answer/${answer?._id}/${!answer?.isApproved}`, { headers: authHeader() }).then(response => {
            if (response?.data) {
                this.setState({
                    topic: {
                        ...this.state.topic,
                        answers: this.state.topic?.answers.map((response) => {
                            if (response?._id === answer?._id) {
                                response.isApproved = !answer?.isApproved;
                            }

                            return response
                        })
                    }
                })
            }
        }).catch((error) => {
            console.log(error.response)
            this.setState({ isLoading: false })
        });
    }

    renderAnswerBeingAdded = () => {
        if (this.state.answerBeingAdded) {
            return this.renderTopicResponse(this.state.answerBeingAdded, true)
        }
    }

    setAnswerBeingAdded = (answer) => {
        this.setState({
            answerBeingAdded: answer
        })
    }

    addAnswerToList = (answer) => {
        this.setState({
            topic: {
                ...this.state.topic,
                answers: [...this.state.topic.answers, answer]
            }
        })
    }

    renderTopicStatus = () => {
        if (this.state.topic?.isDeleted) {
            return (
                <div className='status-bar deleted'>
                    <p>
                        This topic was <b>removed</b> from our forum by the author or the admin.
                    </p>
                </div>
            )
        }
    }

    renderUserStatus = () => {
        if (this.state.topic?.user?.isDeleted) {
            return (
                <div className='status-bar deleted'>
                    <p>
                        This user was <b>banned</b> from the forum. All his posts are deleted.
                    </p>
                </div>
            )
        }
    }

    renderTopicPinned = () => {
        if (this.state.topic?.isPinned) {
            return (
                <div className='status-bar pinned'>
                    <p>
                        This topic was <b>pinned</b> to the search bar.
                    </p>
                </div>
            )
        }
    }

    switchRenderTopic = (topic) => {
        if (this.state.isLoading)
            return this.renderLoadingTopic()
        return this.renderTopic(topic)
    }

    render() {
        let topic = this.state.topic
        if (!topic && !this.state.isLoading) return null;

        return (
            <>
                <div className='list-container topic'>
                    {this.renderUserStatus()}
                    {this.renderTopicStatus()}
                    {this.renderTopicPinned()}

                    <h4>{this.props.forumTitle || 'Loading...'}</h4>
                    {this.switchRenderTopic(topic)}
                </div>
                {this.renderResponses(topic)}
                {this.renderAnswerBeingAdded()}
                {
                    !this.state.topic?.isDeleted && this.state.topic?.isOpen &&
                    <AddResponse topic={topic} setAnswerBeingAdded={this.setAnswerBeingAdded} addAnswerToList={this.addAnswerToList} />
                }
                {this.renderModal()}
                <LinkRedirectionModal />
            </>
        )
    }
}