import React, { Component, Suspense } from 'react'
import { Canvas, useThree } from '@react-three/fiber'
import { BakeShadows, CameraControls, Environment, Html, Lightformer, MeshReflectorMaterial, OrbitControls, ScrollControls, Sky, SoftShadows, Sphere, SpotLight, SpotLightShadow, Stats, TransformControls } from '@react-three/drei'
import * as THREE from 'three';
import { Effects } from './Common/Effects';
import Movement from './Common/Movements';
import TWEEN from '@tweenjs/tween.js';
import { LoadModel } from './Common/LoadModel';
import FocusCamera from './Common/FocusCamera';
import CameraRig from './Common/CameraRig';
import Annotation from './Common/Annotation';
import TutorialCamera from './Common/TutorialCamera';
import TutorialCameraScroll from './Common/TutorialCameraScroll';
import { Skybox } from './Common/Skybox';
import { LoadModelMemo } from './Common/LoadModelMemo';
import { LoadModelFallback } from './Common/LoadModelFallback';

export default class Map3D extends Component {
    constructor(props) {
        super(props)

        this.state = {
            initialCamera:/* {
                "x": -11.460884312290753,
                "y": 35.7232838645493,
                "z": 145.30806203786895,
                "isEuler": true,
                "_x": -0.33368662434705915,
                "_y": -0.15323163700907652,
                "_z": 0,
                "_order": "YXZ"
            },*/
            {
                "x": -72.87872084080688,
                "y": 135.7614830437989,
                "z": -239.15441788003727,
                "isEuler": true,
                "_x": -2.70344608539836,
                "_y": -0.15287928700959577,
                "_z": -3.070365296037235,
                "_order": "XYZ"
            },
            effects: false,
            isStarted: false,
            bloomValue: 0.5,
            propertiesData: [],
            pointLight: {
                /* x: -110,
                 y: 240,
                 z: 10*/
                x: 80,
                y: 70,
                z: 150
            },
            directionalLight: {
                x: -219.20172094931806,
                y: 145.986515919761,
                z: 91.88594753281384,
            },
            dpr: 1,
            isNightMode: true,
            isVarience: true,
            optimisation: {
                ground: false,
                shadows: 2048 * 4,
            },
            fov: 20
        }

        this.mixers = []; // for animations
        this.clock = new THREE.Clock();
        this.myfps = 60;
        this.updateTime = 1000 / this.myfps;
        this.frames = 0;
        this.startTime = Date.now();
        this.prevTime = this.startTime;
        this.fpsArray = []

        this.initFPS()
    }

    componentDidMount = () => {
        this.animate();

        // for testing
        document.addEventListener('keydown', this.keydownHandler, false);
        window.addEventListener('resize', this.windowResized, false);

        if (this.props.isTutorial) {
            this.setState({
                initialCamera: {
                    "x": -219.20172094931806,
                    "y": 145.986515919761,
                    "z": 91.88594753281384,
                    "isEuler": true,
                    "_x": -1.8643011027221361,
                    "_y": -0.9750157952448082,
                    "_z": -1.920898311680398,
                    "_order": "XYZ"
                },

                tutorialSteps: this.props.tutorialSteps.map(step => { return step })
            });

        } 

        if (window.innerWidth < 480) {
            this.setState({
                isMobile: true,
                dpr: .9,
                optimisation: {
                    shadows: 100
                },
                fov: 40
            })
        }

        if (this.props.isTutorial) {
            this.setState({isNightMode: true})
        }
    }

    windowResized = () => {
        if (window.innerWidth <= 768 && !this.state.isMobile) {
            this.setState({
                isMobile: true,
                fov: 40,
            });

            window.location.reload();
        } else if (window.innerWidth > 768 && this.state.isMobile) {
            this.setState({
                isMobile: false, 
                fov: 20
            })
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.selectedNavigationItem?.name !== this.props.selectedNavigationItem?.name) {
            this.getNavigationItemAndFocus(this.props.selectedNavigationItem)
        }

        if (prevProps.navigationPos !== this.props.navigationPos) {
            this.setNavigationPos(this.props.navigationPos)
        }
    }

    initFPS = () => {
        setInterval(() => {
            const sum = this.fpsArray.reduce((a, b) => a + b, 0);
            const avg = (sum / this.fpsArray.length) || 0;
            if (this.fpsArray?.length > 1)
                this.updateDPR(avg)

            this.fpsArray = []
        }, 4000);
    }

    updateDPR = (avg) => {
       // if (this.state.isMobile) return;

        if (avg >= 50 && this.state.dpr !== 1.5) {
            this.setState({
                dpr: 1.5,
                optimisation: {
                    shadows: 2048 * 4,
                },
            })
        } else if (avg > 15 && (!this.state.dpr?.length || this.state.optimisation?.shadows === 200)) {
            this.setState({
                dpr: [1, 1.5],
            })

        } else if (avg <= 15 && this.state.dpr !== .9) {
            this.setState({
                dpr: .9
            })
        }
    }

    getNavigationItemAndFocus = (item) => {
        let propertiesData = this.state.propertiesData;

        if (!propertiesData?.length) return

        for (let propertyData of propertiesData) {
            if (propertyData?.property?.name === item?.name || (item?.name instanceof Array && item?.name[0] === item?.name)) {

                this.focusElement(propertyData?.property, propertyData?.target)
                break;
            }
        }
    }

    keydownHandler = (event) => {
        var key;
        if (window.event) {
            key = window.event.keyCode;
        }
        else {
            key = event.keyCode;
        }

        switch (key) {
            case 88:
                this.translatePosition('x', event.ctrlKey ? -10 : 10)
                break;
            case 89:
                this.translatePosition('y', event.ctrlKey ? -10 : 10)
                break;
            case 90:
                this.translatePosition('z', event.ctrlKey ? -10 : 10)
                break;
            case 9:
                event.preventDefault();
                break;
            case 78:
                this.setState({ isNightMode: !this.state.isNightMode })
                break;
            case 86:
                this.setState({isVarience: !this.state.isVarience})
                break;
            default:
                break;
        }
    }

    translatePosition = (name, value) => {
        let position = {
            ...this.state.directionalLight,
            [name]: this.state.directionalLight?.[name] + value
        }
        this.setState({
            directionalLight: {
                ...this.state.directionalLight,
                ...position
            }
        })
    }

    animate = () => {
        window.requestAnimationFrame(this.animate);
        TWEEN.update();

        var delta = this.clock.getDelta();
        if (this.mixers?.length) {
            this.mixers.map(mixer => {
                mixer.update(delta);
            });
        }

        // for the FPS opt
        var time = Date.now();
        this.frames++;

        if (time > this.prevTime + 1000) {
            this.fps = Math.round((this.frames * 1000) / (time - this.prevTime));

            this.prevTime = time;
            this.frames = 0;
            this.fpsArray.push(this.fps)
        }
    }

    changeBloomValue = (value) => {
        this.setState({
            bloomValue: value
        })
    }

    setLights = () => {
        return (
            <>
                {this.renderAmbientLight()}
                {
                    this.handleLightSwitch(this.state.optimisation?.shadows)
                }
            </>
        )
    }

    handleLightSwitch = (shadow) => {
        let { x, y, z } = this.state.pointLight,
            directionalLight = this.state.directionalLight

        return (
            <>
                <pointLight  position={[x, y, z]} intensity={.25} // highlight-line
                    shadow-mapSize-height={this.state.optimisation.shadows || 2048}
                    shadow-mapSize-width={this.state.optimisation.shadows || 2048}
                    shadow-radius={5} shadow-filter={THREE.NearestFilter}  shadow-blur={200} color={0xa4b8ff} />
                <pointLight castShadow intensity={1} position={[directionalLight?.x, directionalLight?.y, directionalLight?.z]} 
                    shadow-mapSize-height={this.state.optimisation.shadows || 2048}
                    shadow-mapSize-width={this.state.optimisation.shadows || 2048}
                    shadow-radius={5} shadow-blur={200} shadow-filter={THREE.NearestFilter} color={0xfff4df} />
            </>
        )
    }

    renderEnvironment = () => {
        return (
            <Environment resolution={100}>
                <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, -9]} scale={[10, 1, 1]} />
                <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, -6]} scale={[10, 1, 1]} />
                <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, -3]} scale={[10, 1, 1]} />
                <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 0]} scale={[10, 1, 1]} />
                <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 3]} scale={[10, 1, 1]} />
                <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 6]} scale={[10, 1, 1]} />
                <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 9]} scale={[10, 1, 1]} />
                <Lightformer intensity={2} rotation-y={Math.PI / 2} position={[-50, 2, 0]} scale={[100, 2, 1]} />
                <Lightformer intensity={2} rotation-y={-Math.PI / 2} position={[50, 2, 0]} scale={[100, 2, 1]} />
            </Environment>
        )
    }

    renderAmbientLight = () => {
        return <ambientLight intensity={this.state.isNightMode ? .5 : .25} color={this.state.isNightMode ? "#51768f" : '#fff'} />
    }

    renderFog = () => {
        return <fog attach="fog" args={['#4d5e73', this.state.zoom < 450 ? 300 : 700, this.state.zoom < 450 ? 600 : 1000]} />
    }

    renderGround = () => {
        return
    }

    focusElement = (property, target) => {
        this.props.setSelectedProperty(property);
        if (target) {
            target.isCameraBehind = property?.isCameraBehind
            target.parentProperty = property?.parent
            target.annotationPosition = property?.position
        }
        this.setTarget(target)
    }

    setTarget = (target) => {
        if (this.state.target !== target && (this.state.target?.parentProperty !== target?.parentProperty || !target?.parentProperty)) 
            this.setState({
                target: target
            })
    }

    setPropertiesData = (data) => {
        this.setState({
            propertiesData: data
        })
    }

    renderAnnotations = () => {
        if (!this.state.propertiesData?.length || this.props.isTutorial) return null

        return this.state.propertiesData.map((propertyData, index) => {
            return <Annotation
                target={propertyData?.target}
                property={propertyData?.property}
                openCloseAnnotations={this.openCloseAnnotations}
                index={index}
                isActive={propertyData?.isActive}
                focusElement={this.focusElement}
                customPosition={propertyData?.property?.position}
            />
        })
    }

    openCloseAnnotations = (index) => {
        if (!this.state.propertiesData) return null;

        let propertiesData = this.state.propertiesData.map((propertyData, i) => {
            propertyData.isActive = i === index;

            return propertyData
        })

        this.setState({
            propertiesData: propertiesData
        })
    }

    setNavigationPos = (navigationPos) => {
        this.setState({
            navigationPos: navigationPos
        })
    }

    addMixer = (mixer) => {
        this.mixers.push(mixer);
    }

    setCameraZoom = (zoom) => {
        this.setState({
            zoom: zoom
        })
    }
    
    renderScene = () => {
        return (
            <>
                <color attach="background" args={['#4d5e73']} />
                {this.setLights()}
                {
                    this.props.isTutorial ?
                        <ScrollControls pages={this.props.tutorialSteps?.length / (3.5 / (this.state.isMobile ? 3 : 1))}>
                            <TutorialCameraScroll
                                tutorialSteps={this.props.tutorialSteps}
                                initialCamera={this.state.initialCamera}
                                setSelectedTutorialStep={this.props.setSelectedTutorialStep}
                                focusElement={this.focusElement}
                                setPropertiesData={this.setPropertiesData}
                                targetPositionProps={this.props.targetPosition}
                                fov={this.state.fov}
                            />
                        </ScrollControls>
                        :
                        <CameraRig
                            target={this.state.target}
                            navigationPos={this.state.navigationPos}
                            setNavigationPos={this.setNavigationPos}
                            setTarget={this.setTarget}
                            verseWorldObject={this.state.verseWorldObject}
                            isMobile={this.state.isMobile}
                            setCameraMoving={this.props.setCameraMoving}
                            setCameraZoom={this.setCameraZoom}
                            fov={this.state.fov}
                        />
                }
                {
                    false &&
                    <Stats showPanel={0} className="stats" />
                }


                {
                    this.state.isMobile ? 
                    <LoadModelFallback
                        path={"https://slidzo.s3.eu-central-1.amazonaws.com/verse/verseworldmap-v2.glb" || 'https://slidzo.s3.eu-central-1.amazonaws.com/verse/verseworldOfficial2.glb' || 'https://slidzo.s3.eu-central-1.amazonaws.com/verse/verseworld4.glb'} // https://slidzo.s3.eu-central-1.amazonaws.com/verse/Verseworld.glb
                        scale={[.08078, .08078, .08078]}
                        position={[10, 0, 40]}
                        properties={this.props.properties}
                        selectedProperty={this.props.selectedProperty}
                        focusElement={this.focusElement}
                        selectedTypes={this.props.selectedTypes}
                        setPropertiesData={this.setPropertiesData}
                        tutorialSteps={this.props.tutorialSteps}
                        setTutorialSteps={this.props.setTutorialSteps}
                        isTutorial={this.props.isTutorial}
                        addMixer={this.addMixer}
                        setIsLoaded={this.props.setIsModelLoaded}
                        setSelectedTypes={this.props.setSelectedTypes}
                        isMobile={this.state.isMobile}
                    /> : 
                    <LoadModel
                        path={"https://slidzo.s3.eu-central-1.amazonaws.com/verse/verseworldmap-v2.glb" || 'https://slidzo.s3.eu-central-1.amazonaws.com/verse/verseworldOfficial2.glb' || 'https://slidzo.s3.eu-central-1.amazonaws.com/verse/verseworld4.glb'} // https://slidzo.s3.eu-central-1.amazonaws.com/verse/Verseworld.glb
                        scale={[.08078, .08078, .08078]}
                        position={[10, 0, 40]}
                        properties={this.props.properties}
                        selectedProperty={this.props.selectedProperty}
                        focusElement={this.focusElement}
                        selectedTypes={this.props.selectedTypes}
                        setPropertiesData={this.setPropertiesData}
                        tutorialSteps={this.props.tutorialSteps}
                        setTutorialSteps={this.props.setTutorialSteps}
                        isTutorial={this.props.isTutorial}
                        addMixer={this.addMixer}
                        setIsLoaded={this.props.setIsModelLoaded}
                        setSelectedTypes={this.props.setSelectedTypes}
                        isMobile={this.state.isMobile}
                    />
                }
                
                {this.renderEnvironment()}

                {this.renderFog()}
                {this.renderGround()}
                {
                    this.state.target && false &&
                    <FocusCamera
                        target={this.state.target}
                        setTarget={this.setTarget}
                    />
                }
                {this.renderAnnotations()}
            </>
        )
    }

    render() {
        return (
            <>
                <div className='map-3d'>
                    <Canvas
                        shadows={this.state.optimisation?.shadows !== 1 ? this.state.isVarience ? 'variance' : 'soft' : false}
                        gl={{ logarithmicDepthBuffer: true, antialias: true, alpha: true }}
                        dpr={this.state.dpr}
                        camera={{ position: [this.state.initialCamera?.x, this.state.initialCamera?.y, this.state.initialCamera?.z], rotation: [this.state.initialCamera?._x, this.state.initialCamera?._y, this.state.initialCamera?._z], fov: this.state.fov || 20, far: 2000 }}
                        performance={{ min: .75 }}
                        style={{ width: '100%', height: '100%' }}
                        frustumCulling={true}
                    >
                        {<BakeShadows />}
                        {this.renderScene()}
                    </Canvas>
                </div>
            </>
        )
    }
}