import React, { Component } from 'react'

export default class Newsletter extends Component {
    render() {
        return (
            <div className='newsletter-card'>
                <h3>
                    Get Latest Updates
                </h3>
                <input type='text' className='option' placeholder='Your email address'/>
                <div className='option btn'>
                    Subscribe
                </div>
            </div>
        )
    }
}
