import React, { Component } from 'react'
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';

export default class ImageViewModal extends Component {
    constructor(props) {
        super(props)

        this.modalRef = React.createRef();
    }

    componentDidMount = () => {
        window.addEventListener('mousedown', this.pageClick, false);
        this.disableScroll()
    }

    componentWillUnmount = () => {
        clearAllBodyScrollLocks();
    }

    disableScroll = () => {
        let body = document.querySelector('#targetElementId');
        disableBodyScroll(body);
    }

    pageClick = (e) => {
        if (this.modalRef && this.modalRef.current && !this.modalRef.current.contains(e.target)) {
            this.closeModal()
        }
    }

    closeModal = () => {
        this.props.closeModal && this.props.closeModal(false)
    }

    render() {
        const { isBanner, image } = this.props;

        return (
            <div className='modal-overlay'>
                <div className='modal-container gallery' style={{padding: 0, height: 'unset', width: isBanner ? '50vw' : '30vw'}} ref={this.modalRef}>
                    <img src={image} style={{width: "100%"}} />
                </div>
            </div>
        )
    }
}
