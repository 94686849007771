import React, { Component } from 'react'
import { Outlet, Link } from "react-router-dom";
import Map3D from '../components/Map3D';
import MapUI from '../components/MapUI';
import { useState } from "react";
import tutorialStepsData from '../util/TutorialSteps';

export default function MapContainer() {
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [selectedNavigationItem, setselectedNavigationItem] = useState([]);
    const [properties, setProperties] = useState([
        {
            _id: 1,
            image: 'https://waldameer.com/wp-content/uploads/2019/03/img_ferris-wheel-3.jpg',
            tokenID: 1786,
            salePrice: 0.270,
            lastPrice: 0.002,
            type: 'SEMI_COLLECTIVE',
            description: `Lorem ipsum dolor sit amet consectetur. 
            Amet leo tellus dui nec neque`,
            position: [0, 0, 0],
            scale: [1, 1, 1],
            name: 'ParkWheel',
            title: 'Amusement Park (Wheel)'
        },
        {
            _id: 2,
            image: 'https://www.verizon.com/about/sites/default/files/2022-05/stadium-1230x690.jpg',
            tokenID: 2786,
            salePrice: 0.400,
            lastPrice: 0.200,
            type: 'SEMI_COLLECTIVE',
            description: `In the Stadium, Verse users can participate in
            virtual events and invite their friends to be a
            part of event shows.`,
            position: [-1, .25, -1],
            scale: [1, 1.5, 1],
            name: 'Stadium_01',
            title: 'Stadium'
        },
        {
            _id: 3,
            image: 'https://ychef.files.bbci.co.uk/976x549/p0202jwh.jpg',
            tokenID: 1506,
            salePrice: 0.900,
            lastPrice: 0.200,
            type: 'SKYSCRAPER',
            description: `The place where users can showcase and
            exhibit their NFTs, or claim for sales in the
            marketplace.`,
            position: [-2, .5, -1],
            scale: [1, 2, 1],
            name: 'TradingCenter',
            title: 'Trading Center'
        },
        {
            _id: 4,
            image: 'https://mediakwest.com/wp-content/uploads/2019/07/1_Beaugrenelle.ONYX-c-Fr%C3%A9d%C3%A9ric-Berthet.HD_.006.jpg',
            tokenID: 16586,
            salePrice: 0.5870,
            lastPrice: 0.2,
            type: 'SEMI_COLLECTIVE',
            description: `The perfect place where you can watch your
            favorite movies solo, in public, or with your
            friends in VIP suits.`,
            position: [2, 1, 1],
            scale: [1, 3, 1],
            name: 'Cinema',
            title: 'City Cinema'
        },
        {
            _id: 5,
            image: 'https://www.rd.com/wp-content/uploads/2017/10/This-Is-the-Real-Difference-Between-Streets-Roads-and-Avenues_394392439-TTstudio.jpg',
            tokenID: 89786,
            salePrice: 0.8870,
            lastPrice: 0.002,
            type: 'TRANSPORTATION',
            description: `Lorem ipsum dolor sit amet consectetur. 
            Amet leo tellus dui nec neque`,
            position: [3, 0, 1],
            scale: [1, 1, 1],
            name: 'Roads',
            title: 'Road'
        },

        {
            _id: 6,
            image: 'https://images.unsplash.com/photo-1429041966141-44d228a42775?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8YnJpZGdlfGVufDB8fDB8fHww&w=1000&q=80',
            tokenID: 89786,
            salePrice: 0.8870,
            lastPrice: 0.002,
            type: 'TRANSPORTATION',
            description: `Lorem ipsum dolor sit amet consectetur. 
            Amet leo tellus dui nec neque`,
            position: [3, 0, 1],
            scale: [1, 1, 1],
            name: 'CatWalk',
            title: 'Cat walk'
        },
        {
            _id: 6,
            image: 'https://www.telepheriquedenamur.be/wp-content/uploads/2021/04/cabine-telepherique-namur-2.jpg',
            tokenID: 89786,
            salePrice: 0.8870,
            lastPrice: 0.002,
            type: 'TRANSPORTATION',
            description: `Lorem ipsum dolor sit amet consectetur. 
            Amet leo tellus dui nec neque`,
            position: [3, 0, 1],
            scale: [1, 1, 1],
            name: 'CableMachine_1',
            title: 'Cable Machine 1'
        },
        {
            _id: 6,
            image: 'https://www.telepheriquedenamur.be/wp-content/uploads/2021/04/cabine-telepherique-namur-2.jpg',
            tokenID: 89786,
            salePrice: 0.8870,
            lastPrice: 0.002,
            type: 'TRANSPORTATION',
            description: `Lorem ipsum dolor sit amet consectetur. 
            Amet leo tellus dui nec neque`,
            position: [3, 0, 1],
            scale: [1, 1, 1],
            name: 'CableMachine_2',
            title: 'Cable Machine 2'
        },
        {
            _id: 6,
            image: 'https://i.pinimg.com/originals/68/c9/0a/68c90af78e7beba29eec0ed5aa9e120b.jpg',
            tokenID: 89786,
            salePrice: 0.8870,
            lastPrice: 0.002,
            type: 'SKYSCRAPER',
            description: `Lorem ipsum dolor sit amet consectetur. 
            Amet leo tellus dui nec neque`,
            position: [3, 0, 1],
            scale: [1, 1, 1],
            name: 'CityCenter',
            title: 'City Hub'
        },
        {
            _id: 6,
            image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4b/Griffith_observatory_2006.jpg/1200px-Griffith_observatory_2006.jpg',
            tokenID: 89786,
            salePrice: 0.8870,
            lastPrice: 0.002,
            type: 'NFT',
            description: `Lorem ipsum dolor sit amet consectetur. 
            Amet leo tellus dui nec neque`,
            position: [3, 0, 1],
            scale: [1, 1, 1],
            name: 'GeoSphere_01',
            title: 'NFT square'
        },
    ])

    const [tutorialSteps, setTutorialSteps] = useState(tutorialStepsData) 

    const [selectedTutorialStep, setSelectedTutorialStep] = useState(null)
    const [isModelLoaded, setIsModelLoaded] = useState(false);
    const [targetPosition, setTargetPosition] = useState();

    const buyProperty = (id) => {
        if (!properties?.length) return

        let results = properties.map((property, index) => {
            if (property?._id === id) {
                property.owner = '649226fea5f22c6f9ee5b054' // temp
            }

            return property
        })

        let property = selectedProperty;
        property.owner = '649226fea5f22c6f9ee5b054' // temp
        
        setProperties(results)
        setSelectedProperty(property)
    }

    return (
        <>
            <div className='map-container'>
                <MapUI 
                    selectedProperty={selectedProperty} 
                    setSelectedProperty={setSelectedProperty} 
                    setSelectedTypes={setSelectedTypes} 
                    selectedTypes={selectedTypes}
                    buyProperty={buyProperty}
                    properties={properties}
                    selectedNavigationItem={selectedNavigationItem}
                    setselectedNavigationItem={setselectedNavigationItem}
                    isTutorial={true} 
                    tutorialSteps={tutorialSteps}
                    setSelectedTutorialStep={setSelectedTutorialStep}
                    selectedTutorialStep={selectedTutorialStep}
                    isModelLoaded={isModelLoaded}
                    setTargetPosition={setTargetPosition}
                />
                <Map3D 
                    setSelectedProperty={setSelectedProperty} 
                    selectedProperty={selectedProperty} 
                    selectedTypes={selectedTypes} 
                    properties={properties}
                    selectedNavigationItem={selectedNavigationItem}
                    isTutorial={true} 
                    tutorialSteps={tutorialSteps}
                    setTutorialSteps={setTutorialSteps}
                    setSelectedTutorialStep={setSelectedTutorialStep}
                    setIsModelLoaded={setIsModelLoaded}
                    targetPosition={targetPosition}
                />
            </div>
        </>
    )
}