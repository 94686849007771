import * as THREE from 'three'
import { CameraControls, OrbitControls } from '@react-three/drei'
import React, { useEffect, useRef, useState } from 'react'
import { useThree } from '@react-three/fiber'

export default function CameraRig(props) {
    const cameraControlsRef = useRef()
    const { DEG2RAD } = THREE.MathUtils
    let camera = useThree(state => state.camera)
    const {target, navigationPos, isMobile} = props;
    const [isEnabled, setEnabled] = useState(true)

    useEffect(() => {
        cameraControlsRef?.current.addEventListener('transitionstart', () => {
            props.setCameraMoving(true)
            props.setCameraZoom(camera.position.distanceTo( cameraControlsRef?.current.getTarget(new THREE.Vector3())))
        }, false);

        cameraControlsRef?.current.addEventListener('controlend', () => {
            props.setCameraMoving(false)
        }, false);

        cameraControlsRef?.current.addEventListener('rest', () => {
            props.setCameraMoving(false)
        }, false);
    }, [])

    useEffect(() => {
        document.addEventListener('keydown', keydownHandler, false);
        
        // for the boundaries of the camera to the world
        var box = new THREE.Box3();
        box.max = new THREE.Vector3 (132.7, 24.9, 179.8)
        box.min = new THREE.Vector3 (-132.7, 0.6, -94.04)
        cameraControlsRef?.current && cameraControlsRef?.current?.setBoundary(box)
    }, [camera])

    useEffect(() => {
        focusElement(target)
    }, [target])

    useEffect(() => {
        moveCamera(navigationPos)
    }, [navigationPos])

    useEffect(() => {
        camera.fov = props.fov || 20
    }, [props.fov])

    const focusElement = (target) => {
        if (target && !props.navigationPos) {
            let position = target?.annotationPosition || target?.getWorldPosition(new THREE.Vector3());

            let paddingTop = isMobile ? 0 : 10,
            paddingBottom = isMobile ? 20 : 5;

            let rotationX = target?.isCameraBehind ? -Math.PI / 2 : Math.PI / 2;

            if (target?.annotationPosition) {
                const boxSize = new THREE.Vector3(1, 1, 1);
                const boxPosition = new THREE.Vector3(target?.annotationPosition.x, target?.annotationPosition.y, target?.annotationPosition.z);
                target = new THREE.Box3().setFromCenterAndSize(boxPosition, boxSize);
            }

            cameraControlsRef?.current?.rotateTo( rotationX, Math.PI / 2, true );
	        cameraControlsRef?.current?.fitToBox( target, true, {paddingTop: paddingTop, paddingBottom: paddingBottom} );

            setTimeout(() => {
                props.setCameraMoving(false)
            }, 1000);
            return

            cameraControlsRef?.current?.moveTo(...[position?.x, position?.y, position?.z], true)
        }
    }

    const moveCamera = (navigation) => {
        if (navigation) {
            // cameraControlsRef.current.enabled = false

            let position = {
                x: navigation?.x + camera.position.x,
                y: camera.position.y,
                z: navigation?.z + camera.position.z
            }
            // let position = target?.getWorldPosition(new THREE.Vector3());
            
            props.setNavigationPos(undefined)
            props.setTarget(undefined)
            cameraControlsRef.current?.truck(navigation?.x, 0, true)
            navigation?.z && cameraControlsRef.current?.forward(navigation?.z, true)

            //cameraControlsRef?.current?.moveTo(...[position?.x, position?.y, position?.z], true)
        }
    }

    const getCameraControlsProp = () => {
        return {
            maxPolarAngle: Math.PI / 2.2,
            minDistance: 70,  
            maxDistance: isMobile ? 700 : 380,
            azimuthRotateSpeed: isMobile ? .7 : 1,
            polarRotateSpeed: isMobile ? .7 : 1
        }
    }

    const keydownHandler = (event) => {
        var key;
        if (window.event) {
            key = window.event.keyCode;
        }
        else {
            key = event.keyCode;
        }

        switch (key) {
            case 39:
                // console.log({ ...camera.position, ...camera.rotation })
                break;
            default:
                break;
        }
    }

    return (
        <CameraControls ref={cameraControlsRef} {...getCameraControlsProp()} enabled={isEnabled}/>
    )
}