import React, { Component } from 'react'
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios'
import forumtypes from '../../util/ForumTypes';
import ImageCompress from 'quill-image-compress';
import { scrollTo } from '../../util/util';
import ReCAPTCHA from "react-google-recaptcha";
import { authHeader, isTokenValid } from '../../util/service';
Quill.register('modules/imageCompress', ImageCompress);

export default class AddTopic extends Component {
    constructor(props) {
        super(props)

        this.state = {
            question: '',
            content: '',
            isListOpen: false,
            isError: false,
            isInfoMissing: false,
            isCatptchaSubmitted: false
        }

        this.modalRef = React.createRef();
    }

    componentDidMount = () => {
        // window.addEventListener('mousedown', this.pageClick, false);
        if (!isTokenValid()) {
             window.open('/start/login', '_self')
        }
    }

    isValid = (parameter) => {
        return parameter !== undefined && parameter !== null && parameter !== ''
    }

    addQuestion = () => {
        if (this.isValid(this.state.question) && this.isValid(this.state.content) && this.isValid(this.state.selectedType) && this.state.isCatptchaSubmitted) {
            this.addQuestionAction();
            this.setState({isInfoMissing: undefined})
        } else {
            this.setState({
                isInfoMissing: !this.isValid(this.state.question) ? 'title' : !this.isValid(this.state.content) ? 'question content' : !this.isValid(this.state.selectedType) ? 'question category' : !this.state.isCatptchaSubmitted ? 'recaptcha' : ''
            })
        }
    }

    cancelQuestion = () => {
        this.setState({
            question: '',
            content: '',
            selectedType: null,
            isError: false,
            isInfoMissing: undefined
        })
    }

    addQuestionAction = () => {
        let question = {
            question: this.state.question,
            content: this.state.content,
            type: this.state.selectedType?.type,
            forum: this.state.selectedType
        }
        
        this.declareError(false)
        axios.post(`https://versemail.azurewebsites.net/forum/question`, {question}, { headers: authHeader() }).then(response => {
            if (response?.data) {
                this.declareError(false)
                window.open('/forum/topic/' + response?.data?.slug, '_self')
            }

        }).catch(error => {
            this.declareError(true)
        });
    }

    declareError = (value) => {
        this.setState({isError: value})

        if (value)
            scrollTo(500)
    }

    pageClick = (e) => {
        if (this.modalRef?.current && !this.modalRef.current.contains(e.target) && this.state.isListOpen) {
            this.toggleList(false)
        }
    }

    renderTextEditor = (content) => {
        let modules = {
            toolbar: [
                ['bold', 'italic', 'underline'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['link', 'image', 'video'],
            ],
            imageCompress: {
                quality: .75,
                maxWidth: 1000,
                maxHeight: 1000,
                imageType: 'image/jpeg',
            }
        };

        return (
            <ReactQuill
                className='p'
                modules={modules}
                value={content}
                name='content'
                placeholder='Please provide complete details for your question in here...'
                onChange={(html) => this.handleContentChange(html)}
            />
        )
    }

    handleContentChange = (value) => {
        this.setState({
            content: value
        })
    }

    renderList = () => {
        if (this.state.isListOpen) {
            return (
                <div className='autocomplete-list forum-types' ref={this.modalRef}>
                    <div className='elements'>
                        <div className='title'>
                            Choose a topic
                        </div>
                        {this.renderElements(forumtypes)}
                    </div>
                </div>
            )
        }
    }

    renderElements = (forumTypes) => {
        return forumTypes.map((element) => {
            return (
                <div className='element' onClick={() => this.selectType(element)}>
                    <div className='icon'>
                        <i class={element?.icon} style={{ color: element?.color }} />
                    </div>
                    <div className='text-details'>
                        <h5>
                            {element?.title}
                        </h5>
                        <p>
                            {element?.text}
                        </p>
                    </div>
                </div>
            ) 
        })
    }

    renderSelectedType = (element) => {
        return (
            <div className='selected-type'>
                <div className='icon'>
                    <i class={element?.icon} style={{ color: element?.color }} />
                </div>
                <div className='text-details'>
                    <h5>
                        {element?.title}
                    </h5>
                </div>
            </div>
        )
    }
 
    renderTypeSelector = () => {
        return (
            <div className='section'>
                <div className='border' />
                <div className='input-container'>
                    <p>
                        Topic
                    </p>

                    <div className='selector-container' onClick={() => this.toggleList(!this.state.isListOpen)} ref={this.modalRef}>
                        <div>
                            {
                                this.state.selectedType ?
                                this.renderSelectedType(this.state.selectedType):
                                'Choose a topic'
                            }
                        </div>
                        <i class='bx bx-chevron-down' />

                        {this.renderList()}
                    </div>
                </div>
            </div>
        )
    }
    
    renderButtons = () => {
        let isDisabled = this.state.userResponse && this.state.userResponse !== '' && this.state.userResponse !== '<p><br></p>'
        return (
            <div className='section'>
                <div className='btns-container'>
                    <div className='btn cancel' onClick={this.cancelQuestion}>
                        Cancel
                    </div>

                    <div className={'btn confirm' + (isDisabled ? ' disabled' : '')} onClick={this.addQuestion}>
                        Add Question
                    </div>
                </div>
            </div>
        )
    }

    toggleList = (value) => {
        this.setState({
            isListOpen: value
        })
    }

    selectType = (type) => {
        this.setState({
            selectedType: type
        })
    }

    renderQuestionInput = (question) => {
        return (
            <div className='question-title'>
                <input type='text' className='input' placeholder='Type your question...' value={question} onChange={this.handelQuestionInput}/>
            </div>
        )
    }

    handelQuestionInput = (e) => {
        this.setState({
            question: e.target.value
        })
    }

    renderError = () => {
        if (this.state.isError) {
            return (
                <div className='status-bar deleted'>
                    <p>
                        There was an error adding your post, please retry later.
                    </p>
                </div>
            )
        }
    }

    renderInfoMissing = () => {
        if (this.state.isInfoMissing) {
            return (
                <div className='status-bar deleted missing'>
                    <p>
                        You need to fill the <b>{this.state.isInfoMissing}</b> field.
                    </p>
                </div>
            )
        }
    }

    renderRecaptcha = () => {
        return (
            <div className='recaptcha'>
                <ReCAPTCHA
                    sitekey="6LcYJj0pAAAAAJ9kMa8y04EZqM7yIBgYE1LPwbs1"
                    onChange={this.verifyCaptcha}
                    onExpired={() => this.setState({ isCatptchaSubmitted: false })}
                />
                {this.renderRequiredError(this.state.isCaptchaError, 'You need to check the captcha before submitting')}
            </div>
        )
    }

    verifyCaptcha = (token) => {
        this.setState({
            isCatptchaSubmitted: true
        })
    }

    renderRequiredError = (requiredCondition, message) => {
        if (requiredCondition)
            return (
                <div className='error'>
                    {message}
                </div>
            )
    }

    render() {
        return (
            <div className='add-topic'>
                {this.renderError()}
                {this.renderQuestionInput(this.state.question)}
                {this.renderTextEditor(this.state.content)}
                {this.renderTypeSelector()}
                {this.renderRecaptcha()}
                {this.renderButtons()}
                {this.renderInfoMissing()}
            </div>
        )
    }
}
