import React, { Component } from 'react'
import '../dashboard.scss'
import { authHeader, isAdmin } from '../util/service'
import Header from '../components/Dashboard/Header'
import Menu from '../components/Dashboard/Menu'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getDate } from '../util/util'
import axios from 'axios'

export default class DashboardContainer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            newsletters: [],
            isLoading: true
        }
    }

    componentDidMount = () => {
        document.body.classList.toggle('body-dashboard', true);
        this.getNewsletters()
    }

    getNewsletters = () => {
        axios.get(`https://versemail.azurewebsites.net/newsletter`, { headers: authHeader() }).then(response => {
            if (response?.data) {
                this.setState({
                    newsletters: response?.data,
                    isLoading: false,
                    notFound: false
                })
            }
        }).catch(error => {
            this.setState({
                isLoading: false,
                notFound: true
            })
        });
    }

    renderNewsletters = () => {
        if (this.state.isLoading) return 'Loading...'

        if (this.state.notFound ) return 'No data'

        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell align="right">Email</TableCell>
                            <TableCell align="right">Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.renderList(this.state.newsletters)}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    renderList = (users) => {
        if (users?.length) {
            return users.map((row, index) => (
                (row?.email) &&
                <TableRow
                    key={row._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row">
                        {index + 1}
                    </TableCell>
                    <TableCell align="right">
                        {row?.email}
                    </TableCell>
                    <TableCell align="right">{getDate(row?.creationDate)}</TableCell>
                </TableRow>
            ))
        } else return ('Loading...')
    }

    render() {
        if (isAdmin())
            return (
                <>
                    <Header isMenuOpen={true} toggleMenu={() => { }} />
                    <div className='dashboard-container'>
                        <Menu isMenuOpen={true} isUsersPage={true} />
                        <div className='dashboard'>
                            {this.renderNewsletters()}
                        </div>
                    </div>
                </>
            )
        else {
            return ('User not authorized')
        }
    }
}
