import axios from 'axios'
import React, { Component } from 'react'
import {authHeader} from '../../util/service'
import ConfirmationModal from './ConfirmationModal'
import { Link } from 'react-router-dom'

export default class PublishMenu extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isSaving: false,
            alreadySaved: false,
            history: [],
            confirmationData: null,
            isLoading: false
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.article !== this.props.article) {
        }

        if (this.state.alreadySaved) {
            this.checkIfAlreadySaved()
        }
    }

    checkIfAlreadySaved = () => {
        if (!this.state.history?.length) return

        let lastArticle = this.state.history[0];

        if (JSON.stringify(lastArticle) !== JSON.stringify(this.props.article)) {
            this.setState({
                alreadySaved: false
            })
        }
    }

    handlePublish = () => {
        if (this.state.isPublishing || this.props.isCreating)
            return

        this.setState({
            confirmationData: {
                message: `${this.props.article?.isPublished ? 'unpublish' : 'publish'} this article ?`,
                confirmationAction: this.publishArticle,
                cancelAction: this.cancelAction,
            }
        })
    }

    handleRevert = () => {
        this.setState({
            confirmationData: {
                message: 'revert this page ?',
                confirmationAction: this.revertPage,
                cancelAction: this.cancelAction,
            }
        })
    }

    cancelAction = () => {
        this.setState({
            confirmationData: null
        })
    }

    publishArticle = (article = this.props.article, isDisabled = this.state.isPublishing) => {
        if (!article || isDisabled) return;

        this.setState({
            isPublishing: true
        })

        axios.put(`https://versemail.azurewebsites.net/news/world/article/${this.props.slug || article?.slug}/publish`, { article: article }, { headers: authHeader() }).then(response => {
            if (response?.data) {
                this.setState({
                    isSaving: false,
                    alreadySaved: true,
                    isPublishing: false
                })
                this.props.setArticle(response?.data)
                this.successAlert(response?.data?.isPublished ? 'published' : 'unpublished')
                response?.data?.isPublished && window.open('/article/' + response?.data?.slug)
            }
        }).catch(error => {
            this.setState({
                alert: { message: 'Error with your request. It could be a server error or your session expired', class: 'alert missing' },
                isSaving: false,
                alreadySaved: false,
                isPublishing: false
            });

            if (error?.response?.status) {
                this.setState({
                    alert: { message: 'Article not found. Refresh page or access it from news page', class: 'alert missing' },
                });
            }
        })
    }

    revertPage = (isDisabled = !this.state.history?.length) => {
        if (isDisabled) return;

        let history = [...this.state.history];
        history.shift();

        this.setState({
            selectedArticle: history[0] || this.props.originalArticle,
            history: history
        }, () => {
            this.props.setArticle(this.state.selectedArticle)
        })
    }

    isUndefinedOrEmpty = (value) => {
        return !value || value === ''
    }

    handleCreateOrUpdate = () => {
        this.setState({
            alert: undefined,
            isSaving: false
        })

        if (this.isUndefinedOrEmpty(this.props.article?.title)) {
            return this.setState({
                alert: { message: 'Please fill the title of the article before saving', class: 'alert missing' }
            })
        }

        if (this.isUndefinedOrEmpty(this.props.article?.author?.firstName)) {
            return this.setState({
                alert: { message: 'Please fill the name of the author before saving', class: 'alert missing' }
            })
        }

        if (this.isUndefinedOrEmpty(this.props.article?.type)) {
            return this.setState({
                alert: { message: 'Please select type for article before saving', class: 'alert missing' }
            })
        }

        if (this.props.isCreating) {
            return this.createArticle()
        }

        this.updateArticle()
    }

    createArticle = () => {
        let article = this.props.article;

        return axios.post(`https://versemail.azurewebsites.net/news/world/article/`, { article: article }, { headers: authHeader() }).then(response => {
            if (response?.data) {
                this.setState({
                    isSaving: false,
                    alreadySaved: true,
                    history: [article, ...this.state.history]
                });

                this.props.setIsCreating();
                this.props.setArticle(response?.data)
                this.successAlert('created')

                window.open('/ctqbc/article/' + response?.data?.slug, '_self')
            }
        }).catch(error => {
            this.setState({
                alert: { message: 'Error with your request. It could be a server error or your session expired', class: 'alert missing' },
                isSaving: false,
                alreadySaved: false
            });
        })
    }

    updateArticle = () => {
        let article = this.props.article;

        axios.put(`https://versemail.azurewebsites.net/news/world/article/${this.props.slug || article?.slug}`, { article: article }, { headers: authHeader() }).then(response => {
            if (response?.data) {
                this.setState({
                    isSaving: false,
                    alreadySaved: true,
                    history: [article, ...this.state.history]
                })
                this.props.setArticle(response?.data)
                this.successAlert()
            }
        }).catch(error => {
            this.setState({
                alert: { message: 'Error with your request. It could be a server error or your session expired', class: 'alert missing' },
                isSaving: false,
                alreadySaved: false
            });

            if (error?.response?.status) {
                this.setState({
                    alert: { message: 'Article not found. Refresh page or access it from news page', class: 'alert missing' },
                });
            }
        })
    }

    successAlert = (value = 'updated') => {
        this.setState({
            alert: { message: 'Article successfully ' + value, class: 'pinned missing' },
        });

        setTimeout(() => {
            this.setState({
                alert: undefined
            });
        }, 5000)
    }

    saveArticle = (article, isDisabled) => {
        if (!article || isDisabled) return;

        this.setState({
            isSaving: true
        })

        this.handleCreateOrUpdate();
    }

    viewArticleLink = () => {
        if (this.props.article?.slug) {
            window.open(`/article/${this.props.article?.slug}`, '_blank')
        }
    }

    render() {
        return (
            <>
                <ConfirmationModal
                    confirmationData={this.state.confirmationData}
                />
                <div className='publish-menu' style={{ top: '13vh' }}>
                    
                    <div
                        onClick={this.viewArticleLink}
                        className={"button" + (!this.props.article?.slug ? ' disabled' : '')}
                        style={{ width: '50px', height: '38.5px', background: '#DFDFDF', color: '#393939', margin: 'auto', marginLeft: 0 }}
                    >
                        <div>
                            <i class='bx bx-link' style={{ margin: 0, fontSize: '18px' }} />
                        </div>
                    </div>

                    <div className={"button cancel" + (!this.state.history?.length ? ' disabled' : '')} onClick={() => this.revertPage()}>
                        <div>
                            {
                                this.state.isPublishing ?
                                    <i className='bx bx-loader-alt bx-spin' /> :
                                    <i className='bx bx-undo' />
                            }

                            <p>{this.state.isReverting ? 'CANCELING...' : 'CANCEL'}</p>
                        </div>
                    </div>

                    <div className={"button " + (this.state.isPublishing || this.props.isCreating ? 'disabled' : '')} onClick={() => this.handlePublish()}>
                        <div>
                            {
                                this.state.isPublishing ?
                                    <i className='bx bx-loader-alt bx-spin' /> :
                                    <i className='bx bx-cloud-upload' />
                            }

                            <p>{this.state.isPublishing ? 'PUBLISHING...' : this.props.article?.isPublished ? 'UNPUBLISH' : 'PUBLISH'}</p>
                        </div>
                    </div>

                    <div
                        className={"button " + (this.state.isSaving || this.state.alreadySaved ? 'disabled' : '')}
                        style={{ width: '50px', height: '38.5px', background: '#DFDFDF', color: '#393939' }}
                        onClick={() => this.saveArticle(this.props.article, this.state.isSaving || this.state.alreadySaved)}
                    >
                        <div>
                            <i class='bx bxs-save' style={{ margin: 0, fontSize: '18px' }} />
                        </div>
                    </div>
                </div>

                {
                    this.state.alert &&
                    <div className={'status-bar ' + this.state.alert?.class}>
                        {this.state.alert?.message}
                    </div>
                }

            </>
        )
    }
}
