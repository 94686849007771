import React, { useState } from 'react'
import axios from 'axios';

export default function ForgotPassword(props) {
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState(undefined);
    const [errorMessage, seterrorMessage] = useState('');

    const validateEmail = (email) => {
        const isValidEmail = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(
            email
        );
        return isValidEmail;
    };

    const submitEmail = (e) => {
        e.preventDefault();

        const isValidEmail = validateEmail(email);

        if (isValidEmail) {
            axios.post(`http://localhost:5000/playfab/profile/password/request`, { email }).then(response => {
                if (response?.data) {
                    // window.open('/start/reset/' + response?.data?.token, '_self')
                    setSuccess("An email was sent to this address. Please follow its instructions to reset your password. \n You will be redirected to the login page in 10 seconds.")
                    setTimeout(() => {
                        window.open('/start/login', '_self')
                    }, 10000)
                } else {
                    seterrorMessage('A user with that email does not exist.')
                }
            }).catch(error => {
                seterrorMessage('A user with that email does not exist.')
            });

        } else {
            seterrorMessage('Email provided not valid')
        }
    }
 
    return (
        <div className='login'>
            <h3>Forgot Password?</h3>
            <div className='text-explainer'>
                To help keep your account safe, The Verse company wants to make sure it’s really you trying to reset your password.
            </div>

            <div className='text-explainer'>
                {
                    success ? success :
                    <form className="form" noValidate onSubmit={submitEmail}>
                    <div className="input-container">
                        <label>Please enter your account registration email</label>
                        <input
                            type="email"
                            placeholder="your registration email"
                            name="email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            required
                        />
                        <span> {errorMessage}</span>
                    </div>

                    <div className={"login-btn-container"}>
                        <input
                            type="submit"
                            className={"login-btn"}
                            value={"Send Request"}
                        />
                    </div>
                </form>}
            </div>
        </div>
    )
}
