import { useState } from "react";
import Header from "./MapUI/Header";
import { scrollTo } from "../util/util";
import { useEffect } from "react";

export default function DownloadPage(props) {
    const [systemRequirements, setSystemRequirements] = useState([
        "Windows 10 64-bit version 1909 revision .1350 or higher, or versions 2004 and 20H2 revision .789 or higher.",
        "Quad-core Intel or AMD, 2.5 GHz or faster",
        "8 GB RAM",
        "DirectX 11 or 12 compatible graphics card",
    ]);

    const [instructions, setInstructions] = useState([
        {
            os: "windows",
            steps: [
                {
                    imageURL: "https://verseworldstorage.blob.core.windows.net/verseweb/VerseWorld/InstalltionSteps/1.png",
                    instruction: "Select your path destination, then hit next",
                },
                {
                    imageURL: "https://verseworldstorage.blob.core.windows.net/verseweb/VerseWorld/InstalltionSteps/2.png",
                    instruction: "Choose to create a desktop shortcut and click next",
                },
                {
                    imageURL: "https://verseworldstorage.blob.core.windows.net/verseweb/VerseWorld/InstalltionSteps/3.png",
                    instruction: "click to install",
                },
                {
                    imageURL: "https://verseworldstorage.blob.core.windows.net/verseweb/VerseWorld/InstalltionSteps/4.png",
                    instruction: "Your installation is in progress",
                },
                {
                    imageURL: "https://verseworldstorage.blob.core.windows.net/verseweb/VerseWorld/InstalltionSteps/5.png",
                    instruction:
                        "Make sure that the install the prerequisites is ticked and click finish",
                },
                {
                    imageURL: "https://verseworldstorage.blob.core.windows.net/verseweb/VerseWorld/InstalltionSteps/6.png",
                    instruction: "Install the UE4 prerequisites (x64) Important",
                },
            ],
        },
    ]);

    const [faq, setFaq] = useState([
        {
            question: "This is a question",
            answer:
                "This is just a dummy text that has been inserted as a placeholder for future content. While it may seem insignificant at first glance, the use of dummy text is a common practice in the design and publishing industry, as it allows designers and developers to visualize the layout and overall aesthetic of a project without being distracted by the actual content.",
        },
        {
            question: "This is a question",
            answer:
                "This is just a dummy text that has been inserted as a placeholder for future content. While it may seem insignificant at first glance, the use of dummy text is a common practice in the design and publishing industry, as it allows designers and developers to visualize the layout and overall aesthetic of a project without being distracted by the actual content.",
        },
        {
            question: "This is a question",
            answer:
                "This is just a dummy text that has been inserted as a placeholder for future content. While it may seem insignificant at first glance, the use of dummy text is a common practice in the design and publishing industry, as it allows designers and developers to visualize the layout and overall aesthetic of a project without being distracted by the actual content.",
        },
    ]);

    const [openItems, setOpenItems] = useState([]);

    useEffect(() => {
        scrollTo()
    }, [])

    const toggleItem = (index) => {
        if (openItems.includes(index)) {
            setOpenItems(openItems.filter((item) => item !== index));
        } else {
            setOpenItems([...openItems, index]);
        }
    };

    const renderDownloadButton = () => {
        return (
            <div className="download-button-container">
                <a href="https://verseworldstorage.blob.core.windows.net/verseweb/VerseWorld/appinstallation/VerseWorldInstaller.exe" className="download-button">
                    <div className="button"> Download</div>
                    <i className=" icon download"></i>
                </a>
                <div className="download-comment">
                    (1 GB, Multilingual zip file installer*)
                </div>
            </div>
        );
    };

    const renderStepRow = (step, i) => {
        return (
            <tr className="step-container" key={i}>
                <td
                    className="step-image"
                    style={{
                        backgroundImage: `url(${step.imageURL})`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                    }}
                ></td>

                <td className="step">
                    <div className="number">{i + 1}.</div>
                    <div className="instruction-container">
                        <div className="step-number">Step {i + 1}</div>
                        <div className="instruction">{step.instruction}</div>
                    </div>
                </td>
            </tr>
        );
    };

    const renderInstructions = (instructions) => {
        if (!instructions?.length) return;
        return instructions.map((element, index) => {
            return (
                <>
                    {" "}
                    <div className="for-os" key={index}>
                        for {element.os}
                    </div>
                    {element.steps.map((step, i) => {
                        return renderStepRow(step, i);
                    })}
                </>
            );
        });
    };

    const renderFAQ = (faq) => {
        return faq.map((element, index) => {
            return (
                <div className="accordion-item" key={index}>
                    <div className={"accordion-header" + (openItems.includes(index) ? ' show' : '')} onClick={() => toggleItem(index)}>
                        <div className="question">{element.question}</div>
                        <div className="toggle-icon" >
                            {openItems.includes(index) ? (
                                <i className="icon arrow-down"></i>
                            ) : (
                                <i className="icon arrow-up"></i>
                            )}
                        </div>
                    </div>
                    <div className={"accordion-body" + (openItems.includes(index) ? ' show' : '')}>{element.answer}</div>

                </div>
            );
        });
    };

    const renderSystemRequirements = (systemRequirements) => {
        return systemRequirements.map((requirement, index) => {
            return (
                <div className="requirements-item" key={index}>
                    <div className="bullet-point"></div>
                    <div className="requirement">{requirement}</div>
                </div>
            );
        });
    };

    return (
        <div className="download-page-container">
            <Header pageTitle={'DOWNLOAD'}/>
            <video muted autoPlay loop style={{objectFit: 'cover'}} preload="auto">
                <source
                    src="https://verseworldstorage.blob.core.windows.net/verseweb/VerseWorld/Videos/Verselandingpagevideov2.mp4"
                // type="video/mp4"
                />
            </video>
            <div className="center">
                <div className="title">Download The Verse World Metaverse</div>
                <div className="sub-title">Last updated on Aug 7, 2024</div>
                <p className="paragraph">
                    Get started with our immersive metaverse experience by downloading the
                    app installer from our <a href="https://store.epicgames.com/en-US/p/verse-world-e4d877" target="_blank" style={{color: '#428BFF'}}>Epic Store page</a>. Dive into fully fledged virtual world! Follow the
                    steps outlined to complete the installation process and embark on your
                    journey within the metaverse. If you have any questions or need
                    assistance during the installation, check out the helpful links here
                    for additional information and support. Join the Verse World community
                    and unleash your creativity today
                </p>
                <a href="https://store.epicgames.com/en-US/p/verse-world-e4d877" className="epic-store" target="_blank">
                    <img src='../epic-store.png' alt="epic-store-icon" className="icon" />
                    <p className="paragraph">Available Now</p>
                </a>
                {/*<div className="title">verse world installer</div>
                <div className="for-os">For windows</div>
                {renderDownloadButton()}
                <div className="title">installation instructions</div>
                <div className="instructions-container">
                    {renderInstructions(instructions)}
                </div>*/}
                <div className="title" > Recommended System Requirements</div>
                <div className="requirements-list">
                    {renderSystemRequirements(systemRequirements)}
                </div>
                {false && <><div className="title"> Frequently asked questions</div>
                <div className="faq-container">{renderFAQ(faq)}</div></>}
            </div>
            {/*<div className="ellipse small"></div>
            <div className="ellipse medium"></div>
            <div className="ellipse large"></div>
            <div className="ellipse extra-large"></div>*/}
        </div>
    );
}
