import React, { Component } from 'react'
import Modal from './Modal';
import { scrollTo } from '../../util/util';

export default class LinkRedirectionModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            redirectingLink: undefined,
            modal: undefined,
        }
    }

    componentDidMount = () => {
        document.addEventListener('click', this.handleClick);
    }

    handleClick = (event) => {
        const { target } = event;

        if ((target.tagName === 'A' && target.hostname) || (target.tagName === 'EM')) {
            if (target.hostname !== window.location.hostname) {
                event.preventDefault();
                this.setRedirectingLink(target.href)
            }
        }
    };
    
    setRedirectingLink = (value) => {
        this.setState({
            redirectingLink: value
        });

        this.setModalData(value)
    }

    setModalData = (link) => {
        this.setState({
            modal: {
                title: `You will be redirected to an external link. Do you want to proceed?`,
                content: `This will redirect you to  \n \n ${link || 'An external link'}`,
                action: this.redirectClient,
                closeModal: this.closeModal,
            }
        })
    }

    redirectClient = () => {
        if (this.state.redirectingLink)
            window.open(this.state.redirectingLink, '_blank');
    
        this.closeModal()
    }

    closeModal = () => {
        this.setState({
            modal: undefined,
            redirectingLink: undefined
        })
    }


    render() {
        if (this.state.modal)
            return <Modal {...this.state.modal} />
    }
}
