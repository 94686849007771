import React, { Component } from 'react'
import Header from './MapUI/Header'
import ProfilePicture from './Common/ProfilePicture'
import { Link } from 'react-router-dom'
import { authHeader, getCurrentUser, getPlayfabToken, isTokenValid, logOut } from '../util/service'
import axios from 'axios'
import ProfileBanner from './Common/ProfileBanner'

export default class ProfileSettings extends Component {
    constructor(props) {
        super(props)

        this.state = {
            lengthBio: 0,
            bio: '',
            isSaved: true,
            limitChars: 500
        }
    }

    componentDidMount = () => {
        this.windowResized()
        window.addEventListener('resize', this.windowResized);
        if (getCurrentUser() && isTokenValid()) {
            this.fetchProfilePrivate()
        } else {
            window.open('/start/login', '_self')
        }
    }

    windowResized = () => {
        if (window.innerWidth <= 425 && !this.state.isMobile) {
            this.setState({
                isMobile: true
            })
        } else if (window.innerWidth > 425 && this.state.isMobile) {
            this.setState({
                isMobile: false
            })
        }
    }

    fetchProfilePrivate = () => {
        axios.get(`https://versemail.azurewebsites.net/playfab/profile/`, { headers: authHeader() }).then(response => {
            if (response?.data) {
                let user = response?.data
                this.setState({
                    email: user?.email,
                    firstName: user?.firstName,
                    userName: user?.userName,
                    birthDay: user?.birthDay ? new Date(user?.birthDay).toISOString().split('T')[0] : undefined,
                    age: this.calculateAge(user?.birthDay),
                    profileName: user?.userName,
                    bio: user?.bio,
                    profilePicture: user?.profilePicture,
                    coverPicture: user?.coverPicture,
                    lengthBio: user?.bio?.length || 0
                })
            }
        }).catch(error => {
            this.setState({
                error: `User doesn't exist or not verified.`
            })
        });
    }

    updateProfilePrive = () => {
        if (this.state.isSaved || !isTokenValid()) return;

        axios.post(`https://versemail.azurewebsites.net/playfab/profile/`, {firstName: this.state.firstName, birthDay: this.state.birthDay, bio: this.state.bio, profilePicture: this.state.profilePicture, coverPicture: this.state.coverPicture}, { headers: authHeader() }).then(response => {
            if (response?.data) {
                let user = response?.data
                this.setState({
                    isSaved: true,
                })

                let userLocal = JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user'));
                userLocal.profilePicture = user?.profilePicture;
                userLocal.firstName = user?.firstName;

                localStorage.getItem('user') ? localStorage.setItem('user', JSON.stringify(userLocal)) : sessionStorage.setItem('user', JSON.stringify(userLocal));
            }
        }).catch(error => {
            this.setState({
                error: `Error in your request. Please retry later.`
            })
        });
    }

    updateProfilePassword = () => {
        if (this.state.isSaved || !isTokenValid()) return;

        axios.post(`https://versemail.azurewebsites.net/playfab/profile/password`, {password: this.state.password, newPassword: this.state.newPassword, token: getPlayfabToken()}, { headers: authHeader() }).then(response => {
            if (response?.data) {
                let user = response?.data
                this.setState({
                })
            }
        }).catch(error => {
            this.setState({
                error: `Error in your request. Please retry later.`
            })
        });
    }
    
    deleteAccount = () => {
        return axios.post(`https://versemail.azurewebsites.net/playfab/profile/delete`, { password: this.state.deletePassword }, { headers: authHeader() }).then(response => {
            if (response?.data) {
                logOut(true)
            }
        }).catch(error => {
            this.setState({
                error: `Error with your password. Please retry with another one`
            })
        });
    }

    handleInputBio = (e) => {
        let length = e.target.value?.length;

        if (length <= this.state.limitChars)
            this.setState({
                lengthBio: e.target.value?.length, 
                bio: e.target.value,
                isSaved: false
            })
    }

    handleInput = (e, value = false) => {
        this.setState({
            [e.target.name]: e.target.value,
            isSaved: value
        })

        if (e.target.name === 'birthDay') {
            const age = this.calculateAge(e.target.value);
            this.setState({
                age: age
            })
        }
    }

    calculateAge = (birthdateValue) => {
        var birthdate = new Date(birthdateValue);
        var currentDate = new Date();
        var age = currentDate.getFullYear() - birthdate.getFullYear();
        return age
    }

    renderProfileInfos = () => {
        if (this.state.isMobile) {
            return (
                <div className='profile-info mobile'>
                    <div className='profile-titles'>
                        <ProfilePicture picture={this.state.profilePicture} isOwner={isTokenValid()} handleInput={this.handleInput} fromSettings={true}/>
                        <h2 className='settings-titles'>Settings</h2>
                    </div>

                    {this.renderSettingsBtns()}
                </div>
            )
        } else {
            return (
                <div className='profile-info'>
                    <div className='first-part'>
                        <ProfilePicture  picture={this.state.profilePicture} isOwner={isTokenValid()} handleInput={this.handleInput} fromSettings={true}/>
                        <div className='profile-titles'>
                            <h2 className='settings-titles'>Settings</h2>
                        </div>
                    </div>

                    {this.renderSettingsBtns()}
                </div>
            )
        }
    }

    handleSaveButton = () => {
        if (this.props.type === 'password')
            return this.updateProfilePassword()
        else 
            return this.updateProfilePrive()
    }

    renderSettingsBtns = () => {
        return (
            <div className='settings-btns-container'>
                <Link to={'/profile/' + getCurrentUser()?.userName} className='btn'>
                    Cancel
                </Link>

                <div className={'btn confirm' + (this.state.isSaved ? ' disabled' : '')} onClick={this.handleSaveButton}>
                    Save
                </div>
            </div>
        )
    }

    renderNavigator = () => {
        return (
            <div className='settings-navigator'>
                <Link to='/profile/settings' className={this.props.type === '' ? 'active' : ''}>My details</Link>
                <Link to='/profile/settings/change-password' className={this.props.type === 'password' ? 'active' : ''}>Password</Link>
                <Link to='/profile/settings/account' className={this.props.type === 'account' ? 'active' : ''}>Account</Link>
            </div>
        )
    }

    handleSettingsRender = () => {
        if (this.props.type === 'password') {
            return this.renderChangePassword()
        } else if (this.props.type === 'account') {
            return this.renderAccountDeleting()
        } else {
            return this.renderProfileInfoSettings()
        }
    }

    renderProfileInfoSettings = () => {
        return (
            <div className='info-settings'>
                <div className='part'>
                    <div className='input-container'>
                        <p>First Name</p>
                        <input type='text' placeholder='Type your first name...' name='firstName' onChange={this.handleInput} value={this.state.firstName}/>
                    </div>

                    <div className='input-container'>
                        <p>Username</p>
                        <input type='text' placeholder='Type your username...' value={this.state.userName} disabled/>
                    </div>

                    <div className='input-container'>
                        <p>Date Of Birth</p>
                        <input type='date' placeholder='Your date of birth...' value={this.state.birthDay} name='birthDay' onChange={this.handleInput}  min="1920-01-01" max={new Date().toISOString().split('T')[0]}/>
                    </div>
                </div>

                <div className='part'>
                    <div className='vertical'>
                        <div className='input-container small'>
                            <p>Age</p>
                            <input type='number' placeholder='Your age...' value={this.state.age} max={100} min={0} disabled/>
                        </div>

                        <div className='input-container'>
                            <p>Email</p>
                            <div className='email-container'>
                                <i class='bx bx-envelope'/>
                                <input type='email' placeholder='Your email...' value={this.state.email} disabled/>
                            </div>
                        </div>
                    </div>
                    
                    <div className='input-container bio'>
                        <p>Bio</p>
                        <textarea placeholder='Your bio...' onChange={this.handleInputBio} name="bio" value={this.state.bio} />
                        <label>{this.state.lengthBio} / {this.state.limitChars}</label>
                    </div>
                </div>
            </div>
        )
    }

    renderChangePassword = () => {
        return (
            <div className='info-settings'>
                <div className='input-container'>
                    <p>Current password</p>
                    <input type='password' placeholder='Password...' name='password' onChange={this.handleInput} value={this.state.password} />
                </div>

                <div className='input-container'>
                    <p>New password</p>
                    <input type='password' placeholder='New password...' name='newPassword' onChange={this.handleInput} value={this.state.newPassword} />
                </div>

                <div className='input-container'>
                    <p>Retype new password</p>
                    <input type='password' placeholder='Retype new password...' name='newPasswordRepeat' onChange={this.handleInput} value={this.state.newPasswordRepeat} />
                </div>
            </div>
        )
    }

    renderAccountDeleting = () => {
        return (
            <div className='info-settings'>
                <div className='warning'>
                    <div className='icon'>
                        <i className='bx bx-info-circle' />
                    </div>
                    <div className='text'>
                        <h3>Account Deletion</h3>
                        <p>
                            If you tick the box below your account will be permanently deleted and all associated data will be removed. This action cannot be undone, and you will not be able to retrieve any of your data or settings once your account has been deleted. By selecting this option, you agree to permanently delete your account and understand that you will lose access to all associated services and features. Please note that any content or information that you have shared with others may still be visible or accessible even after your account has been deleted. please tick the box below and validate only If you are sure that you want to proceed with deleting your account.
                        </p>
                    </div>
                </div>

                <div className='content'>
                    <div className='checkbox-container' onClick={() => this.handleCheck(this.state.isChecked)}>
                        <i class={this.state.isChecked ? 'bx bxs-checkbox-checked' : 'bx bx-checkbox' }/>    
                        <p>
                            I understand, delete my account
                        </p>
                    </div>

                    {this.renderDeleteAccount()}
                </div>

            </div>
        )
    }

    renderDeleteAccount = () => {
        if (this.state.isChecked)
            return (
                <div>
                    <h5>
                        In order for this action to go through please type in your password
                    </h5>

                    <div className='input-container wide'>
                        <p>Password</p>
                        <div className='delete-container'>
                            <input type='password' placeholder='Password...' name='deletePassword' onChange={(e) => this.handleInput(e, true)} value={this.state.newPassword} />
                            <div className='delete-button' onClick={this.deleteAccount}>
                                <p>
                                    Delete My account
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )
    }

    handleCheck = (value) => {
        this.setState({isChecked: !value})
    }

    render() {
        return (
            <div className='profile-page settings'>
                <Header pageTitle={'PROFILE'} />

                <ProfileBanner coverPicture={this.state.coverPicture} isOwner={isTokenValid()} isSettings={true} handleInput={this.handleInput} fromSettings={true}/>

                {this.renderProfileInfos()}

                <div className='content'>
                    {this.renderNavigator()}

                    {this.handleSettingsRender()}
                </div>
            </div>
        )
    }
}
