import axios from 'axios'
import React, { Component } from 'react'
import {authHeader, isAdmin} from '../../util/service'
import { Link } from 'react-router-dom'
import { getDate } from '../../util/util'
import Skeleton from 'react-loading-skeleton'

export default class NewsArticles extends Component {
    constructor(props) {
        super(props)

        this.state = {
            articles: [],
            skip: 0,
        }
    }

    componentDidMount = () => {
        this.getArticles(0, 5, this.props.type);

        if (this.props.isWithRecent) {
            this.getArticles(0, 5, 'views');
        }
    }

    getArticles = (skip = 0, limit = 5, type) => {
        axios.get(`https://versemail.azurewebsites.net/news/world/articles/${skip}/${limit}/${type}`, { headers: authHeader() }).then(response => {
            if (response?.data) {
                if (type === 'views') {
                    this.setState({
                        mostViewed: response?.data?.articles
                    })
                } else if (type === 1) {
                    this.props.setList('highlightedArticles', response?.data?.articles)
                } else if (type === 3) {
                    this.props.setList('movies', response?.data?.articles)
                } else if (type === 3.1) {
                    this.props.setList('selectedMovie', response?.data?.articles[0])
                } else {
                    this.setState({
                        articles: response?.data?.articles,
                        restElements: response?.data?.restElements
                    })
                }
            }
        }).catch(error => {

        });
    }

    handlePagination = (type, skipValue = 5) => {
        this.setState({
            skip: this.state.skip + skipValue,
            articles: []
        }, () => {
            this.getArticles(this.state.skip, undefined, type)
        });
    }

    renderMainArticle = (latestArticle) => {
        if (latestArticle)
            return (
                <Link to={isAdmin() ? `/ctqbc/article/${latestArticle?.slug}` : '/article/' + latestArticle?.slug} className='main-article' style={{opacity: !latestArticle?.isPublished && .5}}>
                    <div className='image' style={{ backgroundImage: `url(${latestArticle?.image})` }} />
                    <div className='details'>
                        <div className='author'>
                            {latestArticle?.author?.firstName || latestArticle?.author} - <span>{getDate(latestArticle?.creationDate)}</span>
                        </div>
                        <div className='title'>
                            {latestArticle?.title}
                        </div>
                        <div className='text'>
                            {latestArticle?.text || latestArticle?.content}
                        </div>
                    </div>
                </Link>
            )
    }

    renderOtherArticles = (articles) => {
        return (
            <div className='other-articles-scroll'>
                <div className='other-articles'>
                    {
                        articles?.length ?
                            articles.map((article) => {
                                return this.renderArticle(article)
                            }) :
                            <Skeleton height={300} width={430} />
                    }
                </div>
            </div>
        )
    }

    renderArticle = (article) => {
        return (
            <Link to={isAdmin() ? `/ctqbc/article/${article?.slug}` : '/article/' + article?.slug} className='article-container' style={{opacity: !article?.isPublished && .5}}>
                <div className='image' style={{ backgroundImage: `url(${article?.image})` }} />
                <div className='details'>
                    <p>
                        {article?.author?.firstName || article?.author} - <span>{getDate(article?.creationDate)}</span>
                    </p>
                    <h4>
                        {article?.title}
                    </h4>
                </div>
            </Link>
        )
    }


    renderPart = (title, articles, isWithRecent = true, type) => {
        return (
            <section>
                <div className='articles-list'>
                    <div className='title-container'>
                        <h3>
                            {title}

                            <div className='arrows'>
                                <div className={this.state.skip != 0 ? 'active' : '' } onClick={() => this.state.skip != 0 && this.handlePagination(type, -5)}>
                                    <i className='bx bx-chevron-left' />
                                </div>

                                <div className={(this.state.restElements > 0) ? 'active' : ''} onClick={() => (this.state.restElements > 0) && this.handlePagination(type, +5)}>
                                    <i className='bx bx-chevron-right' />
                                </div>
                            </div>
                        </h3>
                        <div className='border' />
                    </div>

                    <div className='content'>
                        {this.renderMainArticle(articles[0])}
                        {this.renderOtherArticles(articles)}
                    </div>
                </div>
                {this.renderRecent(isWithRecent)}
            </section>
        )
    }

    renderRecent = (isWithRecent) => {
        if (isWithRecent)
            return (
                <div className='articles-list recent'>
                    <div className='title-container navigation'>
                        <div className='border' />

                        <div className='switch active'>
                            Most Viewed
                        </div>
                        {/*<div className='switch'>
                            Trending
                        </div>
                        <div className='switch'>
                            Most Views
                        </div>*/}
                    </div>

                    <div className='content'>
                        {this.renderOtherArticles(this.state.mostViewed)}
                    </div>
                </div>
            )
    }

    render() {
        const { title, isWithRecent, type } = this.props;

        if (!title) 
            return null; 

        return this.renderPart(title, this.state.articles, isWithRecent, type)
    }
}
