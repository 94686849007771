import React from 'react'
import DownloadPage from '../components/DownloadPage'
import Footer from '../components/Common/Footer'

export default function DownloadPageContainer() {
    return (
        <>
            <DownloadPage />
            <Footer />
        </>
    )
}
