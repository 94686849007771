import React from 'react'
import Article from '../components/Article'
import Footer from '../components/Common/Footer'
import { useParams } from 'react-router';

export default function ArticleContainer() {
    let { slug } = useParams();

    return (
        <>
            <Article slug={slug}/>
        </>
    )
}
