import React, { useEffect, useRef, useState } from 'react'

export default function NavigationMenu(props) {
    const {data} = props
    const [selectedElement, setSelectedElement] = useState({});
    const navigationRef = useRef()

    useEffect(() => {
        window.addEventListener('mousedown', pageClick, false);
    }, [])

    const pageClick = (e) => {
        if (navigationRef && navigationRef.current && !navigationRef.current.contains(e.target)) {
            props.handleNavigationMenu()
        }
    }

    const renderMenuElements = () => {
        if (!data?.length) return
        
        return data.map((element) => {
            return (
                <div className={'element' + (element?.name === selectedElement?.name ? ' selected' : '') } onClick={() => selectElement(element)}>
                    {element?.title}
                </div>
            )
        })
    }

    const getType = (data) => {
        let type = data[0]?.type;
        
        switch (type) {
            case 'TRANSPORTATION':
                return {type, url: 'https://images.pexels.com/photos/681335/pexels-photo-681335.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'}
            case 'SEMI_COLLECTIVE':
            case 'ACTIVITIES':
                return {type: 'ACTIVITIES', url: 'https://images.pexels.com/photos/2291073/pexels-photo-2291073.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'}
            case 'NFT':
                return {type: 'SHOPPING', url: 'https://images.pexels.com/photos/5011647/pexels-photo-5011647.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'}
            case 'FREE_LANDS':
                return {type: 'FREE LANDS', url: 'https://images.pexels.com/photos/388415/pexels-photo-388415.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'}
            case 'CLIFF': 
            case 'VILLA': 
            case 'HOUSING':
            case 'BEACH':
                return {type: 'HOUSING', url: 'https://images.pexels.com/photos/1642125/pexels-photo-1642125.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'}
            default: 
                return;
        }
    }

    const selectElement = (element) => {
        setSelectedElement(element)
        props.setselectedNavigationItem(element)
        props.handleNavigationMenu()
    }

    if (data?.length && getType(data))
        return (
            <div className='navigation-menu' ref={navigationRef}>
                <div className='image-container' style={{backgroundImage: `url(${getType(data)?.url})`}}>
                    <div>{getType(data)?.type}</div>
                </div>

                <div className='menu-container'>
                    {renderMenuElements()}
                </div>
            </div>
        )
}
