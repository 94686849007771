import React, { Component } from 'react'
import { IconMenu2 } from '@tabler/icons';

export default class Header extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isFocused: false
        }

        this.textInput = React.createRef();
    }

    focusInput = () => {
        if (this.textInput.current !== null && !this.state.isFocused) {
            this.textInput.current.focus();
        }
    }

    toggleFocus = (value) => {
        this.setState({
            isFocused: value
        })
    }

    render() {
        return (
            <div className='navbar'>
                <img src='' className='logo' />

                <div className='menu-icon' >
                    <IconMenu2 stroke={1.5} size="1.3rem" />
                </div>

                <div className={'search-input-container' + (this.state.isFocused ? ' focused' : '')} onClick={this.focusInput}>
                    <i class='bx bx-search' ></i>
                    <input className='input' type='text' placeholder='Search...' ref={this.textInput} onFocus={() => this.toggleFocus(true)} onBlur={() => this.toggleFocus(false)} />
                    <div className='menu-icon'>
                        <i class='bx bx-send' ></i>
                    </div>
                </div>

                <div className='profile-icons-container'>
                    <div className='menu-icon'>
                        <i class='bx bx-bell' ></i>
                    </div>

                    <div className='user-avatar-container'>
                        <div className='avatar' />
                        <p>
                            Admin
                        </p>
                    </div>

                </div>
            </div>
        )
    }
}
