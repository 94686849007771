import React, { Component } from 'react'
import Header from './MapUI/Header'
import parse, { domToReact } from 'html-react-parser';
import { Link } from 'react-router-dom';
import NewsletterArticle from './Common/NewsletterArticle';
import LinkRedirectionModal from './Common/LinkRedirectionModal';
import { getDate, scrollTo } from '../util/util';
import axios from 'axios';
import {authHeader, isAdmin} from '../util/service';
import Footer from './Common/Footer';

export default class Article extends Component {
    constructor(props) {
        super(props)

        this.state = {
            article: {
                title: '',
                image: '',
                content: ''
            },
            isLoading: true,
            articles: [],
            relatedArticles: []
        }
    }

    componentDidMount = () => {
        this.getArticle(this.props.slug)
    }

    getArticle = (slug) => {
        scrollTo(0)

        axios.get(`https://versemail.azurewebsites.net/news/world/article/${slug}`, { headers: authHeader() }).then(response => {
            if (response?.data) {
                this.setState({
                    article: response?.data,
                    originalArticle: response?.data,
                    history: [],
                    isLoading: false
                })
            }
        }).catch(error => {
            this.setState({
                isLoading: false,
                notFound: true
            })

            setTimeout(() => {
                window.open('/news', '_self')
            }, 5000)
        });
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.slug !== this.props.slug) {
            this.getArticle(this.props.slug)
        }

        if (prevState.article !== this.state.article) {
            this.getArticles(0, 3, this.state.article?.type === '2' ? 1 : 2, 'articles') // don't miss
            this.getArticles(0, 5, this.state.article?.type || 1, 'relatedArticles') // related
        }
    }

    getArticles = (skip, limit, type, name) => {
        axios.get(`https://versemail.azurewebsites.net/news/world/articles/${skip}/${limit}/${type}`, { headers: authHeader() }).then(response => {
            if (response?.data) {
                let data = response?.data?.articles;
                // filter data that has same _id as this article
                data = data.filter((article, index) => {
                    if (article?._id !== this.state.article?._id) {
                        return article
                    }
                })

                if (data?.length > limit - 1) {
                    data = data.slice(0, limit - 1)
                }

                this.setState({
                    [name]: data
                })
            }
        }).catch(error => {

        });
    }

    getContentParsed = (content) => {
        if (!content) return;

        content = content.replace(/\(link=(.*?)\)(.*?)\/link\)|\(b\)(.*?)\/b\)|\(i\)(.*?)\/i\)/g, (match, url, text, boldText, italicText) => {
            if (url) {
                if (url.startsWith('www')) {
                    url = 'http://' + url;
                }
                return `<a href='${url}'>${text}</a>`;
            }
            if (boldText) {
                return `<b>${boldText}</b>`;
            }
            if (italicText) {
                return `<i>${italicText}</i>`;
            }
        });

        return (
            <>
                {parse(content)}
            </>
        )
    }

    displayRelatedArticles = (articles) => {
        if (!articles) return 'No articles to display yet'

        return articles.map(article => {
            return (
                <Link to={isAdmin() ? `/ctqbc/article/${article?.slug}` : `/article/${article?.slug}`} className='element' style={{opacity: !article?.isPublished && .5}}>
                    <div className='image' style={{ backgroundImage: `url(${article?.image})` }} />
                    <h5>{article?.title}</h5>
                </Link>
            )
        })
    }

    renderImpressions = () => {
        return (
            <div className='impressions'>
                {/*<div className='child'>
                    <div className='icon'>
                        <i class='bx bx-heart' />
                        <p>28</p>
                    </div>

                    <div className='icon'>
                        <i class='bx bx-message-rounded-dots' />
                        <p>21 comments</p>
                    </div>
                </div>

                <div className='child'>
                    <div className='icon' style={{ fontSize: 20 }}>
                        <i className='bx bx-upload' />
                        <p>72</p>
                    </div>
                </div>*/}
            </div>
        )
    }

    renderAuthor = () => {
        return (
            <div className='author-details'>
                <div className='profile-picture-container'>
                    <div className='profile-picture' style={{ backgroundImage: `url(${this.state.article?.author?.profilePicture})` }} />
                </div>

                <div className='author'>
                    <span>Author</span>
                    <h5>{this.state.article?.author?.firstName}</h5>
                    <p>
                        {this.state.article?.author?.description}
                    </p>
                </div>
            </div>
        )
    }

    renderBottomRelatedArticles = () => {
        if (!this.state.relatedArticles?.length) return 'No related articles yet'

        return this.state.relatedArticles.map((article) => {
            return null
        })
    }

    renderArticle = () => {
        return (
            <>
                <div className='article'>
                    {this.renderNotFound()}
                    <div className='title-container'>
                        <h4>
                            {this.state.article?.title}
                        </h4>
                    </div>
                    <div className='image-container'>
                        <div className='image' style={{ backgroundImage: `url(${this.state.article?.image})` }} />
                    </div>

                    <div className='details'>
                        <div className='date'>
                            {getDate(this.state.article?.creationDate)}
                        </div>

                        <div className='user'>
                            By {this.state.article?.author?.firstName}
                        </div>

                        <div className='user'>
                            |
                        </div>

                        <div className='length'>
                            {Math.max(Math.round((this.state.article?.content?.split(' ')?.length) / 238), 1)} min read
                        </div>
                    </div>

                    <div className='content-container'>
                        <div className='content'>
                            {this.getContentParsed(this.state.article?.content)}

                            <NewsletterArticle author={this.state.article?.author?.firstName} />
                            {this.renderImpressions()}
                            {this.renderAuthor()}
                        </div>

                        <div className='recommendations'>
                            <h4>Don't miss</h4>

                            <div className='articles'>
                                {this.displayRelatedArticles(this.state.articles)}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='related-articles-container' style={{ marginLeft: 0 }}>
                    {
                        this.state.relatedArticles?.length ?
                        <>
                            <h4>Related Topics</h4>
                            <div className='related-articles'>
                                {this.displayRelatedArticles(this.state.relatedArticles)}
                            </div>
                        </> : 
                        ''
                    }

                </div>
            </>
        )
    }

    renderLoaderOrArticle = () => {
        if (this.state.isLoading) {
            return (
                <div className='loading-page'>
                    <div className='loader' />
                </div>
            )
        }

        return this.renderArticle()
    }

    renderNotFound = () => {
        if (this.state.notFound)
            return (
                <div className={'status-bar alert missing'}>
                    Article not found. You will be redirected to the news page shortly...
                </div>
            )
    }

    render() {
        return (
            <div className='article-page'>
                <Header pageTitle={'NEWS'} />
                <LinkRedirectionModal />
                {this.renderLoaderOrArticle()}
                <Footer />
            </div>
        )
    }
}
