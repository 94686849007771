const mapProperties = [
    {
        image: '01',
        type: 'TRANSPORTATION',
        description: `Space reserved for the landing of space shuttles, the SpaceHub is where every citizen will pass through
        to reach Verse City.`,
        name: 'SpaceShip',
        title: 'Space hub',
        position: {
            "x": 79.50181546733607,
            "y": 13.50917026353187,
            "z": 128.48764947822738
        }
    },
    {
        image: '02',
        description: `Where players will spawn, and start their journey
        by enjoying a panoramic view open to all this
        world.`,
        name: 'Station',
        type: 'TRANSPORTATION',
        title: 'Central hub',
        position: {
            "x": 68.5395156416809,
            "y": 8.52487287194204,
            "z": 133.53870434006325
        },
        isCameraBehind: true
    },
    {
        image: '05',
        type: 'SEMI_COLLECTIVE',
        description: `Shafts and duplexes with views of the urban
            area or the beach, offer a compromise
            between the privacy of individual homes and
            the communal aspects of larger housing
            complexes.`,
            
        title: 'Semi collective buildings',
        parent: 'Semi collective buildings',
        name: [
            "Building022", 'Building025', 'Building026', 'Building027', 'Building028',
            'Building029', 'Building030', 'Building031', 'Building032',
            'Building033', 'Building034', 'Building035', 'Building036',
            'Building037', 'Building038', 'Building039', 'Building040',
            'Building041', 'Building042', 'Building043', 'Building044',
            'Building045', 'Building046', 'Building047', 'Building048',
            'Building049', 'Building050', 'Building051', 'Building064',
            'Building065', 'Building066', 'Building067', 'Building068',
            'Building069', 'Building070', 'Building071', 'Building072',
            'Building073', 'Building074', 'Building075', 'Building076',
            'Building077', 'Building078', 'Building079', 'Building081', 'Building082',
            'Building083', 'Building084', 'Building085', 'Building086',
            'Building087', 'Building088', 'Building089', 'Building090',
            'Building091', 'Building092', 'Building093', 'Building094',
            'Building095', 'Building096', 'Building097', 'Building098',
        ],
        isCameraBehind: true,
        position: {
            "x": -20.04531085914283,
            "y": 4.56273516254471,
            "z": 144.16489294861626
        }
    },
    {
        image: '09',
        description: `Buildings that touch the clouds, where penthouses are available with the most beautiful view of Verse.`,
        type: 'SKYSCRAPER',
        parent: 'skyscrapers',
        name: [
            'Building000', 'Building001', 'Building002',
            'Building003', 'Building004', 'Building005',
            'Building006', 'Building007', 'Building008',
            'Building009', 'Building010', 'Building011',
            'Building012', 'Building013', 'Building014',
            'Building015', 'Building016', 'Building017',
            'Building018', 'Building019', 'Building020',
            'Building021', 'Building023', 'Building024',
            'Building053', 'Building054', 'Building055',
            'Building056', 'Building057', 'Building058',
            'Building059', 'Building060', 'Building061',
            'Building062', 'Building080', 'Building081'
        ],
        position: {
            "x": 8.426886176004732,
            "y": 10.2757367129968,
            "z": 126.53503796418306
        },
        title: 'City skyscrapers',
        isCameraBehind: true
    },
    {
        title: 'Suburban villas',
        name: [
            'SuburbVilla000',
            'SuburbVilla001',
            'SuburbVilla002',
            'SuburbVilla003',
            'SuburbVilla004',
            'SuburbVilla005',
            'SuburbVilla006',
            'SuburbVilla007',
            'SuburbVilla008',
            'SuburbVilla009',
            'SuburbVilla010',
            'SuburbVilla011'
        ],
        type: 'VILLA',
        image: '07',
        description: `A sought-after neighborhood for Citizens right next to the Stadium and the Amusement Park, the most
        private neighborhood in Verse One.
        .`,
        parent: 'SuburbVilla000',
        position: {
            "x": -40.6247118097371,
            "y": 4.17374790748714,
            "z": 111.1705704846212
        },
        isCameraBehind: true
    },


    {
        title: 'Beach houses (near the city)',
        name: [
            'BeachHouse000', 'BeachHouse001',
            'BeachHouse002', 'BeachHouse003',
            'BeachHouse004', 'BeachHouse005',
            'BeachHouse006', 'BeachHouse007',
            'BeachHouse008', 'BeachHouse009',
            'BeachHouse010', 'BeachHouse011',
            'BeachHouse012'
        ],
        type: 'BEACH',
        description: `Just steps away from the stadium, experience the ultimate beachfront living with breathtaking views of
        Verse City.`,
        parent: 'BeachHouse000',
        isCameraBehind: true,
        position: {
            "x": -45.5022332631787,
            "y": 4.12542996029777,
            "z": 170.72659019977988
        },
        image: '10',
    },

    {
        description: `Have some fun and adrenaline in the amusement park of Verse One.`,
        name: [
            'AmusementPark', 'Park', 'ParkWheel'
        ],
        type: 'ACTIVITIES',
        parent: 'AmusementPark',
        title: 'Amusement Park',
        position: {
            "x": -33.49532642051854,
            "y": 10.56226069771694,
            "z": -60.63223511194181
        }
    },
    {
        image: '11',
        type: 'ACTIVITIES',
        description: `Come and witness the biggest sports and musical events in Verse One.`,
        name: 'Stadium_01',
        title: 'Stadium'
    },
    {
        image: '08',
        type: 'SKYSCRAPER',
        description: `The main center for cryptocurrency trading in Verse One`,
        name: 'TradingCenter',
        title: 'Trading Center',
        isCameraBehind: true
    },
    {
        image: '06',
        description: `The Cinema of Verse One, where all film premieres will take place.`,
        name: 'Cinema',
        title: 'City Cinema',
        type: 'ACTIVITIES',
        isCameraBehind: true
    },
    {
        image: '03',
        type: 'TRANSPORTATION',
        description: `The podium for the most beautiful Citizens of Verse One, the Catwalk will host the most glamorous
        fashion shows from top metaverse brands.`,
        name: 'CatWalk',
        title: 'Cat walk'
    },
    {
        image: '04',
        type: 'SKYSCRAPER',
        description: `The central point of Verse City, where you can find information about Verse One`,
        name: 'CityCenter',
        title: 'City Hub'
    },
   /* {
        image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4b/Griffith_observatory_2006.jpg/1200px-Griffith_observatory_2006.jpg',
        type: 'NFT',
        description: `Lorem ipsum dolor sit amet consectetur. 
        Amet leo tellus dui nec neque`,
        name: 'GeoSphere_01',
        title: 'NFT square'
    },*/
    {
        image: 'https://images.pexels.com/photos/388415/pexels-photo-388415.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        type: 'FREE_LANDS',
        description: `Lorem ipsum dolor sit amet consectetur. 
        Amet leo tellus dui nec neque`,
        name: 'Houses',
        title: 'Free lands'
    },
    {
        title: 'Hill houses',
        name: [
            'Buildings_part064',
            'Buildings_part065',
            'Buildings_part066',
            'Buildings_part067',
            'Buildings_part068',
            'Buildings_part069',
            'Buildings_part070',
            'Buildings_part071',
            'Buildings_part072',
            'Buildings_part073',
            'Buildings_part074',
            'Buildings_part075',
            'Buildings_part076',
            'Buildings_part077',
            'Buildings_part078',
            'Buildings_part079'
        ],
        description: `The most beautiful villas in the most peaceful neighborhood of Verse One.`,
        type: 'CLIFF',
        parent: 'Buildings_part',
        position: {
            "x": -88.23549376548814,
            "y": 5.37261875045283,
            "z": 25.883262358215404
        }
    },
    {
        title: 'Cliff houses',
        description: 'Villas on a cliff in the Canyon, who has never dreamed of living on a cliff with a view of Verse City?',
        name: [
            'Buildings_part170', 'Buildings_part171',
            'Buildings_part172', 'Buildings_part173',
            'Buildings_part174', 'Buildings_part175',
            'Buildings_part176', 'Buildings_part177',
            'Buildings_part178', 'Buildings_part179',
            'Buildings_part180', 'Buildings_part181',
            'Buildings_part182', 'Buildings_part183',
            'Buildings_part184', 'Buildings_part185',
            'Buildings_part186', 'Buildings_part187',
            'Buildings_part188', 'Buildings_part189',
            'Buildings_part190', 'Buildings_part191',
            'Houses_cliffs000', 'Houses_cliffs001',
            'Houses_cliffs002',
            'Houses_cliffs003', 'Houses_cliffs004', 'Houses_cliffs005',
            'Houses_cliffs006', 'Houses_cliffs007', 'Houses_cliffs008',
            'Houses_cliffs009', 'Houses_cliffs010', 'Houses_cliffs011',
            'Houses_cliffs012', 'Houses_cliffs013', 'Houses_cliffs014',
            'Houses_cliffs015', 'Houses_cliffs016', 'Houses_cliffs017',
            'Houses_cliffs018'
        ],
        parent: 'cliff',
        type: 'CLIFF',
        isCameraBehind: true,
        position: {
            "x": -8.69398849072258,
            "y": 7.66364191183635,
            "z": -88.54898357478271
        }
    },
    {
        title: 'Bungalows',
        description: 'Spacious and elegantly designed residence with modern amenities, offering exceptional comfort.',
        name: 'Buildings_part136',
        type: 'VILLAS',
        position: {
            "x": -20.49849725836003,
            "y": 3.01668717253567,
            "z": 39.14680049882545
        },
        image: '12',

    }, 
    {
        name: [
            'Buildings_part084', 'Buildings_part085', 'Buildings_part086',
            'Buildings_part087', 'Buildings_part088', 'Buildings_part089',
            'Buildings_part090', 'Buildings_part091', 'Buildings_part092',
            'Buildings_part093', 'Buildings_part094', 'Buildings_part095',
            'Buildings_part096', 'Buildings_part097', 'Buildings_part098',
            'Buildings_part099', 'Buildings_part100', 'Buildings_part101',
            'Buildings_part102', 'Buildings_part103', 'Buildings_part104',
            'Buildings_part105', 'Buildings_part106', 'Buildings_part107',
            'Buildings_part108', 'Buildings_part109', 'Buildings_part110',
            'Buildings_part111', 'Buildings_part112', 'Buildings_part113',
            'Buildings_part114', 'Buildings_part115', 'Buildings_part116',
            'Buildings_part117', 'Buildings_part118', 'Buildings_part119',
            'Buildings_part120', 'Buildings_part121', 'Buildings_part122',
            'Buildings_part123', 
            'Buildings_part126', 'Buildings_part127', 'Buildings_part128',
            'Buildings_part129', 'Buildings_part130', 'Buildings_part131',
            'Buildings_part132'
        ],
        title: 'Beach houses (Near the Stadium)',
        type: 'BEACH',
        description: `Just steps away from the stadium, experience the ultimate beachfront living with breathtaking views of
        Verse City.`,
        parent: 'BeachHouse',
        position: {
            "x": -86.35627587214438,
            "y": 3.07944657716435,
            "z": 72.65906349568547
        },
        isCameraBehind: true
    }
]

export default mapProperties