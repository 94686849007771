// cached data from forum categories
const forumtypes = [
    {
        _id:"64ecdc829bd86a29b4bd432d",
        icon: 'bx bxs-megaphone',
        color: '#FFA500',
        title: 'Announcements',
        type: 'ANNOUNCEMENTS',
        url: 'announcements',
        text: `This topic includes updates about new features, events, and releases related to the Verse World app and the users can open discussions about them. `
    },
    {
        "_id":"64ecdc829bd86a29b4bd432e",
        icon: 'bx bx-conversation',
        color: '#00D6D6',
        title: 'General Discussion',
        type: 'GENERAL',
        url: 'general',
        text: `This topic includes discussions about the overall hyper realistic metaverse concept. Users could share their experiences, opinions, and suggestions. `
    },
    {
        "_id":"64ecdc829bd86a29b4bd432f",
        icon: 'bx bx-message-square-error',
        color: '#FF5A5A',
        title: 'Technical Support',
        type: 'TECHNICAL',
        url: 'support',
        text: `This topic includes questions and answers about technical issues related to Verse World’s issues, such as how to troubleshoot problems or use specific features. `
    },
    {
        "_id":"64ecdc829bd86a29b4bd4330",
        icon: 'bx bx-star',
        color: '#08F',
        title: 'Feedback and Suggestions',
        type: 'FEEDBACK',
        url: 'feedback',
        text: `This topic allows users to provide feedback and suggestions to the Verse company, which could be used to improve the user experience and enhance the product. `
    },
    {
        "_id":"64ecdc829bd86a29b4bd4331",
        icon: 'bx bx-donate-heart',
        color: '#C837AB',
        title: 'Community Creations',
        type: 'COMMUNITY',
        url: 'community',
        text: `This topic showcases user-generated content such as virtual world designs for 3d artists, avatar customization, and user-created events. Users could share their creations and get feedback from the community. (to be developed more when the verse world app is well defined)  `
    },
    {
        "_id":"64ecdc829bd86a29b4bd4332",
        icon: 'bx bx-shopping-bag',
        color: '#FF83C8',
        title: 'Marketplace',
        type: 'MARKETPLACE',
        url: 'marketplace',
        text: `This topic includes discussions about buying and selling NFTs. Users could advertise their products or services and negotiate deals. (it will be clearer when the verse world will be well defined `
    },
    {
        "_id":"64ecdc829bd86a29b4bd4333",
        icon: 'bx bx-group',
        color: '#679AFD',
        title: 'Social',
        type: 'SOCIAL',
        url: 'social',
        text: `This topic allows users to connect with each other, share their interests, and organize meetups within the verse world. `
    }
]

export default forumtypes;