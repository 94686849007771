import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import React, { Component } from 'react'

export default class ImagesGalleryMenu extends Component {
    constructor(props) {
        super(props)

        this.state = {
            profilePictures: [
                'https://i.seadn.io/gae/zkR7mzWQXgEZBki6VGs856KLvFf4ffO1glzpe49zEF5UN4rVE8qhPXsW-4Elc0k2dDZbtc22aHoknDzwww9LfsRElIQtwgIOQLVX?auto=format&dpr=1&w=1000',
                'https://i.seadn.io/gae/lvFr3fG87sMe6yLKLl-2IAgcbjENQ0_gaq0reveSQKRps4VnG_uCm74CIWumDjFAONKvLFwstPLWUWX-vzZMRSqEhDUcarzcvley?auto=format&dpr=1&w=1000',
                'https://i.seadn.io/gae/7PLMk3eSuBQrFYZO5EZedjbrjE_OzWRB36hCLBFiixcxEPF7wFj4rHvTl93ZlZ-jubZ7tqO3_te7DWFJ_BFOo1mO5omGrRv31pFSdw?auto=format&dpr=1&w=1000',
                'https://i.seadn.io/gae/UKnW_stiyoXqVFeAok-qOxevlV9i171uowOb2oaINgjFoRzls4i6w541aNt-GF3UyihEiTgrN2zKNQsESnTmznpQH7qIBVrf_TH6?auto=format&dpr=1&w=1000',
                'https://i.seadn.io/gae/2kT1-qvn8x8eOqf0QA1q-sG11nIFoAQhGIANpqGHeJC9kkqJ1qJNM38irslaOI-XIZv-zwjHy-aygxqlpUgbtbfrSNJFSKl1OchKrA?auto=format&dpr=1&w=1000',
                'https://i.seadn.io/gae/ArmI05R0JZ181WJt_sDDAKYLlJvrxXfBtQdeZbqHnW_pJ5kASfs_XOci65KGHo7nB6EU0Oy07_pe5qWWI0jRcBVGjikjh5iNumEbGg?auto=format&dpr=1&w=1000',
                'https://i.seadn.io/gae/d7lfqrGmKJ2A0VKXLA414zbK1mhCs-GZdTb3bUg2SN0uXOnBufAHvDDhUdtHKvCYycWxrSoqpk_wDM2e1U_63z2DmB5oIHWC_ZiS?auto=format&dpr=1&w=1000',
            ],
            coverImages: [
                'https://images.unsplash.com/photo-1665088587830-ca8529af39ca?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
                'https://images.pexels.com/photos/11643390/pexels-photo-11643390.jpeg?auto=compress&cs=tinysrgb&w=1900', 
                'https://images.pexels.com/photos/7135121/pexels-photo-7135121.jpeg?auto=compress&cs=tinysrgb&w=1900',
                'https://images.pexels.com/photos/7130475/pexels-photo-7130475.jpeg?auto=compress&cs=tinysrgb&w=1900',
                'https://images.pexels.com/photos/1210276/pexels-photo-1210276.jpeg?auto=compress&cs=tinysrgb&w=1900'
            ]
        }

        this.modalRef = React.createRef();
    }

    componentDidMount = () => {
        window.addEventListener('mousedown', this.pageClick, false);
        this.disableScroll()
    }

    componentWillUnmount = () => {
        clearAllBodyScrollLocks();
    }

    disableScroll = () => {
        let body = document.querySelector('#targetElementId');
        disableBodyScroll(body);
    }

    pageClick = (e) => {
        if (this.modalRef && this.modalRef.current && !this.modalRef.current.contains(e.target)) {
            this.closeModal()
        }
    }

    closeModal = () => {
        this.props.closeModal && this.props.closeModal(false)
    }

    renderImages = (pictures) => {
        return (
            <div className='images-container'>
                {
                    pictures.map((picture) => {
                        return this.renderImage(picture)
                    })
                }
            </div>
        )
    }

    renderImage = (picture) => {
        return (
            <div className={'image' + (this.props.isBanner ? ' banner' : '') } onClick={() => this.selectImage(picture)} style={{backgroundImage: `url(${picture})`}} />
        )
    }

    selectImage = (image) => {
        this.props.setUploadedPicture(image);
        this.closeModal()
    }

    render() {
        const {isBanner} = this.props;

        return (
            <div className='modal-overlay'>
                <div className='modal-container gallery' ref={this.modalRef}>
                    <h3>Choose your Image</h3>
                    {this.renderImages(isBanner ? this.state.coverImages : this.state.profilePictures)}
                </div>
            </div>
        )
    }
}
