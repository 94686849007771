import React, { useEffect, useState } from 'react'
import { authHeader } from '../../util/service'
import axios from 'axios'

export default function VoucherInput(props) {
    const [voucher, setVoucher] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [isVerified, setIsVerified] = useState(null);

    useEffect(() => {
        getUserVoucher()
    }, [])

    const addVoucher = () => {
        if (voucher !== '' && !isLoading && !isVerified) {
            setLoading(true)
            axios.post(`https://versemail.azurewebsites.net/playfab/profile/voucher/${voucher}`, {}, { headers: authHeader() }).then(response => {
                setLoading(false)
                setIsVerified(true)
            }).catch(error => {
                setLoading(false)
                setIsVerified(false)
            });
        }
    }

    const getUserVoucher = () => {
        setLoading(true)
        axios.get(`https://versemail.azurewebsites.net/playfab/profile/voucher`, { headers: authHeader() }).then(response => {
            //setVoucher(response?.data?.value)
            console.log(response)
            if (response?.data?.voucher?.value) {
                setVoucher(response?.data?.voucher?.value)
                setIsVerified(true)
            }
            setLoading(false)
        }).catch(error => {
            setLoading(false)
        });
    }

    const handleInput = (e) => {
        setVoucher(e?.target?.value)
        setIsVerified(null)
    }

    const renderStatus = () => {
        if (isVerified === null) return;

        return (
            <p className={'status' + (isVerified ? ' valid' : ' error')}>
                {
                    isVerified ?
                        <>
                            <i className='bx bx-check-circle' /> Your voucher is verified !
                        </>
                        :
                        <>
                            <i className='bx bx-error-circle' /> Your voucher is not valid
                        </>
                }
            </p>
        )
    }

    return (
        <>
            <div className='collection'>
                <div className='input-container-profile'>
                    <p>
                        Voucher
                    </p>
                    <div className='voucher-container'>
                        <input type='text' placeholder='Type your voucher...' value={voucher} onChange={handleInput} disabled={isLoading || isVerified} />
                        <div className={'btn confirm ' + (isLoading || isVerified ? 'disabled' : '')} onClick={addVoucher}>
                            {
                                isLoading ? 'Loading...' : isVerified ? 'Verified' : 'Add Voucher'
                            }
                        </div>
                    </div>
                    {renderStatus()}
                </div>
            </div>
        </>
    )

}
