import axios from 'axios';
import moment from 'moment';

function getDate(time) {
    return moment.utc(time).local().startOf('seconds').fromNow();
}

function getDateFormat(time) {
    return moment.utc(time).local().calendar();
}

function scrollTo(px) {
    window.scrollTo({top: px || 0, left: 0, behavior: 'smooth' });
}

function getConnectedUserId() {
    return JSON.parse(localStorage.getItem('user'))?._id || JSON.parse(sessionStorage.getItem('user'))?._id;
}

function getPlayfabId() {
    return "A98EC"
}

const isEmpty = (value) => {
    return !value || value === '' || value === null
}

const multipleIsEmpty = (values) => {
    if (!values?.length) return false;

    let result = false;
    for (let value of values) {
        if (isEmpty(value)) {
            result = true;
            break;
        }
    }

    return result;
}

export {
    getDate,
    getDateFormat,
    scrollTo,
    getConnectedUserId,
    getPlayfabId,
    isEmpty, 
    multipleIsEmpty
}
