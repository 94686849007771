import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ReactDOM from "react-dom/client";
import StartContainer from './containers/StartContainer';
import Login from './components/Login';
import './index.scss'
import Register from './components/Register';
import MapContainer from './containers/MapContainer';
import MapUI from './components/MapUI';
import MapContainerTutorial from './containers/MapContainerTutorial';
import ProfileContainer from './containers/ProfileContainer';
import NewsContainer from './containers/NewsContainer';
import ForumContainer from './containers/ForumContainer';
import ArticleContainer from './containers/ArticleContainer';
import ArticleEditContainer from './containers/ArticleEditContainer';
import RedirectionIndex from './containers/RedirectionIndex';
import { isAdmin } from './util/service';
import DashboardContainer from './containers/DashboardContainer';
import ProfileSettingsContainer from './containers/ProfileSettingsContainer';
import VerifyAccount from './components/VerifyAccount';
import Wallet from './components/Wallet';
import WalletSolana from './components/WalletSolana';
import WalletQuicknode from './components/WalletQuicknode';
import DownloadPageContainer from './containers/DownloadPageContainer';
import ForgotPassword from './components/ForgotPassword';
import ForgotPasswordReset from './components/ForgotPasswordReset';

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<RedirectionIndex />} />
                <Route path="/ctqbc/" element={<StartContainer />}>
                    <Route index path="login" element={<Login isPrivate={true}/>} />
                    <Route index path="register" element={<Register isPrivate={true}/>} />
                </Route>

                <Route path="/start/" element={<StartContainer />}>
                    <Route index path="login" element={<Login />} />
                    <Route index path="register" element={<Register />} />
                    <Route index path="verify/:token?" element={<VerifyAccount />} />
                    <Route index path="reset" element={<ForgotPassword />} />
                    <Route index path="reset/:token" element={<ForgotPasswordReset />} />
                    <Route index path="wallet" element={<Wallet />} />
                    <Route index path="wallet/solana" element={<WalletSolana />} />
                    <Route index path="wallet/quicknode" element={<WalletQuicknode />} />
                </Route>

                <Route path="/map/free-navigation" element={<MapContainer />} />
                <Route path="/map/tutorial" element={<MapContainerTutorial />} />
                <Route path="/profile/:userName?" element={<ProfileContainer />} />
                <Route path="/profile/settings" element={<ProfileSettingsContainer type={''}/>} />
                <Route path="/profile/settings/change-password" element={<ProfileSettingsContainer type={'password'} />} />
                <Route path="/profile/settings/account" element={<ProfileSettingsContainer type={'account'} />} />
                <Route path="/news" element={<NewsContainer />} />
                <Route path="/article/:slug?" element={<ArticleContainer />} />

                <Route path="/forum/announcements" element={<ForumContainer type={'ANNOUNCEMENTS'} />} />
                <Route path="/forum/general" element={<ForumContainer type={'GENERAL'} />} />
                <Route path="/forum/support" element={<ForumContainer type={'TECHNICAL'} />} />
                <Route path="/forum/feedback" element={<ForumContainer type={'FEEDBACK'} />} />
                <Route path="/forum/community" element={<ForumContainer type={'COMMUNITY'} />} />
                <Route path="/forum/marketplace" element={<ForumContainer type={'MARKETPLACE'} />} />
                <Route path="/forum/social" element={<ForumContainer type={'SOCIAL'} />} />
                <Route path="/forum/topic/:slug" element={<ForumContainer isTopic={true} />} />
                <Route path="/forum/ask" element={<ForumContainer isAdd={true} />} />
                <Route path="/forum" element={<ForumContainer type={'Forum'} isForumHome={true} />} />

                <Route path="/download" element={<DownloadPageContainer />} />

                <Route path="/ctqbc/article/:slug?" element={<ArticleEditContainer />} />
                <Route path="/ctqbc/create/article/" element={<ArticleEditContainer isCreating={true} />} />

                <Route path="/dashboard" element={<DashboardContainer />} />

            </Routes>
        </BrowserRouter>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
