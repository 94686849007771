import React, { Component } from 'react'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default class Modal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: false
        }

        this.modalRef = React.createRef();
    }

    componentDidMount = () => {
        window.addEventListener('mousedown', this.pageClick, false);
        this.disableScroll()
    }

    componentWillUnmount = () => {
        clearAllBodyScrollLocks();
    }

    disableScroll = () => {
        let body = document.querySelector('#targetElementId');
        disableBodyScroll(body);
    }

    pageClick = (e) => {
        if (this.modalRef && this.modalRef.current && !this.modalRef.current.contains(e.target)) {
            this.closeModal()
        }
    }

    closeModal = () => {
        this.props.closeModal && this.props.closeModal()
    }

    confirmAction = () => {
        this.props.action();
        this.setState({
            isLoading: true
        })
    }

    renderLoadingMessage = () => {
        if (this.state.isLoading)
            return (
                <p>
                    Your request is being processed. 
                    Please wait...
                </p>
            )
        else {
            return (
                <>
                    <p style={{whiteSpace: 'pre-line'}}>
                        {this.props.content}
                    </p>
                    <div className='btns-container'>
                        <div className='btn cancel' onClick={this.closeModal}>
                            Cancel
                        </div>
                        <div className='btn confirm' onClick={this.confirmAction}>
                            Confirm
                        </div>
                    </div>
                </>
            )
        }
    }

    render() {
        const element = this.props.selectedElement;
        return (
            <div className='modal-overlay'>
                <div className='modal-container' ref={this.modalRef}>
                    <h3>{this.props.title}</h3>
                    {this.renderLoadingMessage()}
                </div>
            </div>
        )
    }
}