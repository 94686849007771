import axios from 'axios';
import React, { Component } from 'react'

export default class NewsletterArticle extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: undefined,
            isError: false,
            isSuccess: false
        }
    }

    submitEmail = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidEmail = emailRegex.test(this.state.email);
        if (!isValidEmail) return this.setState({ isError: true });

        let email = this.state.email.toLowerCase()
        axios.post(`https://versemail.azurewebsites.net/newsletter/`, { email: this.state.email }).then(response => {
            this.setState({
                isSuccess: true,
                email: undefined,
                isError: false
            }, () => {
                setTimeout(() => {
                    this.setState({
                        isSuccess: false
                    })
                }, 5000)
            })
        }).catch(error => {
            console.log(error)
            this.setState({ isError: 'Email already exists or server error.' })
        });
    }

    handleInput = (e) => {
        this.setState({
            email: e.target.value,
            isError: false
        })
    }

    renderSuccess = () => {
        return (
            <div className='newsletter-card-article'>
                <h3>
                    Welcome to the Verse Estate Newsletter !
                </h3>
                <h5>By {this.props.author}</h5>
                <p>
                    Thank you for subscribing to our Verse Estate newsletter.
                </p>
            </div>
        )
    }

    render() {
        if (this.state.isSuccess)
            return this.renderSuccess()

        return (
            <div className='newsletter-card-article'>
                <h3>Subscribe to our Newsletter</h3>
                <h5>By {this.props.author}</h5>

                <p>
                    A weekly, ad-free newsletter that helps verse citizens  stay in the know, be productive, and think more critically about the world.
                </p>

                <div className='buttons'>
                    <input type='email' className={(this.state.isError ? 'input error' : 'input')} placeholder='Type in Your Email' value={this.state.email} onChange={this.handleInput} />
                    <div className='btn' onClick={this.submitEmail}>
                        <i class='bx bx-envelope' />
                        <div>
                            Get Newsletter
                        </div>
                    </div>
                </div>

                {
                    this.state.isError &&
                    <p className='error' style={{color: '#FF9696'}}>
                        Error with your input. Please retry with a valid email address.
                    </p>
                }
            </div>
        )
    }
}
