import axios from "axios";

function authHeader() {
    const user = JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user'));

    if (user && user.accessToken) {
        return {'x-access-token': user.accessToken, 'x-entity-token': user?.playfabToken?.EntityToken};
    } else {
        return {};
    }
}

function getCurrentUser() {
    return JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user'));
} 

function isAdmin() {
    const token = getCurrentToken();
    if (!token || token === undefined)
        return false;
    else if (parseJwt(token).isAdmin) {
        return true;
    }
    return false;
} 

const isTokenValid = () => {
    const token = getCurrentToken(),
    playfabToken = getPlayfabToken();

    if (!token || token === undefined)
        return false;
    else if (parseJwt(token).exp < Date.now() / 1000 || (playfabToken?.TokenExpiration && new Date(playfabToken?.TokenExpiration) < new Date())) {
        localStorage.removeItem('user');
        return false;
    }
    return true;
}

const getCurrentToken = () => {
    const user = JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user'));
    if (user && user.accessToken)
        return user.accessToken;
}

const getPlayfabToken = () => {
    const user = JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user'));
    if (user && user.playfabToken)
        return user.playfabToken;
}

const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return {exp: 0};
    }
};

const getEmailFromTokenVerify = (token) => {
    return parseJwt(token)?.email
}

const verifyEmail = (token) => {
    return axios.post('', {token: token}).then(response => {
        if (response.data.accessToken) {
            localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response;
    }).catch(error => {
        return null;
    });
}

const logOut = (withOpen = true) => {
    localStorage.removeItem('user');
    sessionStorage.removeItem('user');
    withOpen && window.open('/start/login', '_self')
}

export {
    authHeader,
    getCurrentUser,
    isTokenValid,
    isAdmin,
    getEmailFromTokenVerify,
    logOut,
    getPlayfabToken
}
