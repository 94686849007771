import React, { Component } from 'react'
import { isTokenValid } from '../util/service'

export default class RedirectionIndex extends Component {
    componentDidMount = () => {
        window.open('/map/free-navigation', '_self')
    }

    render() {
        return null
    }
}
