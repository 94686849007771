import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { getCurrentUser, isTokenValid, logOut } from '../../util/service'

export default class extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isMobile: false,
            isMenuOpen: false,
            isOptionsOpen: false,
        }
    }

    componentDidMount = () => {
        this.windowResized()
        window.addEventListener('resize', this.windowResized);
    }

    windowResized = () => {
        if (window.innerWidth <= 768 && !this.state.isMobile) {
            this.setState({
                isMobile: true
            })
        } else if (window.innerWidth > 768 && this.state.isMobile) {
            this.setState({
                isMobile: false
            })
        }
    }

    openCloseMenu = (value) => {
        this.setState({
            isMenuOpen: value
        })
    }

    openOptionsMenu = (value) => {
        this.setState({
            isOptionsOpen: value
        })
    }

    renderOptionsMenu = () => {
        if (this.state.isOptionsOpen) {
            return (
                <div className='menu-options'> 
                    <div className='top' />
                    <Link to={`/profile/${getCurrentUser()?.userName}`} className='element'>
                        My profile
                    </Link>
                    <Link to={`/profile/settings`} className='element'>
                        Settings
                    </Link>
                    <div className='element' style={{margin: 0}} onClick={logOut}>
                        Logout
                    </div>
                </div>
            )
        }
    }

    renderMobileVersion = () => {
        return (
            <div className='header-container'>
                {this.renderMobileMenu()}
                <div className='mobile-navbar-user'>
                    <Link to={'/map/free-navigation'} className='logo-container'>
                        <div className='logo' />
                    </Link>

                    <div className='mobile-buttons'>
                        {
                            false && this.props.pageTitle === 'VERSE' &&
                            <div className='button svg info' onClick={this.props.toggleInfo}/>
                        }
                        {
                            this.props.isFromMap && this.props.isTutorial ?
                                <Link to={'/map/free-navigation'} className='button svg tutorial' />
                            :
                            this.props.isFromMap &&
                            <Link to={'/map/tutorial'} className='button svg navigation' />
                        }
                        <div className='button hamburger' onClick={() => this.openCloseMenu(!this.state.isMenuOpen)}>
                            <i class={!this.state.isMenuOpen ? 'bx bx-menu' : 'bx bx-x'} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderMobileMenu = () => {
        return (
            <div className={'mobile-menu' + (this.state.isMenuOpen ? ' open' : '')}>
                <div className='options-container'>
                    <div className='top'>
                        <Link to={'/map/free-navigation'} className='element'>
                            <i class='bx bxs-map-alt' />
                            <div>
                                Verse World
                            </div>
                        </Link>

                        {/*<Link to={'/news'} className='element' style={{animationDuration: '.5s'}}>
                            <i class='bx bx-news'></i>
                            <div>
                                News
                            </div>
                        </Link>*/}

                        {/*
                            <Link to={'/forum'} className='element' style={{animationDuration: '.6s'}}>
                                <i class='bx bxs-chat'></i>
                                <div>
                                    Forum
                                </div>
                            </Link>*/
                        }

                        <Link to={'/download'} className='element' style={{animationDuration: '.7s'}}>
                            <i class='bx bx-download'></i>
                            <div>
                                Join The Dreamers
                            </div>
                        </Link>

                        {
                            isTokenValid() ?
                                <Link to={`/profile/${getCurrentUser()?.userName}`} className='element' style={{ animationDuration: '.8s' }}>
                                    <div className='profile' style={{ backgroundImage: `url(${getCurrentUser()?.profilePicture || 'https://verseworldstorage.blob.core.windows.net/verseweb/VerseWorld/empty.jpg'})` }} />
                                    <div>My Profile</div>
                                </Link> :
                                <Link to={'/start/login'} className='element' style={{animationDuration: '.8s'}}>
                                    <i class='bx bxs-user'></i>
                                    <div>
                                        User
                                    </div>
                                </Link>
                        }
                    </div>

                    {
                        isTokenValid() &&
                        <div className='log-out'>
                            <i class='bx bx-log-in'/>
                            <div>
                                Log-out
                            </div>
                        </div>
                    }

                </div>
            </div>
        )
    }

    renderHeader = () => {
        return (
            <div className='header-container'>
                <div className='navbar-user' style={{opacity: this.props.opacity}}>
                    <Link to={'/map/free-navigation'} className='element'>
                        <img src={'/logo.png'} className='logo' />
                    </Link>

                    <Link to={'/map/free-navigation'} className='element'>
                        <div className={'button ' + (this.props.pageTitle === 'VERSE' ? 'active' : '')}>
                            <i class='bx bxs-map-alt' />
                            <div>
                                Verse World
                            </div>
                        </div>
                    </Link>

                    {/*<Link to={'/news'} className='element'>
                        <div className={'button ' + (this.props.pageTitle === 'NEWS' ? 'active' : '')}>
                            <i class='bx bx-news'></i>
                            <div>
                                News
                            </div>
                        </div>
                    </Link>*/}

                    {/*
                        <Link to={'/forum'} className='element'>
                            <div className={'button ' + (this.props.pageTitle === 'FORUM' ? 'active' : '')}>
                                <i class='bx bxs-chat'></i>
                                <div>
                                    Forum
                                </div>
                            </div>
                        </Link>*/
                    }

                    <Link to={'/download'} className='element'>
                        <div className={'button ' + (this.props.pageTitle === 'DOWNLOAD' ? 'active' : '')}>
                            <i class='bx bx-download'></i>
                            <div>
                                Join The Dreamers
                            </div>
                        </div>
                    </Link>

                    {
                        isTokenValid() ?
                        <>
                            <div onClick={() => this.openOptionsMenu(!this.state.isOptionsOpen)} className='element'>
                                <div className={'profile' + (this.props.pageTitle === 'PROFILE' ? ' active' : '')}  style={{ backgroundImage: `url(${getCurrentUser()?.profilePicture || 'https://verseworldstorage.blob.core.windows.net/verseweb/VerseWorld/empty.jpg'})` }} />
                                {this.renderOptionsMenu()}  
                            </div>
                        </> :
                        <>
                            <Link to={'/start/login'} className='element'>
                                <div className='profile active login' style={{backgroundImage: 'url(/login-icon.png)'}}>
                                </div>
                            </Link>
                        </>
                    }
                </div>
            </div>
        )
    }

    handleVersion = () => {
        if (this.state.isMobile)
            return this.renderMobileVersion()
        return this.renderHeader()
    }

    render() {
        return this.handleVersion()
    }
}
