import React, { Component } from 'react'
import Header from './MapUI/Header'
import { Link } from 'react-router-dom'
import Newsletter from './Common/Newsletter'
import NewsletterFull from './Common/NewsletterFull'
import { getDate, scrollTo } from '../util/util'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import NewsArticles from './Common/NewsArticles'
import { isAdmin } from '../util/service'

export default class News extends Component {
    constructor(props) {
        super(props)

        this.state = {
            highlightedArticles: [],
            articles: [],
            movies: [],
            selectedMovie: {},
            stadium: [],
            trading: [],
            index: 0,
            skip: {
                dontMiss: 0,
                stadium: 0,
                trading: 0,
            }
        }
    }

    componentDidMount = () => {
        scrollTo()
    }

    setList = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    startCarousel = (i) => {
        let index = i + 1,
            length = this.state.highlightedArticles?.length

        if (index >= length) {
            index = 0
        }

        this.setState({
            index: index
        })
    }

    selectCarousel = (index) => {
        clearInterval(this.interval)
        this.startCarousel(index)
    }

    renderHeroCarousel = () => {
        let length = this.state.highlightedArticles?.length;

        return (
            <div className='hero-carousel' style={{ transform: `translateX(${-this.state.index / length * 100}%)` }}>
                {
                    this.state.highlightedArticles?.length ?
                        this.state.highlightedArticles.map((article) => {
                            return (
                                <Link to={isAdmin() ? `/ctqbc/article/${article?.slug}` : `/article/${article?.slug}`} className='hero' style={{ backgroundImage: `url(${article?.image})`, opacity: !article?.isPublished && .5 }} >
                                    <div className='overlay' />
                                    <div className='titles-container'>
                                        <h5>{article?.author?.firstName || article?.author} - {getDate(article?.creationDate)}</h5>
                                        <h3>{article?.title}</h3>
                                    </div>
                                </Link>
                            )
                        }) : 
                        <Skeleton height={'100vh'} width={'100vw'} highlightColor={'#ffffff'}/>
                }

                {
                    this.renderHeroNavigater(this.state.highlightedArticles)
                }
            </div>
        )
    }

    renderHeroNavigater = (highlightedArticles) => {
        let length = highlightedArticles?.length;

        if (length)
            return (
                <div className='hero-navigator' style={{ left: (((this.state.index + 1) / length * 100) - 3) + '%', padding: 0 }}>
                    <div className='hero-buttons'>
                        {
                            highlightedArticles.map((article, index) => {
                                return (
                                    <div className={index === this.state.index ? 'selected' : ''} onClick={() => this.selectCarousel(index - 1)} />
                                )
                            })
                        }
                    </div>
                </div>
            )
    }

    renderArticle = (article) => {
        return (
            <Link to={isAdmin() ? `/ctqbc/article/${article?.slug}` : `/article/${article?.slug}`} className='article-container'>
                <div className='image' style={{ backgroundImage: `url(${article?.image})` }} />
                <div className='details'>
                    <p>
                        {article?.author?.firstName || article?.author} - <span>{getDate(article?.creationDate)}</span>
                    </p>
                    <h4>
                        {article?.title}
                    </h4>
                </div>
            </Link>
        )
    }

    renderMovies = () => {
        return (
            <section>
                <div className='articles-list movies'>
                    <div className='title-container'>
                        <h3>
                            Upcoming movies in the city cinema
                        </h3>
                        <div className='border' />
                    </div>

                    <div className='content movie'>
                        <Link to={isAdmin() ? `/ctqbc/article/${this.state.selectedMovie?.slug}` : `/article/${this.state.selectedMovie?.slug}`} className='main-movie' style={{opacity: !this.state.selectedMovie?.isPublished && .5}}>
                            <div className='poster' style={{ backgroundImage: `url(${this.state.selectedMovie?.image})` }} />
                        </Link>

                        <div className='movies-gallery-container'>
                            <div className='other-articles'>
                                {this.renderMoviesNews(this.state.movies)}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='side-options-container'>
                    {this.renderDownloadBook()}
                    {this.renderNewsletter()}
                </div>
            </section>
        )
    }

    renderMoviesNews = (moviesNews) => {
        if (!moviesNews?.length) return;

        return moviesNews.map(news => {
            return this.renderArticle(news)
        })
    }

    renderDownloadBook = () => {
        return (
            <div className='articles-list recent side-options'>
                <div className='title-container navigation'>
                    <div className='border' />

                    <div className='switch'>
                        Download
                    </div>
                </div>

                <div className='content no-flex'>
                    <div className='download-card'>
                        <div className='cover-preview'>
                            <i className='bx bxs-file-pdf'/>
                            <h2>
                                Lorem ipsum dolor sit amet consectetur. Et tempor nec dignissim sed.
                            </h2>
                        </div>

                        <div className='bottom'>
                            <a href='https://www.atlantis-press.com/article/125971939.pdf' target="_blank" className='btn'>
                                <i className='bx bx-show-alt' />
                                <p>View Document</p>
                            </a>

                            <Link href='https://www.atlantis-press.com/article/125971939.pdf' download='test.pdf' target="_blank"
                                rel="noreferrer" className='btn transparent'>
                                <i className='bx bx-download' />
                                <p>Download</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderNewsletter = () => {
        return 
        return (
            <div className='articles-list recent side-options'>
                <div className='title-container navigation'>
                    <div className='border' />

                    <div className='switch'>
                        Subscribe to our Newsletter
                    </div>
                </div>

                <div className='content'>
                    <Newsletter />
                </div>
            </div>
        )
    }

    handleUnrenderedComponents = () => {
        // unrendered components, only for fetching data
        return (
            <>
                <NewsArticles type={1} setList={this.setList}/>
                <NewsArticles type={3.1} setList={this.setList}/>
                <NewsArticles type={3} setList={this.setList}/>
            </>
        )
    }

    render() {
        return (
            <div className='news-page'>
                <Header pageTitle={'NEWS'} />
                {this.handleUnrenderedComponents()}
                {this.renderHeroCarousel()}
                <NewsArticles title = "Don't miss" isWithRecent={true} type={2} />
                {this.renderMovies()}
                <NewsArticles title = "The stadium news" isWithRecent={false} type={4} />
                <NewsArticles title = "trading center" isWithRecent={false} type={5} />
                <NewsletterFull />
            </div>
        )
    }
}
