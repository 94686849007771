import axios from "axios";
import React, { Component } from "react";

export default class NewsletterFull extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: undefined,
            isError: false,
            errorMessage: "",
        };
    }

    submitEmail = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidEmail = emailRegex.test(this.state.email);
        if (!isValidEmail)
            return this.setState({ isError: "Please enter a valid email" });
        axios
            .post(`https://versemail.azurewebsites.net/newsletter/`, {
                email: this.state.email,
            })
            .then((response) => {
                this.setState(
                    {
                        isSuccess: true,
                        email: undefined,
                        isError: false,
                    },
                    () => {
                        setTimeout(() => {
                            this.setState({
                                isSuccess: false,
                            });
                        }, 5000);
                    }
                );
            })
            .catch((error) => {
                console.log(error);
                this.setState({ isError: "Email already exists or server error." });
            });
    };

    handleInput = (e) => {
        this.setState({
            email: e.target.value,
            isError: false,
        });
    };

    renderSuccess = () => {
        return (
            <div className="content">
                <h3>Welcome to the Verse Estate Newsletter !</h3>
                <p>Thank you for subscribing to our Verse Estate newsletter.</p>
            </div>
        );
    };

    switchRender = () => {
        if (this.state.isSuccess) return this.renderSuccess();
        else
            return (
                <div className="content">
                    <h3>Sign up for Verse Estate Newsletter</h3>
                    <p>
                        A weekly, ad-free newsletter that helps verse citizens stay in the
                        know, be productive, and think more critically about the world.
                    </p>
                    <input
                        type={"email"}
                        placeholder="Type in your email"
                        className={this.state.isError ? " error" : ""}
                        value={this.state.email}
                        onChange={this.handleInput}
                    />

                    <div className="submit-btn" onClick={this.submitEmail}>
                        <i class="bx bx-envelope" />
                        <p>Get this Newsletter</p>
                    </div>

                    {this.state.isError && <p className="error">{this.state.isError}</p>}
                </div>
            );
    };

    render() {
        return (
            <div className="newsletter-card-full">
                <div className="illustration-container">
                    <img src="../newsletter.svg" />
                </div>
                <div className="content-container">{this.switchRender()}</div>
            </div>
        );
    }
}
