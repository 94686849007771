import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getPlayfabId, multipleIsEmpty, scrollTo } from "../util/util";
import axios from "axios";
import { logOut } from "../util/service";

export default class Register extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageType: "LOGIN",
            firstName: "",
            lastName: "",
            login: "",
            password: "",
            isPwdHidden: true,
            isPwdSimilar: true,
            isChecked: false,
            isSubmitted: false,
            isMissingData: false,
            validationRules: {
                firstName: {
                    errorMessage: "",
                    validate: () => this.state.firstName?.length > 20,
                },

                username: {
                    errorMessage: "",
                    validate: () => this.state.username?.length > 20,
                },

                password: {
                    errorMessage: "",
                    validate: () => {
                        if (!this.state.password.length) return false;

                        return (
                            this.state.validationRules.password.errorMessage.length !== 0
                        );
                    },
                },
                email: {
                    errorMessage: "",
                    validate: () =>
                        this.state.validationRules.email.errorMessage.length !== 0,
                },
                birthDay: {
                    errorMessage: "Please enter a valid birth date.",
                    validate: () => {
                        const enteredDate = new Date(this.state.birthDay);
                        const today = new Date();
                        return today < enteredDate;
                    },
                },
                repeatedPassword: {
                    errorMessage: "Passwords are not matching.",
                    validate: () =>
                        this.state?.repeatedPassword &&
                        this.state.password !== this.state.repeatedPassword,
                },
            },
        };
    }

    handleRegister = (e) => {
        e.preventDefault();

        // scrollTo(0);
        const contentSection = document.querySelector(".content");
        if (contentSection) {
            contentSection.scrollTo({
                top: 0,
                scrollBehaviour: "smooth",
            });
        }

        let isMissingData = multipleIsEmpty([
            this.state.firstName,
            this.state.email,
            this.state.username,
            this.state.password,
        ]);
        let isPasswordLength =
            this.state.password?.length >= 6 && this.state.password?.length <= 100;
        this.setState({
            isSubmitted: true,
            isMissingData: isMissingData
                ? "Please check the missing details."
                : undefined,
            isError: false,
            isPwdSimilar: true,
        });

        if (isMissingData) return;

        if (!isPasswordLength)
            return this.setState({
                isMissingData:
                    "Your password length should be at the minimum 6 characters.",
            });

        if (this.state.password !== this.state.repeatedPassword) {
            this.setState({
                isMissingData: "Passwords do not match",
            });
            return;
        }

        this.registerAction(getPlayfabId());
    };

    registerAction = (playfabEnv) => {
        // we logout current user in case
        logOut(false);

        let body = {
            playfabData: {
                Email: this.state.email,
                Password: this.state.password,
                Username: this.state.username,
            },
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            birthDay: this.state.birthDay,
        };

        axios
            .post(`https://versemail.azurewebsites.net/playfab/register`, { ...body })
            .then((response) => {
                if (response?.data?.token) {
                    window.open("/start/verify/" + response?.data?.token, "_self");
                }
            })
            .catch((error) => {
                const responseData = error.response?.data;
                this.setState({
                    isMissingData:
                        responseData?.error.errorDetails.Username ||
                        responseData?.error.errorDetails.Password ||
                        responseData?.error.errorDetails.Email,
                });
            });
    };

    renderMissingDetails = () => {
        if (this.state.isMissingData) {
            return <div className="status-bar alert">{this.state.isMissingData}</div>;
        }
    };

    handleSwitch = (type) => {
        this.setState({
            pageType: type,
        });
    };

    validateEmail = (email) => {
        const isValidEmail = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(
            email
        );
        return isValidEmail;
    };

    hanndleBlur = (e) => {
        const { name, value } = e.target;

        if (name === "email") {
            const isValidEmail = this.validateEmail(value);

            let errorMessage = "";
            if (!isValidEmail) {
                errorMessage = "Please enter a valid email address.";
            }
            this.setState((prevState) => ({
                validationRules: {
                    ...prevState.validationRules,
                    email: {
                        ...prevState.validationRules.email,
                        errorMessage: errorMessage,
                    },
                },
            }));
        }
        if (name === "birthDay") {
            if (this.state.validationRules.birthDay.validate()) {
                this.setState({ birthDay: "" });
            }
        }
    };

    handleInput = (e) => {
        if (e.target.value?.length > 150) return;

        if (e.target.name === "firstName") {
            const errorMessage =
                e.target.value?.length > 20
                    ? "Please enter a first name with less than 20 characters."
                    : "";
            this.setState((prevState) => ({
                validationRules: {
                    ...prevState.validationRules,
                    [e.target.name]: {
                        ...prevState.validationRules[e.target.name],
                        errorMessage: errorMessage,
                    },
                },
            }));
            if (errorMessage) return;
        }
        if (e.target.name === "username") {
            const errorMessage =
                e.target.value?.length < 3
                    ? "Please enter a username with more than 2 characters."
                    : e.target.value?.length > 20
                        ? "Please enter a username with less than 20 characters."
                        : "";
            this.setState((prevState) => ({
                validationRules: {
                    ...prevState.validationRules,
                    [e.target.name]: {
                        ...prevState.validationRules[e.target.name],
                        errorMessage: errorMessage,
                    },
                },
            }));
            if (e.target.value?.length > 20) return;
        }

        if (e.target.name === "email") {
            if (this.validateEmail(e.target.value)) {
                this.setState((prevState) => ({
                    validationRules: {
                        ...prevState.validationRules,
                        email: {
                            ...prevState.validationRules.email,
                            errorMessage: "",
                        },
                    },
                }));
            }
        }
        if (e.target.name === "password") {
            let errorMessage = "";
            if (e.target.value.length > 100) {
                this.setState((prevState) => ({
                    validationRules: {
                        ...prevState.validationRules,
                        password: {
                            ...prevState.validationRules.password,
                            errorMessage:
                                "Your password length should be at the maximum 100 characters.",
                        },
                    },
                }));
                return;
            }
            if (e.target.value.length < 6) {
                errorMessage =
                    "Your password length should be at the minimum 6 characters.";
            }
            this.setState((prevState) => ({
                validationRules: {
                    ...prevState.validationRules,
                    password: {
                        ...prevState.validationRules.password,
                        errorMessage: errorMessage,
                    },
                },
            }));
        }
        if (e.target.name === "repeatedPassword" && e.target.value.length > 100) {
            return;
        }

        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    handleRepeatPasswordInput = (e) => {
        this.setState({
            isPwdSimilar: e.target.value === this.state.password,
        });
    };

    toggleHiddenPwd = () => {
        this.setState({
            isPwdHidden: !this.state.isPwdHidden,
        });
    };

    handleRememberMe = () => {
        this.setState({
            isChecked: !this.state.isChecked,
        });
    };

    renderRegistrationForm = () => {
        return (
            <form className="form register" onSubmit={this.handleRegister} noValidate>
                <div className="input-container">
                    <label>First name *</label>
                    <input
                        type="text"
                        placeholder="Enter your First name"
                        name="firstName"
                        onChange={this.handleInput}
                        value={this.state.firstName}
                        required
                    />
                    <span> {this.state.validationRules.firstName.errorMessage}</span>
                </div>

                <div className="input-container">
                    <label>Last name</label>
                    <input
                        type="text"
                        placeholder="Enter your Last name"
                        name="lastName"
                        onChange={this.handleInput}
                        value={this.state.lastName}
                    />
                </div>

                <div className="input-container">
                    <label>Email Address *</label>
                    <input
                        className={`${this.state.validationRules.email.validate() ? "invalid-input" : ""
                            }`}
                        type="email"
                        placeholder="Enter your Email Address"
                        name="email"
                        onChange={this.handleInput}
                        onBlur={this.hanndleBlur}
                        value={this.state.email}
                        required
                    />
                    <span> {this.state.validationRules.email.errorMessage}</span>
                </div>

                <div className="input-container">
                    <label style={{ margin: 0 }}>Username *</label>
                    <label style={{ fontSize: "12px" }}>
                        (Will be used as your DisplayName also)
                    </label>
                    <input
                        className={`${this.state.validationRules.username.validate()
                                ? "invalid-input"
                                : ""
                            }`}
                        type="text"
                        placeholder="Enter your Username"
                        name="username"
                        onChange={this.handleInput}
                        value={this.state.username}
                        required
                    />

                    <span> {this.state.validationRules.username.errorMessage}</span>
                </div>

                <div className="input-container">
                    <label>Date of birth</label>
                    <input
                        className={`${this.state.validationRules.birthDay.validate()
                                ? "invalid-input"
                                : ""
                            }`}
                        type="date"
                        placeholder="Enter your Date of birth"
                        name="birthDay"
                        onChange={this.handleInput}
                        onBlur={this.hanndleBlur}
                        value={this.state.birthDay}
                    />
                    {this.state.validationRules.birthDay.validate() && (
                        <span> {this.state.validationRules.birthDay.errorMessage}</span>
                    )}
                </div>

                <div className="input-container">
                    <label style={{ margin: 0 }}>Password *</label>
                    <label style={{ fontSize: "12px" }}>
                        (Must be between 6 and 100 characters)
                    </label>
                    <div className="password-container">
                        <input
                            className={`${this.state.validationRules.password.validate()
                                    ? "invalid-input"
                                    : ""
                                }`}
                            type={this.state.isPwdHidden ? "password" : "text"}
                            placeholder="Enter your Password"
                            name="password"
                            onChange={this.handleInput}
                            value={this.state.password}
                            required
                        />

                        <i
                            class={this.state.isPwdHidden ? "bx bxs-hide" : "bx bxs-show"}
                            onClick={this.toggleHiddenPwd}
                        />
                        <span> {this.state.validationRules.password.errorMessage}</span>
                    </div>
                </div>

                <div className="input-container">
                    <label>Repeat password *</label>
                    <div className="password-container">
                        <input
                            className={`${this.state.validationRules.repeatedPassword.validate()
                                    ? "invalid-input"
                                    : ""
                                }`}
                            type={this.state.isPwdHidden ? "password" : "text"}
                            placeholder="Enter your Password"
                            name="repeatedPassword"
                            onChange={this.handleInput}
                            value={this.state.repeatedPassword}
                            required
                        />
                        <i
                            class={this.state.isPwdHidden ? "bx bxs-hide" : "bx bxs-show"}
                            onClick={this.toggleHiddenPwd}
                        />
                        {this.state.validationRules.repeatedPassword.validate() && (
                            <span>
                                {" "}
                                {this.state.validationRules.repeatedPassword.errorMessage}
                            </span>
                        )}
                    </div>

                    {/* {!this.state.isPwdSimilar ? (
            <label>Passwords are not matching.</label>
          ) : (
            <label></label>
          )} */}
                </div>

                <div className="login-btn-container">
                    <input type="submit" className="login-btn" value="Register" />
                </div>
            </form>
        );
    };

    render() {
        return (
            <div className="login">
                <h4>Welcome to Verse Estate..!</h4>
                <div className="switcher">
                    <Link
                        to={this.props.isPrivate ? "/ctqbc/login" : "/start/login"}
                        onClick={() => this.handleSwitch("LOGIN")}
                    >
                        Login
                    </Link>
                    <div className="active" onClick={() => this.handleSwitch("REGISTER")}>
                        Register
                    </div>
                </div>

                <div className="text-explainer">
                    Create an account to be able to fully experience the Verse World.
                    Enter the needed details (*) and proceed.
                </div>

                {this.renderMissingDetails()}
                {this.renderRegistrationForm()}
            </div>
        );
    }
}
