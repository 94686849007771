import React, { Component } from 'react'
import SearchComponent from './SearchComponent'

export default class ForumHero extends Component {
    render() {
        return (
            <div className='forum-hero'>
                <SearchComponent {...this.props}/>
                <img src={this.props.background} className='background' />
            </div>
        )
    }
}
