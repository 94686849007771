import React, { Component } from 'react'
import { Outlet, Link } from "react-router-dom";
import Map3D from '../components/Map3D';
import MapUI from '../components/MapUI';
import { useState } from "react";
import mapProperties from '../util/MapProperties';

export default function MapContainer() {
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [selectedNavigationItem, setselectedNavigationItem] = useState([]);
    const [properties, setProperties] = useState(mapProperties)

    const [navigationPos, setNavigationPos] = useState({x: 0, z: 0});
    const [isCameraMoving, setCameraMoving] = useState();
    const [isModelLoaded, setIsModelLoaded] = useState(false);
    
    const buyProperty = (id) => {
        if (!properties?.length) return

        let results = properties.map((property, index) => {
            if (property?._id === id) {
                property.owner = '649226fea5f22c6f9ee5b054' // temp
            }

            return property
        })

        let property = selectedProperty;
        property.owner = '649226fea5f22c6f9ee5b054' // temp
        
        setProperties(results)
        setSelectedProperty(property)
    }

    const navigate = (x, z) => {
        setNavigationPos({x, z})
    }

    return (
        <>
            <div className='map-container'>
                <MapUI 
                    selectedProperty={selectedProperty} 
                    setSelectedProperty={setSelectedProperty} 
                    setSelectedTypes={setSelectedTypes} 
                    selectedTypes={selectedTypes}
                    buyProperty={buyProperty}
                    properties={properties}
                    selectedNavigationItem={selectedNavigationItem}
                    setselectedNavigationItem={setselectedNavigationItem}
                    navigate={navigate}
                    isCameraMoving={isCameraMoving}
                    isModelLoaded={isModelLoaded}
                />
                <Map3D 
                    setSelectedProperty={setSelectedProperty} 
                    selectedProperty={selectedProperty} 
                    selectedTypes={selectedTypes} 
                    properties={properties}
                    selectedNavigationItem={selectedNavigationItem}
                    navigationPos={navigationPos}
                    setCameraMoving={setCameraMoving}
                    setIsModelLoaded={setIsModelLoaded}
                    setSelectedTypes={setSelectedTypes}
                    isCameraMoving={isCameraMoving}
                />
            </div>
        </>
    )
}