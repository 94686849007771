import React from 'react'
import Footer from '../components/Common/Footer'
import ProfileSettings from '../components/ProfileSettings'

export default function ProfileSettingsContainer(props) {
    return (
        <>
            <ProfileSettings {...props} />
            <Footer />
        </>
    )
}
