const tutorialStepsData = [
    {
        icon: 'https://res.cloudinary.com/dg91u71x5/image/upload/v1689295262/the_city_icon_cy1inz.svg',
        displayName: 'The City',
        description: 'Welcome to the place where all the buildings are.',
        _3DName: '',
        position: {
            "x": -219.20172094931806,
            "y": 145.986515919761,
            "z": 91.88594753281384,
            "isEuler": true,
            "_x": -1.8643011027221361,
            "_y": -0.9750157952448082,
            "_z": -1.920898311680398,
            "_order": "XYZ"
        },
        image: 'https://images.pexels.com/photos/290595/pexels-photo-290595.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
    },
    {
        displayName: 'City hub',
        _3DName: 'CityCenter',
        description: `Players can travel to the city hub and start
        discovering the whole city.`,
        position: {
            "x": -36.37842169936022,
            "y": 39.36128502797088,
            "z": 140.5819826723564,
            "isEuler": true,
            "_x": -1.738553119371143,
            "_y": -1.2685654196340796,
            "_z": -1.7463586407837997,
            "_order": "XYZ"
        },
        image: 'https://i.pinimg.com/originals/68/c9/0a/68c90af78e7beba29eec0ed5aa9e120b.jpg'
    },
    {
        displayName: 'City skyscrapers',
        _3DName: [
            'Building000', 'Building001', 'Building002',
            'Building003', 'Building004', 'Building005',
            'Building006', 'Building007', 'Building008',
            'Building009', 'Building010', 'Building011',
            'Building012', 'Building013', 'Building014',
            'Building015', 'Building016', 'Building017',
            'Building018', 'Building019', 'Building020',
            'Building021', 'Building023', 'Building024',
            'Building053', 'Building054', 'Building055',
            'Building056', 'Building057', 'Building058',
            'Building059', 'Building060', 'Building061',
            'Building062', 'Building080', 'Building081'
        ],
        description: `Characterized by their height, businesses and
        luxury residential are available in the
        skyscrapers.`,
        position: {
            "x": -154.42468708555728,
            "y": 141.7165400103083,
            "z": 95.93099521003731,
            "isEuler": true,
            "_x": -1.8529902939998777,
            "_y": -0.9184205929422388,
            "_z": -1.920644832426325,
            "_order": "XYZ"
        },
        image: 'https://images.pexels.com/photos/618079/pexels-photo-618079.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
    },
    {
        displayName: 'Trading center',
        _3DName: 'TradingCenter',
        description: `The place where users can showcase and
        exhibit their NFTs, or claim for sales in the
        marketplace.`,
        position: {
            "x": -61.26794887434969,
            "y": 15.427270765231924,
            "z": 119,
            "isEuler": true,
            "_x": -2.5109444238563707,
            "_y": -1.2919517598160057,
            "_z": -2.529587997711846,
            "_order": "XYZ"
        },
        image: 'https://ychef.files.bbci.co.uk/976x549/p0202jwh.jpg',
    },
    {
        displayName: 'Semi collective buildings',
        _3DName: [
            "Building022", 'Building025', 'Building026', 'Building027', 'Building028',
            'Building029', 'Building030', 'Building031', 'Building032',
            'Building033', 'Building034', 'Building035', 'Building036',
            'Building037', 'Building038', 'Building039', 'Building040',
            'Building041', 'Building042', 'Building043', 'Building044',
            'Building045', 'Building046', 'Building047', 'Building048',
            'Building049', 'Building050', 'Building051', 'Building064',
            'Building065', 'Building066', 'Building067', 'Building068',
            'Building069', 'Building070', 'Building071', 'Building072',
            'Building073', 'Building074', 'Building075', 'Building076',
            'Building077', 'Building078', 'Building079', 'Building081', 'Building082',
            'Building083', 'Building084', 'Building085', 'Building086',
            'Building087', 'Building088', 'Building089', 'Building090',
            'Building091', 'Building092', 'Building093', 'Building094',
            'Building095', 'Building096', 'Building097', 'Building098',
        ],
        description: `Shafts and duplexes with views of the urban
        area or the beach, offer a compromise
        between the privacy of individual homes and
        the communal aspects of larger housing
        complexes.`,
        position: {
            "x": -193.0842431683987,
            "y": 141.7165400103083,
            "z": 90.32835622828452,
            "isEuler": true,
            "_x": -1.8529902939998777,
            "_y": -0.9184205929422388,
            "_z": -1.920644832426325,
            "_order": "XYZ"
        },
        image: 'https://images.pexels.com/photos/417192/pexels-photo-417192.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
    },
    {
        displayName: 'City cinema',
        _3DName: 'Cinema',
        description: `The perfect place where you can watch your
        favorite movies solo, in public, or with your
        friends in VIP suits.`,
        
        position: {
            "x": -97.0419450374074,
            "y": 24.46839305503632,
            "z": 112.5,
            "isEuler": true,
            "_x": -2.1138244414025333,
            "_y": -1.1968483724181054,
            "_z": -2.146017678448782,
            "_order": "XYZ"
        },
        image: 'https://mediakwest.com/wp-content/uploads/2019/07/1_Beaugrenelle.ONYX-c-Fr%C3%A9d%C3%A9ric-Berthet.HD_.006.jpg',
    },
    {
        displayName: 'Beach houses',
        _3DName: [
            'BeachHouse000', 'BeachHouse001',
            'BeachHouse002', 'BeachHouse003',
            'BeachHouse004', 'BeachHouse005',
            'BeachHouse006', 'BeachHouse007',
            'BeachHouse008', 'BeachHouse009',
            'BeachHouse010', 'BeachHouse011',
            'BeachHouse012'
        ],
        description: `Designed for individuals who want to enjoy
        the proximity and beauty of the beach, beach
        houses offer a unique lifestyle characterized
        by stunning ocean views.`,
        position: {
            "x": -78.7897885928697,
            "y": 334.4700234162379,
            "z": 120.16591261269625,
            "isEuler": true,
            "_x": -1.6144429039263113,
            "_y": -0.20902174372418325,
            "_z": -1.7782439354240265,
            "_order": "XYZ"
        },
        image: 'https://images.pexels.com/photos/593171/pexels-photo-593171.jpeg?auto=compress&cs=tinysrgb&w=600'
    },
    {
        displayName: 'Suburban villas',
        _3DName: [
            'SuburbVilla000',
            'SuburbVilla001',
            'SuburbVilla002',
            'SuburbVilla003',
            'SuburbVilla004',
            'SuburbVilla005',
            'SuburbVilla006',
            'SuburbVilla007',
            'SuburbVilla008',
            'SuburbVilla009',
            'SuburbVilla010',
            'SuburbVilla011'
        ],
        description: `located in suburban areas, and characterized
        by a blend of urban and rural qualities,
        Suburban villas provide the space and
        tranquility of suburban living while still
        offering convenient access to urban amenities
        and services.`,
        position: {
            "x": -199.25604393050293,
            "y": 139.36504060321295,
            "z": 90.11967990512085,
            "isEuler": true,
            "_x": -1.8093641864714107,
            "_y": -0.8943233203056263,
            "_z": -1.873116005965463,
            "_order": "XYZ"
        },
        image: 'https://images.pexels.com/photos/1438834/pexels-photo-1438834.jpeg?auto=compress&cs=tinysrgb&w=600'
    },


    {
        displayName: 'The stadium',
        description: `In the Stadium, Verse users can participate in
        virtual events and invite their friends to be a
        part of event shows.`,
        icon: 'https://res.cloudinary.com/dg91u71x5/image/upload/v1689298394/stadium_icon_cmk8md.svg',
        _3DName: 'Stadium_01',
        position: {
            "x": -225.25949564972598,
            "y": 117.39507755641654,
            "z": -8.355405739492888,
            "isEuler": true,
            "_x": -1.8569677878334785,
            "_y": -0.9399366291070522,
            "_z": -1.9202279542751115,
            "_order": "XYZ"
        },
        image: 'https://www.verizon.com/about/sites/default/files/2022-05/stadium-1230x690.jpg',
    },
    {
        displayName: 'Hill houses',
        description: `Situated near the hills, these houses are
        designed to take advantage of the natural
        landscape and offer stunning views of the
        surrounding or cityscape.`,
        _3DName: [
            'Buildings_part064',
            'Buildings_part065',
            'Buildings_part066',
            'Buildings_part067',
            'Buildings_part068',
            'Buildings_part069',
            'Buildings_part070',
            'Buildings_part071',
            'Buildings_part072',
            'Buildings_part073',
            'Buildings_part074',
            'Buildings_part075',
            'Buildings_part076',
            'Buildings_part077',
            'Buildings_part078',
            'Buildings_part079'
        ],
        position: {
            "x": -111.16297932816127,
            "y": 121.82959695138719,
            "z": 38.937879085227976,
            "isEuler": true,
            "_x": -1.484738849622335,
            "_y": -0.3091551078745408,
            "_z": -1.2945007101407129,
            "_order": "XYZ"
        },
        image: 'https://images.pexels.com/photos/1732414/pexels-photo-1732414.jpeg?auto=compress&cs=tinysrgb&w=600'
    },


    {
        displayName: 'The Amusement park',
        icon: 'https://res.cloudinary.com/dg91u71x5/image/upload/v1689298402/amusement-park_icon_qiweom.svg',
        description: `Situated near the hills, these houses are
        designed to take advantage of the natural
        landscape and offer stunning views of the
        surrounding or cityscape.`,
        _3DName: [
            'AmusementPark', 'Park', 'ParkWheel'
        ],
        position: {
            "x": -86.96348180624618,
            "y": 94.54531296935119,
            "z": -46.7104531293115,
            "isEuler": true,
            "_x": -1.4231641195065117,
            "_y": -0.6223909124646205,
            "_z": -1.3210296461042996,
            "_order": "XYZ"
        },
        image: 'https://waldameer.com/wp-content/uploads/2019/03/img_ferris-wheel-3.jpg',
    },
    {
        displayName: 'The Amusement park',
        icon: 'https://res.cloudinary.com/dg91u71x5/image/upload/v1689298402/amusement-park_icon_qiweom.svg',
        description: `Situated near the hills, these houses are
        designed to take advantage of the natural
        landscape and offer stunning views of the
        surrounding or cityscape.`,
        _3DName: [
            'AmusementPark', 'Park', 'ParkWheel'
        ],
        position: {
            "x": -92.6985603772551,
            "y": 30.948722332274194,
            "z": -76.46094211566165,
            "isEuler": true,
            "_x": -2.3327390730438218,
            "_y": -1.1989733606804678,
            "_z": -2.368119470172855,
            "_order": "XYZ"
        },
        isContinuous: true,
    },
    {
        displayName: 'The Canyons',
        description: `With their distinctive beauty and natural
        wonders, adventurers, explorers, and players
        will do their best to explore the secret inside
        the canyon mountains to earn and enjoy the
        exploration experience`,
        icon: 'https://res.cloudinary.com/dg91u71x5/image/upload/v1689298114/mountain_i6ogp1.svg',
        position: {
            "x": 83.81599075673546,
            "y": 340.0476660902867,
            "z": 93.12713914449468,
            "isEuler": true,
            "_x": -1.5931821511175395,
            "_y": -0.06359734700844077,
            "_z": -1.9095091483979154,
            "_order": "XYZ"
        },
        image: 'https://images.pexels.com/photos/950210/pexels-photo-950210.jpeg?auto=compress&cs=tinysrgb&w=600'
    },
    {
        displayName: 'Space hub',
        description: `This is the place where the spaceship touches
        down on the verse world, Players can take the
        spaceship to teleport from the first Verse planet to
        another in verse world.`,
        _3DName: ['SpaceShip', 'Buildings_part236'],
        position: {
            "x": 63.9965133533943,
            "y": 177.21472825486046,
            "z": 75.44097499400036,
            "isEuler": true,
            "_x": -1.8696450034092047,
            "_y": -0.12280320529236566,
            "_z": -2.7631455230652695,
            "_order": "XYZ"
        },
        image: 'https://images.pexels.com/photos/796206/pexels-photo-796206.jpeg?auto=compress&cs=tinysrgb&w=600'
    },
    {
        displayName: 'Space hub',
        description: `This is the place where the spaceship touches
        down on the verse world, Players can take the
        spaceship to teleport from the first Verse planet to
        another in verse world.`,
        _3DName: ['SpaceShip', 'Buildings_part236'],
        position: {
            "x": 55.492129535275126,
            "y": 56.353889270380236,
            "z": 55.15445526166464,
            "isEuler": true,
            "_x": -2.571676423900517,
            "_y": -0.33930397357916714,
            "_z": -2.931446960903519,
            "_order": "XYZ"
        },
        isContinuous: true,
    },
    {
        displayName: 'Central hub',
        description: `where players will spawn, and start their journey
        by enjoying a panoramic view open to all this
        world.`,
        _3DName: 'Station',
        position: {
            "x": 18.434240314255298,
            "y": 18.121107579436153,
            "z": 107.46873545462955,
            "isEuler": true,
            "_x": -2.8279317341784806,
            "_y": -1.0913950958043257,
            "_z": -2.8613628576905605,
            "_order": "XYZ"
        },
        image: 'https://images.pexels.com/photos/425246/pexels-photo-425246.jpeg?auto=compress&cs=tinysrgb&w=600'
    },
    {
        displayName: 'Cliff houses',
        description: `These unique homes offer breathtaking views
        and a close connection to nature.
        Their unique locations and stunning views
        make them highly sought-after properties for
        those who value serenity, and nature.`,
        _3DName: [
            'Buildings_part170', 'Buildings_part171',
            'Buildings_part172', 'Buildings_part173',
            'Buildings_part174', 'Buildings_part175',
            'Buildings_part176', 'Buildings_part177',
            'Buildings_part178', 'Buildings_part179',
            'Buildings_part180', 'Buildings_part181',
            'Buildings_part182', 'Buildings_part183',
            'Buildings_part184', 'Buildings_part185',
            'Buildings_part186', 'Buildings_part187',
            'Buildings_part188', 'Buildings_part189',
            'Buildings_part190', 'Buildings_part191'
        ],
        position: {
            "x": -3.2511286566761015,
            "y": 17.143287905042268,
            "z": 87.80995086669353,
            "isEuler": true,
            "_x": -2.5849207523398965,
            "_y": -1.2320201930769157,
            "_z": -2.6108229762887465,
            "_order": "XYZ"
        },
        image: 'https://images.pexels.com/photos/1732414/pexels-photo-1732414.jpeg?auto=compress&cs=tinysrgb&w=600'
    },
    {
        displayName: 'Cliff houses',
        description: `These unique homes offer breathtaking views
        and a close connection to nature.
        Their unique locations and stunning views
        make them highly sought-after properties for
        those who value serenity, and nature.`,
        _3DName: [
            'Buildings_part170', 'Buildings_part171',
            'Buildings_part172', 'Buildings_part173',
            'Buildings_part174', 'Buildings_part175',
            'Buildings_part176', 'Buildings_part177',
            'Buildings_part178', 'Buildings_part179',
            'Buildings_part180', 'Buildings_part181',
            'Buildings_part182', 'Buildings_part183',
            'Buildings_part184', 'Buildings_part185',
            'Buildings_part186', 'Buildings_part187',
            'Buildings_part188', 'Buildings_part189',
            'Buildings_part190', 'Buildings_part191'
        ],
        position:
        {
            "x": -44.414439152926334,
            "y": 27.197087521225004,
            "z": 81.81936327692704,
            "isEuler": true,
            "_x": -2.3102901261089404,
            "_y": -1.303039674848026,
            "_z": -2.3283816149718426,
            "_order": "XYZ"
        },
        isContinuous: true,
    },
   /* {
        displayName: 'The zoo park',
        description: `The Zoo Park represents the opportunity for
        players to connect with the natural world, and
        gain a greater appreciation for wildlife.`,
        icon: 'https://res.cloudinary.com/dg91u71x5/image/upload/v1689298412/zoo_icon_iv0abp.svg',
        position: {
            "x": -31.167275181182546,
            "y": 135.04221560779445,
            "z": -78.55131291358639,
            "isEuler": true,
            "_x": -2.1330107593515475,
            "_y": -0.6656718902405757,
            "_z": -2.3661738803186934,
            "_order": "XYZ"
        }
    },*/
]

export default tutorialStepsData