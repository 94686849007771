import React, { useEffect } from 'react'
import { Connection, GetProgramAccountsFilter, PublicKey } from "@solana/web3.js";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";

export default function WalletQuicknode() {
    useEffect(() => {
        let rpcEndpoint = 'https://soft-dawn-reel.solana-mainnet.quiknode.pro/cb957a6fed43fc8d32a6e0a118e4b1f90914c920/';
        rpcEndpoint = 'https://soft-dawn-reel.solana-mainnet.quiknode.pro/cb957a6fed43fc8d32a6e0a118e4b1f90914c920'
        const solanaConnection = new Connection(rpcEndpoint);

        const walletToQuery = '3nhmsoT6w5vGt6NaPzSRJPCuz7S2q2Pit5UAEXeHLZT4'; //example: vines1vzrYbzLMRdu58ou5XTby4qAqVRLmqo36NKPTg

        async function getTokenAccounts(wallet, solanaConnection) {
            try {
                
                console.log(wallet)
                console.log(solanaConnection)

                const accounts = await solanaConnection.getTokenAccountsByOwner(
                    TOKEN_PROGRAM_ID, // new PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA")
                    {
                      filters: [
                        {
                          dataSize: 165, // number of bytes
                        },
                        {
                          memcmp: {
                            offset: 32, // number of bytes
                            bytes: wallet, // base58 encoded string
                          },
                        },
                      ],
                    }
                  );
                console.log(`Found ${accounts.length} token account(s) for wallet ${wallet}.`);
                accounts.forEach((account, i) => {
                    //Parse the account data
                    const parsedAccountInfo = account.account.data;
                    const mintAddress = parsedAccountInfo["parsed"]["info"]["mint"];
                    const tokenBalance = parsedAccountInfo["parsed"]["info"]["tokenAmount"]["uiAmount"];
                    //Log results
                    /*console.log(`Token Account No. ${i + 1}: ${account.pubkey.toString()}`);
                    console.log(`--Token Mint: ${mintAddress}`);
                    console.log(`--Token Balance: ${tokenBalance}`);*/

                    console.log(parsedAccountInfo)
                });
                
            } catch (error) {
                console.log(error)
            }
        }
        
        getTokenAccounts(walletToQuery, solanaConnection);
        
    }, [])


    return (
        <div>WalletQuicknode</div>
    )
}
