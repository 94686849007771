import React, { useEffect } from "react";
import { isTokenValid } from "../util/service";
import { Outlet, Link, useParams } from "react-router-dom";

export default function StartContainer() {
    useEffect(() => {
        if (isTokenValid()) {
            window.open("/map/free-navigation", "_self");
        }
    }, []);

    return (
        <>
            <div className="login-container">
                <div className="panel">
                    <video className="image" muted autoPlay loop style={{ objectFit: "cover" }} preload="auto">
                        <source
                            src="https://verseworldstorage.blob.core.windows.net/verseweb/VerseWorld/Videos/Verselandingpagevideov2.mp4#t=0.1"
                        // type="video/mp4"
                        />
                        <div className="titles-container">
                            <h2>Welcome back !</h2>
                            <p>Verse World is waiting for you </p>
                        </div>
                    </video>
                </div>
                <div className="content">
                    <Link to={"/map/tutorial"}>
                        <img src={"/logo.png"} className="logo" />
                    </Link>

                    <Outlet />
                </div>
            </div>
        </>
    );
}
