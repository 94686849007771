import React, { Component } from 'react'
import Header from './MapUI/Header'
import ProfilePicture from './Common/ProfilePicture'
import axios from 'axios'
import { authHeader, getCurrentUser, isTokenValid } from '../util/service'
import ProfileBanner from './Common/ProfileBanner'
import { Link } from 'react-router-dom'
import { scrollTo } from '../util/util'
import Wallet from './Wallet'
import WalletSolana from './WalletSolana'
import VoucherInput from './Common/VoucherInput'

export default class ProfilePublic extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ...this.getState()
        }
    }

    getState = () => {
        return {
            isLoading: true,
            error: undefined,
            profileName: undefined,
            description: undefined,
            profilePicture: undefined,
            coverPicture: undefined,
            friends: [],
            nftFilters: [
                {
                    name: 'All',
                    type: 0,
                    isChecked: true
                },
                {
                    name: 'Appartments',
                    type: 1,
                    isChecked: false
                },
                {
                    name: 'villas',
                    type: 2,
                    isChecked: false
                },
                {
                    name: 'lands',
                    type: 3,
                    isChecked: false
                },
                {
                    name: 'tickets',
                    type: 4,
                    isChecked: false
                },
                {
                    name: 'skins',
                    type: 5,
                    isChecked: false
                },
                {
                    name: 'games',
                    type: 6,
                    isChecked: false
                },
            ],
            nftCollection: []
        }
    }

    componentDidMount = () => {
        this.windowResized()
        window.addEventListener('resize', this.windowResized);

        this.fetchProfile(this.props.userName);
        this.fetchFriends(this.props.userName);
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.userName !== prevProps.userName) {
            this.fetchProfile(this.props.userName);
            this.fetchFriends(this.props.userName);
            scrollTo(0)
        }
    }

    windowResized = () => {
        if (window.innerWidth <= 768 && !this.state.isTablet) {
            this.setState({
                isTablet: true
            })
        } else if (window.innerWidth > 768 && this.state.isTablet) {
            this.setState({
                isTablet: false
            })
        }

        if (window.innerWidth <= 425 && !this.state.isMobile) {
            this.setState({
                isMobile: true
            })
        } else if (window.innerWidth > 425 && this.state.isMobile) {
            this.setState({
                isMobile: false
            })
        }
    }

    setNftCollection = (nfts) => {
        this.setState({
            nftCollection: nfts?.length ? nfts : null
        })
    }

    fetchProfile = (userName) => {
        // init state
        this.setState({
            ...this.getState()
        })

        axios.get(`https://versemail.azurewebsites.net/playfab/profile/${userName}`, { headers: authHeader() }).then(response => {
            if (response?.data) {
                let user = response?.data
                this.setState({
                    profileName: user?.userName,
                    description: user?.bio,
                    profilePicture: user?.profilePicture,
                    coverPicture: user?.coverPicture
                })
            }
        }).catch(error => {
            this.setState({
                error: `User doesn't exist or not verified.`
            })
        });
    }

    fetchFriends = (userName) => {
        // init state
        this.setState({
            ...this.getState()
        })

        axios.get(`https://versemail.azurewebsites.net/playfab/profile/friends/${userName}`, { headers: authHeader() }).then(response => {
            if (response?.data?.length) {
                this.setState({
                    friends: response?.data
                })
            } else {
                this.setState({
                    friends: null
                })
            }
        }).catch(error => {
            this.setState({
                friends: null
            })
        });
    }

    renderProfileInfos = () => {
        if (this.state.isMobile) {
            return (
                <div className='profile-info mobile'>
                    <div className='profile-titles'>
                        <ProfilePicture picture={this.state.profilePicture} isOwner={getCurrentUser()?.userName === this.state.profileName}/>
                        <h2>{this.state.profileName || 'Loading...'}</h2>
                    </div>

                    <div className='profile-titles mobile'>
                        <h4>{this.state.description || "User doesn't have a bio yet."}</h4>
                    </div>
                </div>
            )
        } else {
            return (
                <div className='profile-info'>
                    <ProfilePicture picture={this.state.profilePicture} isOwner={getCurrentUser()?.userName === this.state.profileName}/>

                    <div className='profile-titles'>
                        <h2>{this.state.profileName || 'Loading...'}</h2>
                        <h4>{this.state.description || "User doesn't have a bio yet."}</h4>
                    </div>
                </div>
            )
        }
        
    }

    checkFilter = (filter) => {
        let nftCollectionDisplay = this.state.nftCollection;
        
        if (filter?.type !== 0 && nftCollectionDisplay?.length) {
            nftCollectionDisplay = nftCollectionDisplay.filter((nft) => {
                if (nft?.type === filter.type) {
                    return nft
                }
            })
        }

        this.setState({
            nftFilters: this.state.nftFilters.map((nftFilter) => {
                nftFilter.isChecked = false
                if (filter?.name === nftFilter?.name) {
                    nftFilter.isChecked = !filter?.isChecked
                }

                return nftFilter
            }),
            filter: filter,
            nftCollectionDisplay: nftCollectionDisplay
        })
    }

    renderFilter = (filter) => {
        return (
            <div className='checkbox-container' onClick={() => this.checkFilter(filter)}>
                <i class={!filter?.isChecked ? 'bx bxs-checkbox checkbox' : 'bx bxs-checkbox-checked checkbox checked'}></i>
                <p>{filter?.name}</p>
            </div>
        )
    }

    renderFilters = (filters, isRendered) => {
            return (
                <div className='filters-scroll'>
                    <div className={'filters' + (isRendered ? '' : ' invisible')}>
                        {
                            filters.map((filter) => {
                                return this.renderFilter(filter)
                            })
                        }
                    </div>
                </div>
            )
    }

    renderCollection = (title, filters, gallery, isFiltersRendered = false, placeholder) => {
        return (
            <div className='collection'>
                <div className='titles-container'>
                    <div className='title'>
                        {title}
                    </div>

                    <div className='filters-container'>
                        {this.renderFilters(filters, isFiltersRendered)}
                        {this.renderSearch()}
                    </div>
                </div>

                {this.renderGallery(gallery, placeholder)}
            </div>
        )
    } 

    renderNFTsCollection = (title, filters, gallery, isFiltersRendered = false, placeholder) => {
        return (
            <div className='collection'>
                <div className='titles-container'>
                    <div className='title'>
                        {title}
                    </div>

                    <div className='filters-container'>
                        {this.renderFilters(filters, isFiltersRendered)}
                        {this.renderSearch()}
                    </div>
                </div>

                <Wallet setNftCollection={this.setNftCollection}/>
                {this.renderGallery(this.state.nftCollection, undefined, true)}
            </div>
        )
    }

    renderGallery = (gallery, placeholder, isNft = false) => {
        if (!gallery?.length && !isNft) {
            return (
                <div style={{textAlign: 'center', paddingTop: '35px', height: '200px'}}>
                    {gallery !== null ? 'Loading...' : 'No results'}
                </div>
            )
        } else if (!gallery?.length)
            return;

        let isGalleryLengthy = gallery?.length > 10 ? '' : ' disabled'

        return (
            <div className='gallery-container'>

                {/*<div className={'navigate left' + isGalleryLengthy}>
                    <i class='bx bx-chevron-left'/>
                </div>

                <div className={'navigate right' + isGalleryLengthy}>
                    <i class='bx bx-chevron-right'/>
                </div>*/}
                {
                    gallery.map((element, index) => {
                        if (this.state.isMobile && index >= 6) {
                            return 
                        }
                        return this.renderGalleryElement(element, index, placeholder)
                    })
                }
            </div>
        )
    }

    renderGalleryElement = (element, index, placeholder) => {
        return (
            <Link to={element?.userName ? '/profile/' + element?.userName : '#' } className='element' style={{backgroundImage: `url(${element?.thumbnail || element?.profilePicture || element?.imageUrl || placeholder})`, animationDuration: index / 10 + 's' }} key={index}>
                <div className='title-card'>{element?.name || element?.userName}</div>
                {
                    element?.size && element?.size !== '' &&
                    <div className='badge'>
                        {element?.size}
                    </div>
                }
            </Link>
        )
    }

    renderSearch = () => {
        return (
            <div className='search-container'>
                <input type='text' placeholder='search...' onChange={this.handleSearch}/>
                {/*<div className='pagination'>
                    1/10
                </div>*/}
            </div>
        )
    }

    handleSearch = (e) => {
        let value = e?.target?.value;

        if (e?.target?.value !== '' && this.state.nftCollection)
            this.setState({
                nftCollectionDisplay: this.state.nftCollection.filter(nft => {
                    return nft?.name?.toUpperCase().includes(value?.toUpperCase())
                })
            })
        else {
            this.checkFilter(this.state.filter || this.state.nftFilters[0])
        }
    }

    renderError = () => {
        if (this.state.error) {
            return (
                <div className='status-bar alert' style={{width: '70vw', margin: 'auto'}}>
                    {this.state.error}
                </div>
            )
        }
    }

    renderNfts = () => {
        if (getCurrentUser()?.userName === this.state.profileName && isTokenValid()) {
            return (
                <>
                    {this.renderNFTsCollection('My NFT Collection', this.state.nftFilters, this.state.nftCollectionDisplay || this.state.nftCollection, true)}
                </>
            )
        }
    }


    render() {
        return (
            <div className='profile-page'>
                <Header pageTitle={'PROFILE'}/>

                <ProfileBanner coverPicture={this.state.coverPicture} isOwner={getCurrentUser()?.userName === this.state.profileName} />

                {this.renderProfileInfos()}
                {this.renderError()}

                {this.renderNfts()}
                {this.renderCollection(getCurrentUser()?.userName === this.state.profileName ? 'My friends' : 'Friends', this.state.nftFilters, this.state.friends, false, 'https://verseworldstorage.blob.core.windows.net/verseweb/VerseWorld/empty.jpg')}
                
                {
                    getCurrentUser()?.userName === this.state.profileName && isTokenValid() &&
                    <VoucherInput />
                }
            </div>
        )
    }
}
