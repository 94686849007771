import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { authHeader } from '../../util/service'

export default class SearchComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            placeholder: 'Search your question...',
            icon: 'bx bx-search-alt-2',
            popularSearches: 'Canyons, Amusement Park, Trading center',
            isListDisplayed: false,
            listElements: {
                title: 'Popular Searches',
                elements: null
            },
            pinnedQuestions: []
        }

        this.modalRef = React.createRef();
    }

    componentDidMount = () => {
        // window.addEventListener('mousedown', this.pageClick, false);

        if (this.props.isAdd) {
            this.setState({
                placeholder: 'Start your question with “What”, “How”,”Why”,etc...',
                icon: 'bx bx-pen'
            })
        } 

        this.fetchPinnedQuestions()
    }

    fetchPinnedQuestions = () => {
        axios.get(`https://versemail.azurewebsites.net/forum/question/pin`, { headers: authHeader() }).then(response => {
            if (response?.data) {
                this.setState({
                    listElements: {
                        title: 'Popular Searches',
                        elements: response?.data
                    },
                    pinnedQuestions: response?.data
                })
            }
        }).catch((error) => {
            console.log(error.response)
        });
    }

    searchInput = (searchInput) => {
        axios.get(`https://versemail.azurewebsites.net/forum/question/search/${searchInput}`, { headers: authHeader() }).then(response => {
            if (response?.data) {
                this.setState({
                    listElements: {
                        ...this.state.listElements,
                        title: 'Your search results',
                        elements: response?.data
                    }
                })
            } else {
                this.setState({
                    listElements: {
                        ...this.state.listElements,
                        title: 'Popular Searches',
                        elements: this.state.pinnedQuestions
                    }
                })
            }
        }).catch((error) => {
            this.setState({
                listElements: {
                    ...this.state.listElements,
                    title: 'Popular Searches',
                    elements: this.state.pinnedQuestions
                }
            })
        });
    }

    pageClick = (e) => {
        if (this.modalRef?.current && !this.modalRef.current.contains(e.target)) {
            this.toggleListDisplay(false)
        }
    }

    toggleListDisplay = (value) => {
        this.setState({
            isListDisplayed: value
        })
    }

    renderList = () => {
        if (this.state.isListDisplayed) {
            return (
                <div className='autocomplete-list' ref={this.modalRef}>
                    <div className='title'>
                        {this.state.listElements?.title}
                    </div>

                    <div className='elements'>
                        {this.renderElements(this.state.listElements?.elements)}
                    </div>
                </div>
            )
        }
    }

    renderElements = (elements) => {
        if (elements === null)
            return 'Loading...'
        else if (!elements?.length) return 'No results'

        return elements.map((element, index) => {
            return (
                <>
                    <Link to={'/forum/topic/' + element?.slug} className='element' onClick={() => this.toggleListDisplay(false)}>
                        {element?.question}
                    </Link> 
                    {
                        index < elements?.length - 1 &&
                        <div className='border' />
                    }
                </>
            )
        })
    }

    renderModalBg = () => {
        if (this.state.isListDisplayed) 
            return (
                <div className='modal' onClick={() => this.toggleListDisplay(false)}/>
            )
    }

    handleSearch = (e) => {
        if (e.target.value?.length >= 70) return; 

        this.setState({
            searchInput: e.target.value
        }, () => {
            if (this.state.searchInput && this.state.searchInput !== '') {
                this.searchInput(this.state.searchInput)
            } else {
                this.setState({
                    listElements: {
                        ...this.state.listElements,
                        title: 'Popular Searches',
                        elements: this.state.pinnedQuestions
                    }
                })
            }
        })
    }

    render() {
        return (
            <>
                <div className='search-component'>
                    <div className='searchbar-container'>
                        <div className='icon'>
                            <i className={this.state.icon} />
                        </div>
                        <input
                            type='text'
                            className='input'
                            placeholder={this.state.placeholder}
                            onFocus={() => this.toggleListDisplay(true)}
                            onChange={this.handleSearch}
                            value={this.state.searchInput}
                        />

                        {this.renderList()}

                    </div>

                    <div className={'popular-searches' + (this.state.isListDisplayed ? ' white' : '')}>
                        <p>
                            <span>Popular Searches: </span>
                            {this.state.popularSearches}
                        </p>
                    </div>
                </div>

                {this.renderModalBg()}
            </>
        )
    }
}
